import {
    PbshoppingCatalogProducts,
    PbshoppingCatalogProductsPageInfo,
    PbshoppingMenuItem,
    ReturnsApi,
    ReturnsApiProductDetailsRequest,
    StoreApi,
    StoreApiFetchMenuRequest,
    StoreApiFetchProductsRequest
} from '@itsrever/rever-api'
import { Loading, axiosInstance } from './apiConfiguration'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const storeApi = new StoreApi(undefined, undefined, axiosInstance)
const returnsApi = new ReturnsApi(undefined, undefined, axiosInstance)

interface MenuCall {
    response: Array<PbshoppingMenuItem>
    loading: Loading
    error: unknown | null
}
interface ProductsCall {
    products: PbshoppingCatalogProducts
    pageInfo: PbshoppingCatalogProductsPageInfo
    loading: Loading
    error: unknown | null
}

interface StoreApiState {
    menu: MenuCall
    products: ProductsCall
}

const initialState: StoreApiState = {
    menu: {
        response: [],
        loading: 'pending',
        error: null
    },
    products: {
        products: {},
        pageInfo: {},
        loading: 'pending',
        error: null
    }
}

export const fetchMenu = createAsyncThunk(
    'storeApi/fetchMenu',
    async (fetchMenuRequest: StoreApiFetchMenuRequest, { rejectWithValue }) => {
        try {
            const response = await storeApi.fetchMenu(fetchMenuRequest)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchProducts = createAsyncThunk(
    'storeApi/fetchProducts',
    async (
        fetchProductsRequest: StoreApiFetchProductsRequest,
        { rejectWithValue }
    ) => {
        try {
            const response = await storeApi.fetchProducts(fetchProductsRequest)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
export const loadMoreProducts = createAsyncThunk(
    'storeApi/loadMoreProducts',
    async (
        fetchProductsRequest: StoreApiFetchProductsRequest,
        { rejectWithValue }
    ) => {
        try {
            const response = await storeApi.fetchProducts(fetchProductsRequest)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export async function getProductDetails(
    productDetials: ReturnsApiProductDetailsRequest
) {
    const response = await returnsApi.productDetails(productDetials)
    return response.data
}

const storeApiSlice = createSlice({
    name: 'storeApi',
    initialState,
    reducers: {
        resetMenu: (state) => {
            state.menu = initialState.menu
        },
        resetProducts: (state) => {
            state.products = initialState.products
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMenu.pending, (state) => {
            state.menu.loading = 'pending'
            state.menu.error = null
            state.menu.response = []
        })
        builder.addCase(fetchMenu.rejected, (state, action) => {
            state.menu.loading = 'failed'
            state.menu.error = action.payload
        })
        builder.addCase(fetchMenu.fulfilled, (state, action) => {
            state.menu.loading = 'succeeded'
            state.menu.response = action.payload.items ?? []
        })

        builder.addCase(fetchProducts.pending, (state) => {
            state.products.loading = 'pending'
            state.products.error = null
            state.products.products = {}
            state.products.pageInfo = {}
        })
        builder.addCase(fetchProducts.rejected, (state, action) => {
            state.products.loading = 'failed'
            state.products.error = action.payload
        })
        builder.addCase(fetchProducts.fulfilled, (state, action) => {
            state.products.loading = 'succeeded'
            state.products.products = action.payload ?? {}
            state.products.pageInfo = action.payload.page_info ?? {}
        })

        builder.addCase(loadMoreProducts.fulfilled, (state, action) => {
            state.products.products.products = [
                ...(state.products.products.products ?? []),
                ...(action.payload.products ?? [])
            ]
            state.products.pageInfo = action.payload.page_info ?? {}
        })
    }
})
export const { resetMenu, resetProducts } = storeApiSlice.actions
export default storeApiSlice.reducer
