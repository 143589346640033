import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
    setBBAN,
    setCountryCode,
    setIBAN,
    setSWIFT
} from '@/redux/return/returnSlice'
import { Button, TextInput, useTheme } from '@itsrever/design-system'
import ibanValidator from 'iban'
import { useEffect, useState } from 'react'
import { isValidBIC, isValidBBAN } from 'ibantools'
import { GithubComItsreverReturnsApiPkgDomainLocationsCountry as LocationsCountry } from '@itsrever/returns-api-types'
import { TextField, Autocomplete } from '@mui/material'
import { useCountries, useTranslate } from '@/hooks'
import styled from 'styled-components'
import { Loading } from '@/components/Loading'

export interface IntroduceIbanProps {
    onContinue: () => void
}

export const IntroduceIban: React.FC<IntroduceIbanProps> = ({ onContinue }) => {
    const { translate } = useTranslate()
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const IBAN = useAppSelector((store) => store.return.IBAN)
    const SWIFT = useAppSelector((store) => store.return.SWIFT)
    const BBAN = useAppSelector((store) => store.return.BBAN)
    const [errorIBAN, setErrorIBAN] = useState(false)
    const [errorSWIFT, setErrorSWIFT] = useState(false)
    const [errorBBAN, setErrorBBAN] = useState(false)
    const [useIban, setUseIban] = useState(true)

    const [selectedCountry, setSelectedCountry] = useState<LocationsCountry>()

    const { countries, loadingCountries } = useCountries()

    const supportedCountries = ['AU', 'US']
    const usedCountries =
        countries
            ?.filter((country) =>
                supportedCountries.includes(country.iso ?? '')
            )
            .concat({ nicename: 'Other', iso: '' }) ?? []

    const complete = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (useIban) {
            if (ibanValidator.isValid(IBAN)) {
                onContinue()
            } else {
                setErrorIBAN(true)
            }
        } else {
            if (selectedCountry?.iso === 'AU') {
                onContinue()
            } else if (selectedCountry?.iso === 'US') {
                onContinue()
            } else {
                if (isValidBBAN(BBAN, SWIFT.substring(4, 6))) {
                    onContinue()
                } else setErrorBBAN(true)
            }
        }
    }

    useEffect(() => {
        if (selectedCountry?.iso === '') {
            if (!isValidBIC(SWIFT) && SWIFT.length > 0) {
                setErrorSWIFT(true)
                setErrorBBAN(false)
            } else setErrorSWIFT(false)
        } else if (selectedCountry?.iso === 'AU') {
            dispatch(setCountryCode('AU'))
            const re = /^([0-9]{3})(-| )?([0-9]{3}),*$/g
            if (SWIFT.length > 0 && !re.test(SWIFT)) {
                setErrorSWIFT(true)
            } else setErrorSWIFT(false)
        } else if (selectedCountry?.iso === 'US') {
            dispatch(setCountryCode('US'))
            const re = /^([0-9]{9}),*$/g
            if (SWIFT.length > 0 && !re.test(SWIFT)) {
                setErrorSWIFT(true)
            } else setErrorSWIFT(false)
        }
    }, [SWIFT, selectedCountry])

    const handleClick = () => {
        setUseIban(!useIban)
        dispatch(setIBAN(''))
        dispatch(setBBAN(''))
        dispatch(setSWIFT(''))
        setErrorIBAN(false)
        setErrorBBAN(false)
        setErrorSWIFT(false)
        setSelectedCountry(undefined)
    }

    const color =
        theme.colors.primary.main === 'transparent'
            ? '#000'
            : theme.colors.primary.main

    const variant = theme.inputVariant.toLowerCase()

    if (loadingCountries) return <Loading loading />
    return (
        <form
            onSubmit={complete}
            className="flex flex-col content-center items-center"
        >
            {useIban && (
                <>
                    <p
                        className="text-xs"
                        dangerouslySetInnerHTML={{
                            __html: translate('iban_introduce_iban')
                        }}
                    />
                    <TextInput
                        placeholder="ESXX XXXX XXXX XXXX XXXX XXXX"
                        id="iban"
                        value={IBAN}
                        error={errorIBAN}
                        hint={errorIBAN ? translate('iban_iban_hint') : ''}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            dispatch(setIBAN(e.currentTarget.value))
                            setErrorIBAN(false)
                        }}
                        autoFocus
                    />
                </>
            )}
            {!useIban && (
                <>
                    <p className="text-xs">{translate('iban_bank_country')}</p>
                    {usedCountries && (
                        <Autocomplete
                            className="mt-4"
                            fullWidth
                            clearIcon={''}
                            popupIcon={''}
                            id="country-select"
                            options={usedCountries}
                            isOptionEqualToValue={(option) =>
                                option.iso === selectedCountry?.iso
                            }
                            defaultValue={selectedCountry}
                            getOptionLabel={(option) => option.nicename ?? ''}
                            onChange={(event, value) => {
                                setSelectedCountry(value ?? undefined)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant={
                                        variant === 'standard'
                                            ? 'standard'
                                            : 'outlined'
                                    }
                                    label={translate('iban_country')}
                                    inputProps={{
                                        ...params.inputProps
                                    }}
                                    sx={{
                                        '& label.Mui-focused': {
                                            color: color
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: color
                                            }
                                        },
                                        '& .MuiInput-underline:before': {
                                            borderBottomColor: color
                                        },
                                        '& .MuiInput-underline:after': {
                                            borderBottomColor: color
                                        }
                                    }}
                                />
                            )}
                        />
                    )}
                    <p
                        className="mt-6 text-xs"
                        dangerouslySetInnerHTML={{
                            __html: translate(
                                `iban_introduce_${
                                    selectedCountry?.iso
                                        ? selectedCountry.iso
                                        : ''
                                }`
                            )
                        }}
                    />
                    <TextInput
                        id="SWIFT"
                        placeholder={
                            selectedCountry?.iso === 'AU'
                                ? 'XXX-XXX'
                                : selectedCountry?.iso === 'US'
                                ? 'XXXXYYYYC'
                                : 'BOFAUS3N'
                        }
                        value={SWIFT}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            if (selectedCountry?.iso === 'AU') {
                                const bsb =
                                    e.currentTarget.value
                                        .replace(/[-]/g, '')
                                        .match(/.{1,3}/g)
                                        ?.join('-') ?? ''
                                dispatch(setSWIFT(bsb))
                            } else dispatch(setSWIFT(e.currentTarget.value))
                        }}
                        error={errorSWIFT}
                        hint={errorSWIFT ? translate('iban_swift_hint') : ''}
                        disabled={!selectedCountry}
                    />
                    <p
                        className="mt-2 text-xs"
                        dangerouslySetInnerHTML={{
                            __html: translate('iban_introduce_bban')
                        }}
                    />
                    <TextInput
                        id="BBAN"
                        value={BBAN}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            dispatch(setBBAN(e.currentTarget.value))
                            setErrorBBAN(false)
                        }}
                        error={errorBBAN}
                        hint={errorBBAN ? translate('iban_bban_hint') : ''}
                        disabled={errorSWIFT || SWIFT.length === 0}
                    />
                </>
            )}
            <SwitchMethod
                id="switchBankMethod"
                textColor={theme.colors.primary.light}
                className="text-xs"
                onClick={handleClick}
            >
                {useIban
                    ? translate('iban_option1')
                    : translate('iban_option2')}
            </SwitchMethod>
            <div className="mt-3 flex w-full justify-center">
                <Button
                    fullWidth
                    disabled={IBAN === '' && BBAN === ''}
                    id="ibanComplete"
                >
                    {translate('iban_button_complete')}
                </Button>
            </div>
        </form>
    )
}

export default IntroduceIban

interface SwitchProps {
    textColor: string
}

const SwitchMethod = styled.span<SwitchProps>`
    margin-top: 1rem;
    cursor: pointer;
    color: ${(p) => p.textColor};
`
