import { useSearchParams } from 'react-router-dom'
import { useState } from 'react'
import { useAppSelector } from '@/redux/hooks'
import {
    HorizontalLanding,
    SimplifiedLanding,
    VerticalLanding,
    SettingsDemoModal
} from '@/components/LandingComponents'
import { useTheme } from '@itsrever/design-system'
import { computeColors } from '@/utils/colorPaletteGenerator'

export default function LandingPage() {
    const design = useAppSelector(
        (store) => store.settingsApi.settings.response.theme?.design
    )
    const theme = useTheme()
    computeColors(theme.colors.primary.main)

    const LandingToUse = () => {
        switch (design) {
            case 'VERTICAL':
                return <VerticalLanding />
            case 'SIMPLIFIED':
                return <SimplifiedLanding />
            default:
                return <HorizontalLanding />
        }
    }

    const [searchParams] = useSearchParams()
    const isDemo = searchParams.get('hacking-the-matrix') === 'true'
    const [isDemoModalOpen, setIsDemoModalOpen] = useState<boolean>(isDemo)

    return (
        <>
            <LandingToUse />
            {isDemoModalOpen ? (
                <SettingsDemoModal
                    isOpen={isDemoModalOpen}
                    requestClose={() => setIsDemoModalOpen(false)}
                />
            ) : null}
        </>
    )
}
