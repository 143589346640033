import { Loading, axiosInstance } from './apiConfiguration'
import {
    ReturnsApi,
    ApiProcessRequest,
    ApiProcessStartResponse,
    ApiCalculateRequest,
    ApiCalculateResponse
} from '@itsrever/returns-api-types'
import {
    ReturnsApi as ReturnsApiProxy,
    ReturnsGetOrderResponse
} from '@itsrever/rever-api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const returnsApi = new ReturnsApi(undefined, undefined, axiosInstance)
const returnsApiProxy = new ReturnsApiProxy(undefined, undefined, axiosInstance)

interface OrderCall {
    response: ReturnsGetOrderResponse
    loading: Loading
    error: unknown | null
}

interface CalculateCall {
    response: ApiCalculateResponse
    loading: Loading
    error: unknown | null
}

interface ProcessCall {
    response: ApiProcessStartResponse
    loading: Loading
    error: unknown | null
}

interface State {
    order: OrderCall
    calculate: CalculateCall
    process: ProcessCall
}

const initialState: State = {
    order: {
        response: {},
        loading: 'idle',
        error: null
    },
    calculate: {
        response: {},
        loading: 'idle',
        error: null
    },
    process: {
        response: {},
        loading: 'idle',
        error: null
    }
}

export const getOrder = createAsyncThunk(
    'returnsApi/getOrder',
    async (
        args: {
            ecommerceId: string
            orderId: string
            email: string
            userPreferredLang: string
        },
        { rejectWithValue }
    ) => {
        const { ecommerceId, orderId, email, userPreferredLang } = args
        try {
            const order = await returnsApiProxy.getOrder({
                ecommerceId,
                customerPrintedOrderId: orderId,
                email,
                userPreferredLang
            })
            return order.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const calculateReturn = createAsyncThunk(
    'returnsApi/postCalculateReturn',
    async (
        args: {
            ecommerceID: string
            calculateData: ApiCalculateRequest
        },
        { rejectWithValue }
    ) => {
        const { ecommerceID, calculateData } = args
        try {
            const calculateResponse = await returnsApi.calculate({
                ecommerceID,
                calculateData
            })
            return calculateResponse.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const postProcess = createAsyncThunk(
    'returnsApi/postProcess',
    async (
        args: { ecommerceID: string; returnData: ApiProcessRequest },
        { rejectWithValue }
    ) => {
        const { ecommerceID, returnData } = args
        try {
            const returnResponse = await returnsApi.process({
                ecommerceID,
                processData: returnData
            })
            return returnResponse.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

// Slice:
const returnsApiSlice = createSlice({
    name: 'returnsApi',
    initialState,
    reducers: {
        resetOrder: (state) => {
            state.order = {
                ...initialState.order,
                response: state.order.response
            }
        },
        setOrder: (state, action) => {
            state.order.response = action.payload
        },
        resetCalculate: (state) => {
            state.calculate = {
                ...initialState.calculate,
                response: state.calculate.response
            }
        },
        cleanCalculate: (state) => {
            state.calculate = initialState.calculate
        },
        resetProcess: (state) => {
            state.process = {
                ...initialState.process,
                response: state.process.response
            }
        },
        cleanProcess: (state) => {
            state.process = initialState.process
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getOrder.pending, (state) => {
            state.order.loading = 'pending'
            state.order.error = null
            state.order.response = {}
        })
        builder.addCase(getOrder.rejected, (state, action) => {
            state.order.loading = 'failed'
            state.order.error = action.payload
        })
        builder.addCase(getOrder.fulfilled, (state, action) => {
            state.order.loading = 'succeeded'
            state.order.response = action.payload
        })

        builder.addCase(postProcess.pending, (state) => {
            state.process.loading = 'pending'
        })
        builder.addCase(postProcess.rejected, (state, action) => {
            state.process.error = action.payload
            state.process.loading = 'failed'
        })
        builder.addCase(postProcess.fulfilled, (state, action) => {
            state.process.response = action.payload
            state.process.loading = 'succeeded'
        })

        builder.addCase(calculateReturn.pending, (state) => {
            state.calculate.loading = 'pending'
        })
        builder.addCase(calculateReturn.rejected, (state, action) => {
            state.calculate.error = action.payload
            state.calculate.loading = 'failed'
        })
        builder.addCase(calculateReturn.fulfilled, (state, action) => {
            state.calculate.response = action.payload
            state.calculate.loading = 'succeeded'
        })
    }
})

export const {
    resetOrder,
    resetCalculate,
    cleanCalculate,
    resetProcess,
    cleanProcess,
    setOrder
} = returnsApiSlice.actions
export default returnsApiSlice.reducer
