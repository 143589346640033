import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { useTranslation } from 'react-i18next'
import { PersonalizedTheme, toast } from '@itsrever/design-system'
import { getSettings } from '@/redux/api/settingsApiSlice'
import { useSlug } from '@/hooks'

export function useSettings() {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const slug = useSlug()

    const settingsApiCall = useAppSelector(
        (store) => store.settingsApi.settings
    )

    const settings = settingsApiCall.response
    const loadingSettings = settingsApiCall.loading
    const errorSettings = settingsApiCall.error

    const settingsTheme = settings?.theme

    const theme = intersectTheme()
    function intersectTheme() {
        return (
            settingsTheme
                ? {
                      ...settingsTheme,
                      colors: {
                          primary: {
                              main: settingsTheme.primary?.main,
                              light: settingsTheme.primary?.light,
                              dark: settingsTheme.primary?.dark,
                              contrastText: settingsTheme.primary?.contrast_text
                          },
                          secondary: {
                              main: settingsTheme.secondary?.main,
                              light: settingsTheme.secondary?.light,
                              dark: settingsTheme.secondary?.dark,
                              contrastText:
                                  settingsTheme.secondary?.contrast_text
                          }
                      },
                      borderRadius: settingsTheme.border_radius,
                      inputVariant: settingsTheme.input_variant
                  }
                : {}
        ) as PersonalizedTheme
    }

    changeFaviconAndTitle()
    function changeFaviconAndTitle() {
        if (settingsTheme?.title) document.title = settingsTheme.title
        if (settingsTheme?.favicon_url) {
            const oldLink = document.getElementById('dynamic-favicon')
            if (oldLink) document.head.removeChild(oldLink)

            const link = document.createElement('link')
            link.id = 'dynamic-favicon'
            link.rel = 'shortcut icon'
            link.href = settingsTheme.favicon_url
            document.head.appendChild(link)
        }
    }

    useEffect(() => {
        if (!slug) return
        const promise = dispatch(getSettings(slug))
        return () => promise.abort()
    }, [slug, dispatch])

    useEffect(() => {
        if (loadingSettings === 'failed') {
            if (!errorSettings) return
            console.warn(errorSettings)

            if (errorSettings.response?.status === 404) {
                toast({
                    text: t('toast_errors.settings_help'),
                    variant: 'error'
                })
                toast({
                    text: t('toast_errors.settings_404'),
                    variant: 'error'
                })
                return
            }
        }
    }, [loadingSettings, errorSettings, t])

    return { settings, loadingSettings, errorSettings, theme }
}
