import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
    useTheme
} from '@itsrever/design-system'
import {
    RefundPaymentMethod,
    setRefundPaymentMethod,
    setKeepYourItem
} from '@/redux/return/returnSlice'
import { RefundTimings } from '@/redux/return/returnSlice'
import { ReactComponent as GiftCard } from '@/assets/images/icons/gift-card.svg'
import BankTransfer from '@/assets/images/icons/bank-transfer.svg'
import OPM from '@/assets/images/icons/opm.svg'
import { useTranslate } from '@/hooks'
import { toRGB } from '@/utils'
import {
    PkgInfrastructureRestKeepYourItem as RestKeepYourItem,
    PkgInfrastructureRestRefundMethod as RestRefundMethod,
    PkgInfrastructureRestRDVSurplus as rdvSurplus,
    PkgInfrastructureRestShippingCosts as RestShippingCosts,
    PkgInfrastructureRestServiceFees as RestServiceFees
} from '@itsrever/returns-api-types'
import { useTranslation } from 'react-i18next'
import { ShippingCosts } from './ShippingCosts'
import RDVSurplusBadge from './RDVSurplusBadge'
import DeliveryIcon from '@/assets/images/icons/DeliveryIcon'
import { formatStringPrice } from '@/utils'
import { PbECommerceSettingsUseCaseEnum } from '@itsrever/rever-api'
interface RefundCardProps {
    method: RestRefundMethod
    keepYourItem?: RestKeepYourItem
    totalRefundPrice?: number
    keepYourItemOffered?: boolean
    selected?: boolean
    rdvSurplus?: rdvSurplus
    shippingCosts?: RestShippingCosts
    giftCardOffered?: boolean
    serviceFees?: RestServiceFees
}

export const RefundCard: React.FC<RefundCardProps> = ({
    method,
    totalRefundPrice,
    keepYourItemOffered,
    selected = false,
    rdvSurplus,
    shippingCosts,
    giftCardOffered = false,
    serviceFees
}) => {
    const { translate } = useTranslate()
    const { i18n, t } = useTranslation()
    const lang = i18n.language

    const dispatch = useAppDispatch()
    const theme = useTheme()

    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )
    const isReverLite =
        settings.use_case === PbECommerceSettingsUseCaseEnum.Lite

    const instantMethod =
        method.method === RefundPaymentMethod.GiftCard ||
        method.method === RefundPaymentMethod.PromoCode

    const tagBackgroundColor = instantMethod
        ? '#1b75eb'
        : selected
        ? theme.colors.primary.main
        : theme.colors.grey[0]
    const tagColor = theme.colors.common.white

    const iconSource =
        method.method === RefundPaymentMethod.BankTransfer ? BankTransfer : OPM

    const isRDVSurplus =
        (rdvSurplus?.surplus_amount ?? 0) > 0 ||
        (rdvSurplus?.surplus_percentage ?? 0) > 0

    const currency =
        useAppSelector(
            (store) => store.returnsApi.order.response?.order?.money_format
        )?.currency ?? 'unknown'

    const getRDVAmount = (amount: number) => {
        const amountDivided = (amount ?? 0) / 100
        return formatStringPrice(amountDivided.toString(), currency, lang)
    }

    const totalAmount = (totalRefundPrice ?? 0) / 100
    const totalAmountMinusShippingCosts = () => {
        if (instantMethod || !giftCardOffered) {
            return totalAmount - parseFloat(shippingCosts?.min?.amount || '0')
        } else {
            return totalAmount - parseFloat(shippingCosts?.max?.amount || '0')
        }
    }
    const returnTotalAmount = formatStringPrice(
        totalAmountMinusShippingCosts().toString(),
        currency,
        lang
    )
    const freeShipping = parseFloat(shippingCosts?.max?.amount || '0') === 0
    const freeAvailable = parseFloat(shippingCosts?.min?.amount || '0') === 0
    const maxShippingCots = formatStringPrice(
        shippingCosts?.max?.amount || '0',
        currency,
        lang
    )
    const totalRDVSurplus = () => {
        if (rdvSurplus?.surplus_amount) {
            const amount = totalAmount + rdvSurplus?.surplus_amount / 100
            return formatStringPrice(amount.toString(), currency, lang)
        } else if (rdvSurplus?.surplus_percentage) {
            const amount =
                totalAmount +
                totalAmount * (rdvSurplus?.surplus_percentage / 100)
            return formatStringPrice(amount.toString(), currency, lang)
        } else {
            return formatStringPrice(totalAmount.toString(), currency, lang)
        }
    }

    const serviceFeeAmount = () => {
        if (serviceFees?.service_fees_amount) {
            return formatStringPrice(
                (serviceFees.service_fees_amount / 100).toString(),
                currency,
                lang
            )
        } else if (serviceFees?.service_fees_percentage) {
            const amount =
                totalAmount * (serviceFees.service_fees_percentage / 100)
            return formatStringPrice(amount.toString(), currency, lang)
        } else {
            return ''
        }
    }

    return (
        <div
            id={method.method?.toString()}
            className="relative flex content-center items-center"
            onClick={() => {
                dispatch(setRefundPaymentMethod(method ?? null))
                dispatch(setKeepYourItem(false))
            }}
        >
            {instantMethod && freeAvailable ? (
                <FreeShippingLabel>
                    <p className="text-xs">
                        <b
                            dangerouslySetInnerHTML={{
                                __html: translate('refund_card_free_shipping')
                            }}
                        />
                    </p>
                </FreeShippingLabel>
            ) : null}
            <Card
                selected={selected}
                isBlueOption={instantMethod ?? false}
                borderColor={theme.colors.primary.main}
                bgColor={toRGB(theme.colors.primary.main)}
            >
                <div className="flex justify-between gap-2">
                    <div className="flex items-center gap-2">
                        {instantMethod ? (
                            <GiftCardIcon selected={selected} />
                        ) : (
                            <Icon src={iconSource} selected={selected} />
                        )}
                        <p className="flex items-center truncate text-lg">
                            <b
                                dangerouslySetInnerHTML={{
                                    __html: translate(
                                        `refund_card_method${method.method}_title`
                                    )
                                }}
                            />
                        </p>
                        {isRDVSurplus ? (
                            <RDVSurplusBadge
                                totalRDVSurplus={totalRDVSurplus()}
                                rdvAmount={getRDVAmount(
                                    rdvSurplus?.surplus_amount ?? 0
                                )}
                                surplusPercentage={
                                    rdvSurplus?.surplus_percentage ?? 0
                                }
                            />
                        ) : (
                            <>
                                {!isReverLite && (
                                    <div
                                        className={
                                            'rounded-lg border-solid border-grey-4 px-2 text-base font-light  ' +
                                            (freeShipping ||
                                            (freeAvailable && instantMethod) ||
                                            !giftCardOffered
                                                ? 'bg-blue-100 text-blue-700'
                                                : 'bg-grey-5 text-grey-1')
                                        }
                                    >
                                        <p>{returnTotalAmount}</p>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    {serviceFeeAmount() ? (
                        <Tooltip>
                            <TooltipTrigger>
                                <p className="flex size-5 items-center justify-center self-start rounded-full bg-grey-6 text-grey-2">
                                    ℹ
                                </p>
                            </TooltipTrigger>
                            <TooltipContent className="max-w-32 rounded bg-neutral-800 px-2 py-1 text-xs text-white">
                                {t('service_fees.tooltip_message', {
                                    fee: serviceFeeAmount()
                                })}
                            </TooltipContent>
                        </Tooltip>
                    ) : null}
                </div>

                {keepYourItemOffered ? null : (
                    <ShippingCosts
                        shippingCosts={shippingCosts}
                        isInstantMethod={instantMethod}
                        hideMinimumReturnCost={giftCardOffered}
                    />
                )}

                <div>
                    {keepYourItemOffered && (
                        <p className="flex items-center gap-2 text-xs text-grey-2">
                            {translate('kyi_refund_of')} {totalRDVSurplus()}{' '}
                            {instantMethod ? null : (
                                <span className="flex items-center text-orange-400">
                                    - {maxShippingCots}
                                    <DeliveryIcon />
                                </span>
                            )}
                        </p>
                    )}
                </div>
                <p
                    className="mt-2 font-normal"
                    style={{
                        color: selected
                            ? theme.colors.common.black
                            : theme.colors.grey[0]
                    }}
                    dangerouslySetInnerHTML={{
                        __html: translate(
                            `refund_card_method${method.method}_description${method.timing}`
                        )
                    }}
                />

                <div className="flex justify-between md:gap-6">
                    <div />
                    {method.timing === RefundTimings.OnStartReturnProcess ? (
                        <Tag
                            color={tagColor}
                            bgroundColor={tagBackgroundColor}
                            selected={selected}
                        >
                            <TagText
                                dangerouslySetInnerHTML={{
                                    __html: translate(
                                        `refund_card_time_method${method.method}`
                                    )
                                }}
                            />
                        </Tag>
                    ) : (
                        <div />
                    )}
                </div>
            </Card>
        </div>
    )
}

export default RefundCard

const GiftCardIcon = styled(GiftCard)<{ selected: boolean }>`
    @keyframes spin {
        0% {
            transform: translateY(0) rotate(0deg);
            transform-origin: right;
        }
        50% {
            transform: rotate(30deg);
            transform-origin: right;
        }
        100% {
            transform: translateY(0) rotate(0deg);
            transform-origin: right;
        }
    }
    #TOP {
        animation: ${(p) => (p.selected ? 'spin 0.5s linear' : '')};
    }
    z-index: 5;
    height: 15px;
    width: 15px;
    overflow: visible;
`

const Icon = styled.img<{ selected: boolean }>`
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(-30deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
    animation: ${(p) => (p.selected ? 'spin 0.5s linear' : '')};

    height: 15px;
    width: 15px;
`

const FreeShippingLabel = styled.div`
    position: absolute;
    top: -10px;
    left: 16px;
    padding: 0.1rem 0.8rem;
    border: 1px solid #1b75eb;
    border-radius: 20px;
    background: radial-gradient(circle at 78px 10px, #0076e7, #07a5fe 100%);
    color: white;
`

interface TagProps {
    color: string
    bgroundColor: string
    selected: boolean
    isAnimating?: boolean
}

const Tag = styled.div<TagProps>`
    @keyframes slideInFromLeft {
        from {
            width: 88px;
        }
        to {
            width: 100px;
        }
    }
    @keyframes slideOutFromLeft {
        from {
            width: 100px;
        }
        to {
            width: 88px;
        }
    }

    width: 80px;
    align-self: center;
    margin-top: 8px;
    white-space: nowrap;
    background-color: ${(p) => p.bgroundColor};
    color: ${(p) => p.color};
    text-align: center;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    padding: 3px 0;
    margin-right: ${(p) => (p.selected ? '-10px' : '-12px')};
    transform-origin: right;
    animation: ${(p) =>
        p.selected
            ? `slideInFromLeft 0.2s ease-in-out forwards`
            : 'slideOutFromLeft 0.2s ease-in-out forwards'};
`

const TagText = styled.p`
    font-size: 0.6rem;
    line-height: 8px;
`

interface CardProps {
    selected: boolean
    isBlueOption: boolean
    borderColor: string
    bgColor: string
}

const Card = styled.div<CardProps>`
    padding: ${(p) => (p.selected ? '12px 10px' : '14px 12px')};
    cursor: pointer;
    border: ${(p) => (p.selected ? '2px' : '0px')} solid;
    border-radius: 0.25rem;
    width: 100%;
    margin-bottom: 1.5rem;
    border-color: ${(p) =>
        p.selected ? (p.isBlueOption ? '#1b75eb' : p.borderColor) : ''};
    background-color: ${(p) =>
        p.selected
            ? p.isBlueOption
                ? 'rgb(27, 117, 235, 0.1)'
                : p.bgColor
            : ''};
    box-shadow: ${(p) =>
        p.selected ? '' : '0px 0px 2px 2px rgb(0 0 0 / 0.1)'};
`
