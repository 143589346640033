import { useTranslate } from '@/hooks'
import { useAppSelector } from '@/redux/hooks'
import { BoxIcon, CalendarIcon } from '@itsrever/design-system'
import { getDate } from '@/utils'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '@mui/material'

export const OrderInformation: React.FC = () => {
    const { translate } = useTranslate()
    const isMobile = useMediaQuery('(max-width:599px)')
    const { i18n } = useTranslation()
    const orderNumber = useAppSelector(
        (store) =>
            store.returnsApi.order.response.order?.identification
                ?.customer_printed_order_id
    )
    const date = useAppSelector(
        (store) => store.returnsApi.order.response.order?.purchased_at
    )
    const monthType = isMobile ? 'short' : 'long'
    const purchaseDate = date ? getDate(date, i18n.language, monthType) : ''

    return (
        <>
            <div className="flex basis-96 flex-wrap items-center justify-between gap-1 text-xs text-neutral-600">
                <div className="flex items-center gap-1 rounded-full bg-neutral-200 px-3 py-1">
                    <span className="text-neutral-400">
                        <BoxIcon className="mr-0.5" />
                    </span>
                    <span
                        className=" text-neutral-400"
                        dangerouslySetInnerHTML={{
                            __html: translate('item_selection_order')
                        }}
                    />
                    <span>{orderNumber}</span>
                </div>
                {purchaseDate ? (
                    <div className="flex gap-1 rounded-full bg-neutral-200 px-3 py-1">
                        <span className="text-neutral-400">
                            <CalendarIcon className="mr-0.5 size-4" />
                        </span>
                        <span
                            className="text-neutral-400"
                            dangerouslySetInnerHTML={{
                                __html: translate('item_selection_date')
                            }}
                        />
                        <span className="">{purchaseDate}</span>
                    </div>
                ) : null}
            </div>
        </>
    )
}
