import axios, { AxiosRequestConfig } from 'axios'

async function callFlagrFlag(flagID: number, entityContext: object = {}) {
    const request: AxiosRequestConfig = {
        method: 'post',
        url: `https://flagr.byrever.com/api/v1/evaluation`,
        data: { flagID, entityContext }
    }
    const { data: response } = await axios.request(request)

    if (response.variantKey) return Boolean(response.variantKey === 'on')
    return false
}

export default { callFlagrFlag }
