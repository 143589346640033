interface RDVSurplusBadgeProps {
    totalRDVSurplus: string
    rdvAmount: string
    surplusPercentage: number
}
export const RDVSurplusBadge: React.FC<RDVSurplusBadgeProps> = ({
    totalRDVSurplus,
    rdvAmount,
    surplusPercentage
}) => {
    return (
        <>
            <div className="relative flex overflow-hidden rounded-lg border border-solid border-green-300 bg-green-50 text-sm">
                <div className="flex self-stretch bg-green-200 px-1 text-green-700">
                    <p className="my-auto text-sm">
                        {surplusPercentage > 0
                            ? '+ ' + surplusPercentage + '%'
                            : '+ ' + rdvAmount}
                    </p>
                </div>
                <div className="mr-3 self-stretch text-green-200">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 -15 4 15"
                        className="h-full"
                    >
                        <path
                            d="M 0 0 L 0 -15 L 4 -15 L 0 0"
                            fill="currentColor"
                        />
                    </svg>
                </div>

                <p className="relative pr-2 text-base font-bold">
                    {totalRDVSurplus}
                </p>
            </div>
        </>
    )
}
export default RDVSurplusBadge
