import { notifyError } from '@/services/Sentry'
import axios from 'axios'

export type Loading = 'idle' | 'pending' | 'succeeded' | 'failed'

export const axiosInstance = axios.create({ timeout: 300_000 })

axiosInstance.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        console.warn(error)
        if (!isErrorToIgnore(error)) notifyError(error)
        return Promise.reject(error)
    }
)

function isErrorToIgnore(error: unknown): boolean {
    if (axios.isCancel(error)) return true
    if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) return true
        if (error.response?.status === 404) return true
        if (error.response?.status === 406) return true
        if (error.response?.status === 409) return true
        if (error.response?.status === 419) return true
        if (error.response?.status === 503) return true
        if (error.code === 'ERR_NETWORK') return true
        if (error.code === 'ECONNABORTED') return true
    }
    return false
}
