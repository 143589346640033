import { Button, useTheme } from '@itsrever/design-system'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
    RefundActions,
    ReturnMethod,
    setPickUpRanges
} from '@/redux/return/returnSlice'
import { useEffect, useState } from 'react'
import { PkgInfrastructureRestReturnMethod as RestReturnMethod } from '@itsrever/returns-api-types'
import styled from 'styled-components'
import { device, disabledForm } from '@/utils'
import { useCalculate, useTranslate, usePages, useReturnMethods } from '@/hooks'
import ReturnCard from '@/components/ReturnCard/ReturnCard'
import TopBar from '@/components/TopBarComponent/TopBar'
import { ReverCard } from '@/components/ReverCard'
import FallbackNotFound from '@/components/FallbackNotFound'
import { LoadingModal } from '@/components/Loading'
import { useFeatureFlag } from '@/application/use-feature-flag'

function getFinalReturnMethods(returnMethods: RestReturnMethod[]) {
    const checker = new Map<number, boolean>()
    const availableReturnMethods: RestReturnMethod[] = []
    returnMethods.forEach((retMethod) => {
        if (retMethod.name) {
            if (!checker.has(retMethod.name)) {
                checker.set(retMethod.name, true)
                availableReturnMethods.push(retMethod)
            }
        }
    })
    return availableReturnMethods
}

const PICKUP_RANGES_FLAG_ID = 1717

function ReturnMethodPage() {
    const { translate } = useTranslate()
    const navigate = useNavigate()
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const { pages } = usePages()

    const { isFlagEnabled } = useFeatureFlag(PICKUP_RANGES_FLAG_ID)
    const shouldHidePickupRanges = isFlagEnabled

    const addressObject = useAppSelector((store) => store.return.returnAddress)

    const ret = useAppSelector((store) => store.return)
    const returnMethod = ret.returnMethod

    const displayPickupRanges =
        !shouldHidePickupRanges && returnMethod === ReturnMethod.HomePickup

    const returnMethods = useAppSelector(
        (store) => store.returnMethodsApi.returnMethods.response.return_methods
    )

    //timepicker // 3 Time Ranges
    const [time, setTime] = useState([
        { timeRange: 0, selected: false, calcString: 1 },
        { timeRange: 1, selected: false, calcString: 2 },
        { timeRange: 2, selected: false, calcString: 3 }
    ])
    const [pickupRangesState, setPickupRanges] = useState<number[]>([])

    const handleTime = (timeRange: number) => {
        setTime(
            time.map((item) =>
                item.timeRange === timeRange
                    ? { ...item, selected: !item.selected }
                    : item
            )
        )
    }

    useEffect(() => {
        const pickupRanges = time.filter((item) => item.selected)
        setPickupRanges(pickupRanges.map((item) => item.calcString))
        dispatch(setPickUpRanges(pickupRanges.map((item) => item.calcString)))
    }, [time])

    const availableReturnMethods = getFinalReturnMethods(returnMethods ?? [])

    const { callCalculate, loadingCalculate } = useCalculate()
    const { loadingReturnMethods } = useReturnMethods()
    const hasExchanges = ret.returnedItems.some(
        (item) => item.item.action === RefundActions.ToExchange
    )

    const onClickContinue = () => {
        if (hasExchanges && returnMethod !== ReturnMethod.DeliveryPickup) {
            navigate(pages.ExchangeAddress)
        } else {
            callCalculate(returnMethod)
        }
    }

    const disableContinue =
        availableReturnMethods.length == 0 ||
        disabledForm(
            addressObject,
            0,
            undefined,
            pickupRangesState,
            returnMethod,
            displayPickupRanges
        )

    return (
        <>
            {loadingCalculate || loadingReturnMethods ? (
                <LoadingModal loading />
            ) : null}
            <TopBar
                page="return-method"
                title={translate('return_page_title')}
            />
            <MainDiv>
                <ReverCard>
                    {availableReturnMethods.length > 0 ? (
                        availableReturnMethods.map((retMethod) => {
                            return (
                                <ReturnCard
                                    key={retMethod.name}
                                    method={retMethod}
                                />
                            )
                        })
                    ) : (
                        <FallbackNotFound
                            title={translate(
                                'return_page_no_methods_available'
                            )}
                        />
                    )}

                    {displayPickupRanges ? (
                        <div className="mt-4">
                            <span
                                className="text-base"
                                dangerouslySetInnerHTML={{
                                    __html: translate(
                                        'return_page_pickup_times'
                                    )
                                }}
                            />
                            <div className="mt-4 grid grid-cols-2 gap-4">
                                <TimeFrame
                                    id="morning"
                                    color={theme.colors.grey[0]}
                                    bgColor="black"
                                    bgText="white"
                                    clicked={time[0].selected}
                                    onClick={() => {
                                        handleTime(0)
                                    }}
                                >
                                    8:00 - 12:00
                                </TimeFrame>
                                <TimeFrame
                                    color={theme.colors.grey[0]}
                                    bgColor="black"
                                    bgText="white"
                                    clicked={time[1].selected}
                                    onClick={() => {
                                        handleTime(1)
                                    }}
                                >
                                    12:00 - 16:00
                                </TimeFrame>
                                <TimeFrame
                                    color={theme.colors.grey[0]}
                                    clicked={time[2].selected}
                                    bgColor="black"
                                    bgText="white"
                                    onClick={() => {
                                        handleTime(2)
                                    }}
                                >
                                    16:00 - 20:00
                                </TimeFrame>
                            </div>
                        </div>
                    ) : null}
                    <div className="mt-2 flex w-full justify-center">
                        <div className="w-full md:max-w-[280px]">
                            <Button
                                onClick={() => onClickContinue()}
                                id="returnMethodContinue"
                                fullWidth
                                disabled={disableContinue}
                            >
                                {translate('return_page_button_continue')}
                            </Button>
                        </div>
                    </div>
                </ReverCard>
            </MainDiv>
        </>
    )
}

const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    flex-grow: 1;
    @media ${device.lg} {
        background-color: #f2f2f2;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-evenly;
    }
`

interface TimeProps {
    bgColor: string
    color: string
    bgText: string
    clicked: boolean
}

const TimeFrame = styled.div<TimeProps>`
    color: ${(p) => (p.color ? p.color : 'black')};
    border: 1px solid;
    border-color: ${(p) => (p.color ? p.color : 'black')};
    background-color: transparent;
    cursor: pointer;
    border-radius: 33px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    max-height: 4rem;
    font-size: small;
    @media ${device.sm} {
        font-size: 14;
    }
    background-color: ${(p) => (p.clicked ? p.bgColor : 'transparent')};
    border-color: ${(p) => (p.clicked ? p.bgColor : 'transparent')};
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.25);
    color: ${(p) => (p.clicked ? p.bgText : '')};
`

export default ReturnMethodPage
