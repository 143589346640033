import TextField from '@mui/material/TextField'
import { useTheme } from '@itsrever/design-system'
import { forwardRef } from 'react'
import { TextFieldProps } from '@mui/material/TextField'

const ReverTextField = forwardRef<HTMLInputElement, TextFieldProps>(
    (props, ref) => {
        const theme = useTheme()

        const color =
            theme.colors.primary.main === 'transparent'
                ? '#000'
                : theme.colors.primary.main

        const variant = theme.inputVariant.toLowerCase()

        return (
            <TextField
                variant={variant === 'standard' ? 'standard' : 'outlined'}
                inputRef={ref}
                sx={{
                    '& label.Mui-focused': {
                        color: color
                    },
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                            borderColor: color
                        }
                    },
                    '& .MuiInput-underline:before': {
                        borderBottomColor: color
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: color
                    }
                }}
                InputLabelProps={{
                    sx: {
                        fontFamily: 'Roobert'
                    }
                }}
                InputProps={{
                    sx: {
                        fontFamily: 'Roobert'
                    }
                }}
                FormHelperTextProps={{
                    sx: {
                        fontFamily: 'Roobert',
                        fontWeight: 500
                    }
                }}
                {...props}
            />
        )
    }
)
ReverTextField.displayName = 'ReverTextField'

export default ReverTextField
