import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
    getRefundMethods,
    resetRefundMethods,
    setProvisionalRefundAmount
} from '@/redux/api/refundMethodsApiSlice'
import { useNavigate } from 'react-router-dom'
import {
    GithubComItsreverDomainModelsPkgModelsAddress as ModelsAddress,
    PkgInfrastructureRestCalculateRefundMethodsRequest as RestCalculateRefundMethodsRequest,
    PkgInfrastructureRestItemToReturn as ItemToReturn,
    ApiExchangeSelection
} from '@itsrever/returns-api-types'
import { toast } from '@itsrever/design-system'
import { useTranslation } from 'react-i18next'
import {
    RefundActions,
    setKeepYourItem,
    setRefundPaymentMethod,
    resetKeepYourItem
} from '@/redux/return/returnSlice'
import { usePages } from './usePages'
import { AxiosError } from 'axios'
import { cleanCalculate, cleanProcess } from '@/redux/api/returnsApiSlice'
import { cleanReturnMethods } from '@/redux/api/returnMethodsApiSlice'

export function useRefundMethods(onSuccess?: () => void) {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { pages } = usePages()

    const refundMethodsCall = useAppSelector(
        (store) => store.refundMethodsApi.refundMethods
    )

    const refundMethods = refundMethodsCall.response
    const loadingRefundMethods = refundMethodsCall.loading
    const errorRefundMethods = refundMethodsCall.error

    const orderInfo = useAppSelector((store) => store.order)
    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )
    const ecommerceID = settings.ecommerce_id
    const retStatus = useAppSelector((store) => store.return)

    const order = useAppSelector((store) => store.returnsApi.order.response)

    const retItems = retStatus.returnedItems
    const newItems = retStatus.newItems
    const exchangeSelections = newItems?.map((item) => {
        const exchangeItem: ApiExchangeSelection = {
            line_item_platform_id: item.returnedItemId,
            quantity: item.quantity,
            variant_id: item.productVariant.variantId,
            signed_product_raw: item.signedProductRaw
        }
        return exchangeItem
    })

    const orderedItems = useAppSelector(
        (store) => store.refundMethodsApi.refundMethods.response.ordered_items
    )
    const returnedItems = retStatus.returnedItems
    const orderProducts = orderInfo.orderProducts
    const totalReturningCredit = returnedItems.reduce((total, item) => {
        const matchedOrderProduct = orderProducts.find(
            (product) => product.lineItemId === item.item.line_item_id
        )
        if (matchedOrderProduct) {
            const unitPrice =
                (matchedOrderProduct.product.total ?? 0) /
                (matchedOrderProduct.product.quantity ?? 1)
            return total + unitPrice * (item.item.quantity ?? 1)
        }
        return total
    }, 0)
    const newItemsTotal =
        orderedItems?.reduce((total, item) => {
            return total + parseFloat(item.amount ?? '0') * 100
        }, 0) ?? 0
    const provisionalRefundAmount = totalReturningCredit - newItemsTotal

    function callRefundMethods(address: ModelsAddress) {
        const itemsToReturn: ItemToReturn[] = retItems.map((p) => {
            const item: ItemToReturn = {
                line_item_id: p.lineItemId,
                quantity: p.item.quantity,
                product_return_reason: p.item.product_return_reason,
                action: p.item.action ?? RefundActions.NoAction
            }
            return item
        })

        if (ecommerceID && order.checkout_id && itemsToReturn.length > 0) {
            const request: RestCalculateRefundMethodsRequest = {
                checkout_id: order.checkout_id,
                items_to_return: itemsToReturn,
                return_address: address,
                exchange_selections: exchangeSelections
            }
            dispatch(getRefundMethods({ ecommerceID, request }))
        }
    }

    function handleSuccess() {
        dispatch(resetRefundMethods())
        dispatch(cleanReturnMethods())
        dispatch(cleanCalculate())
        dispatch(cleanProcess())
        dispatch(resetKeepYourItem())
        dispatch(setProvisionalRefundAmount(provisionalRefundAmount))

        const methods = refundMethods.result?.refund_methods ?? []
        const isOnlyKyi = methods.every(
            (method) => method.keep_your_item?.enabled === true
        )
        if (methods.length > 0) dispatch(setRefundPaymentMethod(methods[0]))
        if (methods.length > 0 && isOnlyKyi) dispatch(setKeepYourItem(true))
        if (onSuccess) onSuccess()
    }

    function handleReject() {
        dispatch(resetRefundMethods())
        console.warn(errorRefundMethods)
        const error = errorRefundMethods as AxiosError

        if (error.response?.status === 401 || error.response?.status === 419) {
            toast({
                text: t('toast_errors.error_419'),
                variant: 'error'
            })
            navigate(
                `${pages.Landing}?orderNumber=${encodeURIComponent(
                    orderInfo.orderNumber || ''
                )}&email=${encodeURIComponent(orderInfo.email || '')}`
            )
            return
        }
        navigate(pages.Error)
    }

    useEffect(() => {
        if (loadingRefundMethods === 'succeeded') {
            handleSuccess()
            return
        }

        if (loadingRefundMethods === 'failed') {
            handleReject()
            return
        }
    }, [loadingRefundMethods])

    return {
        callRefundMethods,
        loadingRefundMethods: loadingRefundMethods === 'pending'
    }
}
