import { Loading, axiosInstance } from './apiConfiguration'
import {
    PkgInfrastructureRestCalculateRefundMethodsRequest as RestCalculateRefundMethodsRequest,
    PkgInfrastructureRestCalculateRefundMethodsResponse as RestCalculateRefundMethodsResponse,
    RefundMethodsApi
} from '@itsrever/returns-api-types'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const refundMethodsApi = new RefundMethodsApi(
    undefined,
    undefined,
    axiosInstance
)

interface RefundMethodsCall {
    response: RestCalculateRefundMethodsResponse
    loading: Loading
    error: unknown | null
}

interface State {
    refundMethods: RefundMethodsCall
    provisionalRefundAmount: number | undefined
}

const initialState: State = {
    refundMethods: {
        response: {},
        loading: 'idle',
        error: null
    },
    provisionalRefundAmount: undefined
}

export const getRefundMethods = createAsyncThunk(
    'refundMethodsApi/getRefundMethods',
    async (
        args: {
            ecommerceID: string
            request: RestCalculateRefundMethodsRequest
        },
        { rejectWithValue }
    ) => {
        try {
            const { ecommerceID, request } = args
            const returnMethodsResponse = await refundMethodsApi.refundMethods({
                ecommerceID,
                calculateRefundMethodsRequest: request
            })
            return returnMethodsResponse.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const refundMethodsApiSlice = createSlice({
    name: 'returnMethodsApi',
    initialState,
    reducers: {
        resetRefundMethods: (state) => {
            state.refundMethods = {
                ...initialState.refundMethods,
                response: state.refundMethods.response
            }
        },
        cleanRefundMethods: (state) => {
            state.refundMethods = initialState.refundMethods
        },
        setProvisionalRefundAmount: (state, action) => {
            state.provisionalRefundAmount = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getRefundMethods.pending, (state) => {
            state.refundMethods.loading = 'pending'
            state.refundMethods.response = {}
            state.refundMethods.error = null
        })
        builder.addCase(getRefundMethods.fulfilled, (state, action) => {
            state.refundMethods.loading = 'succeeded'
            state.refundMethods.response = action.payload
        })
        builder.addCase(getRefundMethods.rejected, (state, action) => {
            state.refundMethods.loading = 'failed'
            state.refundMethods.error = action.payload
        })
    }
})

export const {
    resetRefundMethods,
    cleanRefundMethods,
    setProvisionalRefundAmount
} = refundMethodsApiSlice.actions
export default refundMethodsApiSlice.reducer
