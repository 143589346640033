import { useState } from 'react'
import { getExchangeOptions } from '@/api/returnsApi'
import { useAppSelector } from '@/redux/hooks'
import { ReturnsExchangeOptionsRequest } from '@itsrever/rever-api'
import axios from 'axios'
import { toast } from '@itsrever/design-system'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { usePages } from '@/hooks'

export function useExchangeOptions(
    lineItemId: string | undefined,
    return_reason: string | undefined,
    quantity: number | undefined
) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { pages } = usePages()

    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )
    const checkout_id = useAppSelector(
        (store) => store.returnsApi.order.response.checkout_id
    )
    const ecommerceId = settings.ecommerce_id
    const landingPage = pages.Landing

    const orderInfo = useAppSelector((store) => store.order)
    const orderNumber = orderInfo.orderNumber
    const email = orderInfo.email

    const [loadingExchangeOptions, setLoading] = useState(false)

    const callExchangeOptions = async () => {
        if (!ecommerceId || !lineItemId) return
        const returnsExchangeOptionsRequest: ReturnsExchangeOptionsRequest = {
            checkout_id,
            line_item_platform_id: lineItemId,
            ecommerce_id: ecommerceId,
            return_reason,
            quantity
        }

        try {
            setLoading(true)
            const exchangeOptions = await getExchangeOptions({
                ecommerceId,
                returnsExchangeOptionsRequest
            })
            return exchangeOptions
        } catch (error) {
            if (axios.isAxiosError(error))
                if (
                    error.response?.status === 401 ||
                    error.response?.status === 419
                ) {
                    toast({
                        text: t('toast_errors.error_419'),
                        variant: 'error'
                    })
                    navigate(
                        `${landingPage}?orderNumber=${encodeURIComponent(
                            orderNumber || ''
                        )}&email=${encodeURIComponent(email || '')}`
                    )
                    return
                }
        } finally {
            setLoading(false)
        }
    }

    return { callExchangeOptions, loadingExchangeOptions }
}
