const BankTransferIcon = () => (
    <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.39022 0.191816C8.20129 0.101321 7.99448 0.0543442 7.785 0.0543442C7.57552 0.0543442 7.36871 0.101321 7.17978 0.191816L1.29575 3.00495C1.05735 3.11914 0.856125 3.29845 0.715317 3.52215C0.574508 3.74586 0.499861 4.00484 0.5 4.26917V5.59729C0.5 6.37062 1.12763 6.99825 1.90096 6.99825V10.3606H1.62077C1.32352 10.3606 1.03845 10.4786 0.828266 10.6888C0.618081 10.899 0.5 11.1841 0.5 11.4813V13.1625C0.5 13.4597 0.618081 13.7448 0.828266 13.955C1.03845 14.1652 1.32352 14.2832 1.62077 14.2832H13.9492C14.2465 14.2832 14.5316 14.1652 14.7417 13.955C14.9519 13.7448 15.07 13.4597 15.07 13.1625V11.4813C15.07 11.1841 14.9519 10.899 14.7417 10.6888C14.5316 10.4786 14.2465 10.3606 13.9492 10.3606H13.669V6.99825C14.4424 6.99825 15.07 6.37062 15.07 5.59729V4.26917C15.07 3.73008 14.7607 3.23807 14.2743 3.00495L8.39022 0.191816ZM10.3067 6.99825H11.9879V10.3606H10.3067V6.99825ZM6.94442 6.99825H8.62558V10.3606H6.94442V6.99825ZM3.58212 6.99825H5.26327V10.3606H3.58212V6.99825Z"
            fill="currentColor"
        />
    </svg>
)
export default BankTransferIcon
