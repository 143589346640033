import { NewItem } from '@/domain/new-item'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
    addNewItem,
    removeNewItem,
    removeNewItems,
    updateNewItemQuantity
} from '@/redux/return/returnSlice'

import { ReturnsProductDetailsResponse } from '@itsrever/rever-api'

export function useUpdateCart() {
    const dispatch = useAppDispatch()
    const newItems = useAppSelector((state) => state.return.newItems)

    function removeCartItem(cartItem: NewItem) {
        dispatch(
            removeNewItem({
                returnedItemId: cartItem.returnedItemId,
                variantId: cartItem.productVariant.variantId
            })
        )
    }
    function updateCartItemQuantity(cartItem: NewItem, quantity: number) {
        dispatch(
            updateNewItemQuantity({
                returnedItemId: cartItem.returnedItemId,
                variantId: cartItem.productVariant.variantId,
                quantity
            })
        )
    }
    function addItemToCart(
        returnedItemId: string,
        selectedQuantity: number,
        selectedVariantIndex: number,
        productDetails: ReturnsProductDetailsResponse
    ) {
        const variant =
            productDetails.product_info?.variants &&
            productDetails.product_info?.variants[selectedVariantIndex]
        const existingItem = newItems.find(
            (item) =>
                item.returnedItemId === returnedItemId &&
                item.productVariant.variantId === variant?.platform_id
        )
        if (existingItem) {
            updateCartItemQuantity(
                existingItem,
                existingItem.quantity + selectedQuantity
            )
        } else {
            const newItem: NewItem = {
                returnedItemId,
                quantity: selectedQuantity,
                price: parseFloat(variant?.price?.amount ?? '0'),
                signedProductRaw: productDetails.signed_product_raw ?? '',
                productVariant: {
                    productName: productDetails.product_info?.name ?? '',
                    variantName: variant?.name ?? '',
                    productId: productDetails.product_info?.platform_id ?? '',
                    variantId: variant?.platform_id ?? '',
                    imageUrl: variant?.image_urls?.[0] ?? '',
                    imagesUrls: variant?.image_urls ?? [],
                    inventoryQuantity: variant?.inventory_quantity ?? 1
                }
            }
            dispatch(addNewItem(newItem))
        }
    }

    function clearReturnedItemCartItems(returnedItemId: string) {
        dispatch(removeNewItems({ returnedItemId }))
    }

    return {
        removeCartItem,
        updateCartItemQuantity,
        addItemToCart,
        clearReturnedItemCartItems
    }
}
