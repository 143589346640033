import { useTranslate } from '@/hooks'
import { Button } from '@itsrever/design-system'
import { useTranslation } from 'react-i18next'

interface SuggestionsByReasonProps {
    selectedReason: string
    handleContinue: () => void
    handleClickExchange: () => void
}

export const SuggestionsByReason: React.FC<SuggestionsByReasonProps> = ({
    selectedReason,
    handleContinue,
    handleClickExchange
}) => {
    const { translate } = useTranslate()
    const { t } = useTranslation()
    return (
        <div className="h-fit w-full min-w-[250px] overflow-x-hidden px-2 sm:min-w-[280px]">
            <div className="h-fit w-full overflow-x-hidden px-2">
                <div className="mt-5 flex">
                    <p className="text-base">
                        {t('suggestions_by_reason.as_you_selected')}
                    </p>
                    <p
                        className="mx-2 rounded-lg border border-solid border-grey-5 px-3 text-base "
                        dangerouslySetInnerHTML={{
                            __html: translate(`select_reason_${selectedReason}`)
                        }}
                    />
                </div>
                <p className="mt-3 text-base">
                    {t('suggestions_by_reason.change_question')}
                </p>
                <div className="mb-12 mt-8 flex w-full flex-col content-center items-center gap-4">
                    <Button onClick={handleClickExchange}>
                        {t('suggestions_by_reason.yes')}
                    </Button>
                    <span
                        className="cursor-pointer text-sm text-grey-4"
                        onClick={handleContinue}
                    >
                        {t('suggestions_by_reason.proceed')}
                    </span>
                </div>
            </div>
        </div>
    )
}
