import { useState, useEffect, useRef } from 'react'

interface MobileVariantImageProps {
    src: string
    onClick: () => void
}

export const MobileVariantImage: React.FC<MobileVariantImageProps> = ({
    src,
    onClick
}) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const imgRef = useRef<HTMLImageElement>(null)

    useEffect(() => {
        if (imgRef.current && imgRef.current.complete) {
            setIsLoaded(true)
        }
    }, [imgRef, src])
    return (
        <>
            {!isLoaded && (
                <div className="aspect-square rounded-lg bg-neutral-100" />
            )}
            <img
                ref={imgRef}
                src={src}
                className={
                    'aspect-square rounded-lg object-cover ' +
                    (isLoaded ? 'block' : 'hidden')
                }
                onClick={onClick}
                onLoad={() => setIsLoaded(true)}
            />
        </>
    )
}
