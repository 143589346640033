import styled from 'styled-components'
import {
    useTheme,
    CrossIcon,
    Button,
    Spinner,
    CartIcon
} from '@itsrever/design-system'
import { useState } from 'react'
import {
    DesktopNavBar,
    Search,
    DesktopVariantSelector,
    ProductCard,
    ShowMoreButton
} from '@/components/FullCatalog'
import { Drawer } from '@mui/material'
import {
    PbshoppingProductInfo,
    ReturnsProductDetailsResponseVariantInfo
} from '@itsrever/rever-api'
import { useTranslation } from 'react-i18next'
import {
    useStoreProducts,
    useStoreMenu,
    useStoreProductDetails,
    useCredit
} from '@/hooks'
import {
    formatProductPrice,
    variantsHaveSamePrices
} from '@/components/FullCatalog/utils'
import { LoadingModal } from '@/components/Loading'
import { CartView } from '../Components/CartView'
import { useAppSelector } from '@/redux/hooks'
import { totalNewItemsQuantity } from '@/domain/new-item'
import { CreditBar } from '../Components/CreditBar'
import { CartCreditBar } from '../Components/CartCreditBar'
import EmptyCart from '@/assets/images/EmptyCart'
import { usePostHog } from '@/application/use-posthog'

interface DesktopFullCatalogProps {
    returnedItemId: string
    handleClose: () => void
    handleContinue: () => void
    showCartView: boolean
    setShowCartView: (show: boolean) => void
}

export const DesktopFullCatalog: React.FC<DesktopFullCatalogProps> = ({
    returnedItemId,
    handleClose,
    handleContinue,
    showCartView,
    setShowCartView
}) => {
    const theme = useTheme()
    const { t, i18n } = useTranslation()
    const lang = i18n.language.toLocaleUpperCase() ?? 'EN'
    const { captureEventPosthog } = usePostHog()

    const [selectedItem, setSelectedItem] = useState<PbshoppingProductInfo>()

    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0)
    const [search, setSearch] = useState('')

    const newItems = useAppSelector((store) => store.return.newItems)
    const cartQuantity = totalNewItemsQuantity(newItems)

    const { totalAvailableCredit, spentCredit } = useCredit()
    const remainingCredit = totalAvailableCredit - spentCredit

    const [variantDrawerOpen, setVariantDrawerOpen] = useState(false)
    const { menu, isMenuLoaded, loadingMenu } = useStoreMenu()
    const { loadNextPage, isLoadingProducts, products, pageInfo } =
        useStoreProducts(menu, selectedCategoryIndex, search, isMenuLoaded)
    const { selectedItemDetails, fetchProductDetails, loadingProductDetails } =
        useStoreProductDetails()

    const handleChangeCategory = (idx: number) => {
        captureEventPosthog('FC - Change category', {
            categoryName: menu[idx]?.title,
            categoryIndex: idx
        })
        setShowCartView(false)
        setSelectedItem(undefined)
        setSelectedCategoryIndex(idx)
    }

    const onProductClick = (product: PbshoppingProductInfo) => {
        captureEventPosthog('FC - View item details', {
            product_id: product.title
        })
        setVariantDrawerOpen(true)
        setSelectedItem(product)
        fetchProductDetails(product.id ?? '')
    }

    const onClickShowMore = () => {
        captureEventPosthog('FC - Click show more')
        loadNextPage()
    }

    const onCloseVariantDrawer = () => {
        setVariantDrawerOpen(false)
    }

    const onCloseVariantSelector = () => {
        setVariantDrawerOpen(false)
    }

    const hasStock = (variant: ReturnsProductDetailsResponseVariantInfo) => {
        return (
            !variant.is_inventory_enabled ||
            (variant.inventory_quantity !== undefined &&
                variant.inventory_quantity >= 1)
        )
    }

    const productPhotoList = () => {
        const productImages =
            selectedItemDetails?.product_info?.image_urls ?? []
        const variantsImages =
            selectedItemDetails?.product_info?.variants
                ?.filter((variant) => hasStock(variant))
                ?.map((variant) => variant?.image_urls?.[0] ?? '') ?? []
        return Array.from(new Set([...productImages, ...variantsImages]))
    }

    const onClickClose = () => {
        if (showCartView) {
            captureEventPosthog('FC - Click close Cart')
            setShowCartView(false)
        } else {
            handleClose()
        }
    }

    const computePrice = (product: PbshoppingProductInfo) => {
        return formatProductPrice(
            product.price_range?.min?.amount,
            product.price_range?.min?.currency,
            lang
        )
    }

    const computeCreditPrice = (product: PbshoppingProductInfo) => {
        const amountValue = product.price_range?.min?.amount ?? '0'
        const parsedAmount = parseFloat(amountValue)
        if (remainingCredit <= 0) {
            return ''
        }
        const restingAmount = remainingCredit < 0 ? 0 : remainingCredit
        const creditValue = parsedAmount - restingAmount
        const value = creditValue < 0 ? 0 : creditValue
        return formatProductPrice(
            value.toString(),
            product.price_range?.min?.currency,
            lang
        )
    }

    return (
        <>
            <Container bgColor={theme.colors.grey[5]}>
                {loadingMenu === 'pending' ? (
                    <LoadingModal loading />
                ) : (
                    <>
                        <DesktopNavBar
                            categories={menu}
                            selectedCategory={selectedCategoryIndex}
                            handleChangeCategory={handleChangeCategory}
                        />
                        <div
                            className="absolute right-2 top-2 z-50 flex size-8 cursor-pointer items-center justify-center rounded-md p-2 hover:bg-gray-200"
                            onClick={onClickClose}
                        >
                            <CrossIcon />
                        </div>

                        {loadingMenu === 'failed' ? (
                            <div className="flex size-full flex-col items-center justify-center gap-4">
                                <div className="opacity-75">
                                    <EmptyCart
                                        lightColor="#cbd5e1"
                                        darkColor="#f1f5f9"
                                    />
                                </div>
                                <div className="mb-20">
                                    <h3 className="mt-2 text-center text-2xl font-semibold">
                                        {t('full_catalog.menu_error_title')}
                                    </h3>
                                    <p className="mt-1 max-w-80 text-center text-base font-light text-slate-500">
                                        {t(
                                            'full_catalog.menu_error_description'
                                        )}
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <div className="relative flex w-full flex-col px-6 pt-6 lg:mx-auto lg:max-w-[1500px]">
                                <div className="flex items-center justify-between gap-4">
                                    <Search
                                        placeholder={
                                            t('full_catalog.search') + '...'
                                        }
                                        value={search}
                                        onChange={setSearch}
                                    />
                                    {!showCartView && (
                                        <div className="mr-5">
                                            <CartCreditBar
                                                setShowCartView={
                                                    setShowCartView
                                                }
                                            />
                                        </div>
                                    )}
                                </div>

                                {showCartView ? (
                                    <div className="mb-8 mt-6">
                                        {cartQuantity > 0 && (
                                            <div className="mb-5 flex items-center justify-between gap-4">
                                                <h4 className="shrink-0 font-medium text-gray-800">
                                                    {t(
                                                        'full_catalog.your_selected_items'
                                                    )}
                                                </h4>
                                                <button
                                                    onClick={() => {
                                                        captureEventPosthog(
                                                            'FC - Click keep-exploring from cart'
                                                        )
                                                        setShowCartView(false)
                                                    }}
                                                    className="group flex items-center gap-1 rounded-lg border border-solid border-brand-500 p-2 text-brand-600 transition-all hover:scale-105 hover:bg-brand-100"
                                                >
                                                    <span className="transition-all duration-300 group-hover:rotate-3 group-hover:scale-125">
                                                        <CartIcon scale={0.8} />
                                                    </span>
                                                    <p className="font-semibold">
                                                        {t(
                                                            'full_catalog.keep_exploring'
                                                        )}
                                                    </p>
                                                </button>
                                            </div>
                                        )}

                                        <CartView
                                            returningItemId={returnedItemId}
                                            onClose={() =>
                                                setShowCartView(false)
                                            }
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <h4 className="mb-4 mt-6 shrink-0 font-medium text-gray-800">
                                            {search
                                                ? search
                                                : menu[selectedCategoryIndex]
                                                      ?.title}
                                        </h4>
                                        {isLoadingProducts ? (
                                            <div className="flex h-[100px] w-full items-center justify-center">
                                                <Spinner />
                                            </div>
                                        ) : (
                                            <ProductsContainer
                                                $show={
                                                    isLoadingProducts === false
                                                }
                                            >
                                                {products?.length > 0 ? (
                                                    <div className="w-full overflow-y-scroll">
                                                        <ItemsDiv
                                                            className="mb-10"
                                                            id="products-grid"
                                                        >
                                                            {products?.map(
                                                                (product) => {
                                                                    const secondaryImage =
                                                                        product.image_urls &&
                                                                        product
                                                                            .image_urls
                                                                            ?.length >=
                                                                            2
                                                                            ? product
                                                                                  .image_urls[1]
                                                                            : ''
                                                                    return (
                                                                        <ProductCard
                                                                            key={
                                                                                product.id
                                                                            }
                                                                            id={
                                                                                product.id ??
                                                                                ''
                                                                            }
                                                                            name={
                                                                                product.title ??
                                                                                ''
                                                                            }
                                                                            imageUrl={
                                                                                product.featured_image_url ??
                                                                                ''
                                                                            }
                                                                            secondaryImageUrl={
                                                                                secondaryImage
                                                                            }
                                                                            description={
                                                                                product.description ??
                                                                                ''
                                                                            }
                                                                            isFrom={
                                                                                !variantsHaveSamePrices(
                                                                                    product?.price_range
                                                                                )
                                                                            }
                                                                            price={computePrice(
                                                                                product
                                                                            )}
                                                                            creditPrice={computeCreditPrice(
                                                                                product
                                                                            )}
                                                                            onProductClick={() =>
                                                                                onProductClick(
                                                                                    product
                                                                                )
                                                                            }
                                                                        />
                                                                    )
                                                                }
                                                            )}
                                                        </ItemsDiv>
                                                        <div className="mb-36">
                                                            {pageInfo?.has_next_page &&
                                                                isLoadingProducts ===
                                                                    false && (
                                                                    <div className="flex">
                                                                        <ShowMoreButton
                                                                            onClick={() =>
                                                                                onClickShowMore()
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            {search !== '' &&
                                                                products?.length ===
                                                                    0 && (
                                                                    <div className="flex flex-col">
                                                                        <p className="mx-auto mt-4 text-xl text-gray-400">
                                                                            {t(
                                                                                'full_catalog.no_products_found'
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            {!pageInfo?.has_next_page &&
                                                                products &&
                                                                products?.length >
                                                                    0 && (
                                                                    <div className="mb-7 mt-8 flex">
                                                                        <p className="mx-auto border-b-2 border-solid border-gray-400 pb-5 text-sm text-gray-400">
                                                                            {t(
                                                                                'full_catalog.showing_of',
                                                                                {
                                                                                    number: products?.length
                                                                                }
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="flex w-full flex-col items-center justify-center">
                                                        <div className="opacity-75">
                                                            <EmptyCart
                                                                lightColor="#cbd5e1"
                                                                darkColor="#f1f5f9"
                                                            />
                                                        </div>
                                                        <h3 className="mt-6 text-center text-2xl font-semibold">
                                                            {t(
                                                                'full_catalog.empty_category_title'
                                                            )}
                                                        </h3>
                                                        <p className="mt-1 max-w-80 text-center text-base font-light text-slate-500">
                                                            {t(
                                                                'full_catalog.empty_category_description'
                                                            )}
                                                        </p>
                                                    </div>
                                                )}
                                            </ProductsContainer>
                                        )}
                                    </>
                                )}
                                <div className="absolute inset-x-0 bottom-0 flex justify-center">
                                    <div className="flex flex-nowrap items-center gap-8 rounded-t-3xl border border-solid border-neutral-200 bg-white px-10 py-3 shadow-lg">
                                        <CreditBar />
                                        {newItems.length ? (
                                            <Button
                                                onClick={handleContinue}
                                                id="fc-continue-button"
                                            >
                                                <span className="mx-6 flex items-center justify-center gap-3 text-base">
                                                    {t('full_catalog.confirm')}
                                                    <span className="flex size-6 items-center justify-center rounded-full border border-solid border-white/50 text-center text-xs font-bold">
                                                        {cartQuantity}
                                                    </span>
                                                </span>
                                            </Button>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </Container>
            <Drawer
                anchor="bottom"
                open={variantDrawerOpen}
                onClose={onCloseVariantDrawer}
                sx={{
                    backdropFilter: 'blur(1px)',
                    borderTopLeftRadius: '0.5rem',
                    borderTopRightRadius: '0.5rem'
                }}
                PaperProps={{
                    style: {
                        height: '600px',
                        top: 'calc(100% - 600px)',
                        borderTopLeftRadius: '0.5rem',
                        borderTopRightRadius: '0.5rem'
                    }
                }}
            >
                <DesktopVariantSelector
                    productId={selectedItem?.id ?? ''}
                    mainImage={selectedItem?.featured_image_url ?? ''}
                    selectedCategoryIndex={selectedCategoryIndex}
                    returnedItemId={returnedItemId}
                    name={selectedItem?.title ?? ''}
                    description={selectedItem?.description ?? ''}
                    product={selectedItemDetails ?? {}}
                    onClose={onCloseVariantSelector}
                    photoList={productPhotoList()}
                    loadingDetails={loadingProductDetails}
                />
            </Drawer>
        </>
    )
}

const Container = styled.div<{ bgColor: string }>`
    z-index: 9;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${(p) => p.bgColor};
`

const ItemsDiv = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    gap: 1rem;
`
interface ProductsContainerProps {
    $show: boolean
}
const ProductsContainer = styled.div<ProductsContainerProps>`
    overflow-y: scroll;
    display: ${(p) => (p.$show ? 'flex' : 'none')};
    transition: all 0.5s ease-in;
`
