import {
    ActionButton,
    SuccessIcon,
    toast,
    useTheme
} from '@itsrever/design-system'
import styled from 'styled-components'
import { TrackingHeader } from '@/components/TrackingComponents'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { UploadImages } from '@/components/UploadImages'
import { UploadedImagePreview } from '@/components/UploadedImagePreview'
import { useResizeImage, useUploadReturnDocs } from '@/hooks'
import { useAppSelector } from '@/redux/hooks'
import { CircularProgress } from '@mui/material'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import AlertTriangleIcon from '@/assets/images/icons/AlertTriangleIcon'

export type PreviewFile = {
    file: string
    preview: string
    description: string
}

export default function TrackingUploadDocuments() {
    const { t } = useTranslation()
    const theme = useTheme()
    const [parent] = useAutoAnimate()

    const [files, setFiles] = useState<PreviewFile[]>([])
    const [totalSize, setTotalSize] = useState(0)

    const processId = useAppSelector((s) => s.order.selectedProcessID)
    const { uploadDocs, uploading, successUpload } = useUploadReturnDocs()
    const { resizeFileAndTransformToBase64 } = useResizeImage()

    function fileSizeInMb(file: string) {
        const binaryString = atob(file.split(',')[1])
        return binaryString.length / 1024 / 1024
    }

    const onUpload = async (inputFiles: FileList) => {
        const newFilesList: PreviewFile[] = [...files]
        let newTotalSize = totalSize
        let hasError = false

        for (let i = 0; i < inputFiles.length; i++) {
            const file = await resizeFileAndTransformToBase64(inputFiles[i])
            const fileSizeMB = fileSizeInMb(file)

            if (fileSizeMB <= 3.5 && newTotalSize + fileSizeMB <= 3.5) {
                const binaryString = atob(file.split(',')[1])
                const bytes = new Uint8Array(binaryString.length)
                for (let j = 0; j < binaryString.length; j++) {
                    bytes[j] = binaryString.charCodeAt(j)
                }
                const blob = new Blob([bytes], { type: 'image/jpeg' })
                newFilesList.push({
                    file,
                    preview: URL.createObjectURL(blob),
                    description: ''
                })
                newTotalSize += fileSizeMB
            } else {
                hasError = true
            }
        }

        if (hasError) {
            toast({
                variant: 'error',
                text: t('upload_images.size_limit')
            })
            return
        }
        setFiles(newFilesList)
        setTotalSize(newTotalSize)
    }
    const [editingImage, setEditingImage] = useState<number | undefined>(
        undefined
    )

    const onClear = (index: number) => {
        const newFiles = files.filter((_, i) => i !== index)
        const newTotalSize = newFiles.reduce((acc, file) => {
            return acc + fileSizeInMb(file.file)
        }, 0)
        setFiles(newFiles)
        setTotalSize(newTotalSize)
    }

    const changeDescription = (index: number, value: string) => {
        const newFiles = files.map((file, i) => {
            if (i === index) return { ...file, description: value }
            return file
        })
        setFiles(newFiles)
    }

    const ableToUpload = () => {
        if (uploading) return false
        if (files.length === 0) return false
        if (files.some((file) => file.description === '')) return false
        return true
    }
    const [uploadClicked, setUploadClicked] = useState(false)

    const handleClickUpload = () => {
        setUploadClicked(true)
        if (!ableToUpload()) {
            return
        }
        uploadDocs(processId ?? '', files)
    }

    return (
        <>
            <MainDiv bgcolor={theme.colors.grey[5]}>
                <TrackingHeader>
                    <div className="flex w-full justify-center text-brand-900">
                        <div className="ml-7 w-[300px] md:ml-0">
                            <h4 className="text-2xl">
                                {t('tracking_pages.upload_documents')}
                            </h4>
                        </div>
                    </div>
                </TrackingHeader>
                <ContentDiv>
                    {files.length === 0 && (
                        <p className="mt-3 flex items-center gap-1 text-center text-lg text-gray-400">
                            {t('upload_images.upload_one_or_more_photos')}
                        </p>
                    )}

                    {successUpload ? (
                        <div className="mt-6 px-4">
                            <div className="flex w-full justify-center">
                                <SuccessIcon width={60} height={60} />
                            </div>
                            <p className="mt-4 text-lg text-gray-700">
                                {t('upload_images.success_description')}
                            </p>
                        </div>
                    ) : (
                        <>
                            <div
                                className="mt-2 flex w-full flex-col gap-4 px-3"
                                ref={parent}
                            >
                                {files.map((file, index) => (
                                    <UploadedImagePreview
                                        key={index}
                                        src={file.preview}
                                        index={index}
                                        isEditing={editingImage === index}
                                        setEditingImage={setEditingImage}
                                        onClear={onClear}
                                        value={file.description}
                                        onChangeText={changeDescription}
                                    />
                                ))}
                            </div>
                            <div className="mb-20">
                                <UploadImages
                                    onUpload={onUpload}
                                    isInitial={files.length === 0}
                                />
                            </div>
                        </>
                    )}
                </ContentDiv>
                <div className="fixed bottom-0 flex w-full flex-col items-center justify-center bg-white py-3">
                    {!ableToUpload() && files.length > 0 && uploadClicked && (
                        <p className="my-3 flex items-center gap-1 text-center text-lg text-gray-400">
                            <span className="inline-block size-6 text-yellow-400">
                                <AlertTriangleIcon />
                            </span>
                            {t('upload_images.add_reason')}
                        </p>
                    )}

                    {files.length > 0 && !successUpload && (
                        <ActionButton onClick={handleClickUpload}>
                            {uploading ? (
                                <CircularProgress
                                    size="16px"
                                    thickness={1.5}
                                    sx={{ color: '#fff' }}
                                />
                            ) : (
                                <>
                                    <p className="px-2 text-lg md:text-base">
                                        {t('upload_images.send')}
                                    </p>
                                </>
                            )}
                        </ActionButton>
                    )}
                </div>
            </MainDiv>
        </>
    )
}

const MainDiv = styled.div<{ bgcolor: string }>`
    flex-grow: 1;
`

const ContentDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;
`
