import { uploadDocuments } from '@/api/processesApi'
import { PreviewFile } from '@/pages/Tracking/Upload.page'
import { toast } from '@itsrever/design-system'
import { PbUploadReturnDocumentsRequestDocument } from '@itsrever/rever-api'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export function useUploadReturnDocs() {
    const { t } = useTranslation()

    const [uploading, setUploading] = useState(false)
    const [successUpload, setSuccessUpload] = useState(false)

    async function uploadDocs(processId: string, documents: PreviewFile[]) {
        try {
            setUploading(true)
            const backendDocuments = await toBackendDocuments(documents)
            setSuccessUpload(true)
            await uploadDocuments(processId, backendDocuments)
            toast({
                variant: 'success',
                text: t('upload_images.successful_upload')
            })
        } catch (error) {
            toast({
                variant: 'error',
                text: 'Error uploading documents!'
            })
            console.warn(error)
        } finally {
            setUploading(false)
        }
    }

    return { uploading, uploadDocs, successUpload }
}

function toBackendDocuments(
    documents: PreviewFile[]
): PbUploadReturnDocumentsRequestDocument[] {
    return documents.map((doc) => {
        const backDoc: PbUploadReturnDocumentsRequestDocument = {
            document_information: doc.description,
            data: doc.file.split(',')[1]
        }
        return backDoc
    })
}
