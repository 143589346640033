import styled from 'styled-components'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { device } from '@/utils'

import DownloadLabelIcon from '@/assets/images/icons/DownloadLabelIcon'
import DropOffIcon from '@/assets/images/icons/DropOffIcon'
import SchedulePickupIcon from '@/assets/images/icons/SchedulePickupIcon'
import MoneyIcon from '@/assets/images/icons/MoneyIcon'
import QuestionMarkIcon from '@/assets/images/icons/QuestionMarkIcon'
import UploadImagesIcon from '@/assets/images/icons/UploadImagesIcon'
import AlertTriangleIcon from '@/assets/images/icons/AlertTriangleIcon'
import XIcon from '@/assets/images/icons/XIcon'
import EmailIcon from '@/assets/images/icons/EmailIcon'

import { Trans, useTranslation } from 'react-i18next'
import { useDownloadLabel, usePages } from '@/hooks'
import { useAppSelector } from '@/redux/hooks'
import { usePostHog } from '@/application/use-posthog'
import { useContext, useEffect, useState } from 'react'
import { useTheme } from '@itsrever/design-system'
import reverLogo from '@/assets/images/REVER-logo-2024.svg'
import { ExchangeInformation } from '@/components/ExchangeInformation'
import { TrackingProgressBar } from '@/components/TrackingProgressBar'
import { RefundInformation } from '@/components/RefundInformation'
import { TrackingHeader } from '@/components/TrackingComponents'
import { TrackingContext } from '@/layout/TrackingLayout'
import Summary from '@/components/Summary'
import { Drawer } from '@mui/material'
import { LineItemSubtypes } from '@/redux/return/returnSlice'
import ClockIcon from '@/assets/images/icons/ClockIcon'
import { PbECommerceSettingsUseCaseEnum } from '@itsrever/rever-api'
import StarIcon from '@/assets/images/icons/StarIcon'

function TrackingPage() {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const { pages } = usePages()
    const theme = useTheme()
    const { captureEventPosthog } = usePostHog()

    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )
    const isReverLite =
        settings.use_case === PbECommerceSettingsUseCaseEnum.Lite
    const ecommerceId = settings.ecommerce_id ?? ''
    const ecommerceName = settings.name ?? ''
    const logoUrl = settings.theme?.logo_url

    const [searchParams] = useSearchParams()
    const source = searchParams.get('source') ?? 'direct'

    const trackingInfo = useContext(TrackingContext)

    const isKeepYourItem =
        trackingInfo.keep_your_item_type &&
        trackingInfo.keep_your_item_type !== 'KEEP_YOUR_ITEM_TYPE_NONE' &&
        trackingInfo.keep_your_item_type !== 'KEEP_YOUR_ITEM_TYPE_UNSPECIFIED'

    const logisticsInfo = trackingInfo?.logistics_info

    const summary = trackingInfo?.summary
    const exchangeItems = summary?.exchange_items ?? []
    const newExchangeOrderNumber =
        trackingInfo?.exchange_order_info?.order_number ?? ''
    const isExchange = exchangeItems.length > 0

    const status = trackingInfo?.status ?? 'RETURN_STATUS_UNSPECIFIED'
    const orderInfo = useAppSelector((store) => store.order)
    const processId = orderInfo.selectedProcessID
    const orderNumber =
        trackingInfo.customer_printed_order_id ?? orderInfo.orderNumber
    const refundNotNecessary =
        trackingInfo?.refund_info?.status === 'REFUND_NOT_NECESSARY'
    const isDeclined = trackingInfo?.review_result === 'FULLY_DECLINED'
    const amountFormatted =
        trackingInfo?.refund_info?.status === 'FULLY_REFUNDED'
            ? trackingInfo?.refund_info?.executed_formatted_amount ?? ''
            : trackingInfo?.refund_info?.pending_formatted_amount ?? ''
    const returnedItems = summary?.returned_items ?? []
    const costs = (summary?.costs ?? []).filter(
        (item) =>
            item.subtype !== LineItemSubtypes.RdvSurplus &&
            item.subtype !== LineItemSubtypes.ServiceFee &&
            item.subtype !== LineItemSubtypes.OriginalPromotionBroken
    )
    const email = useAppSelector((store) => store.order.email)
    const { labelURL } = useDownloadLabel(processId, email)

    const RDVSurplus = summary?.costs?.find(
        (item) => item.subtype === LineItemSubtypes.RdvSurplus
    )
    const serviceFee = summary?.costs?.find(
        (item) => item.subtype === LineItemSubtypes.ServiceFee
    )
    const originalPromotionBroken = summary?.costs?.find(
        (item) => item.subtype === LineItemSubtypes.OriginalPromotionBroken
    )

    const subTotal = () => {
        let subTotal = 0
        returnedItems.forEach((item) => {
            subTotal += Number(item.total ?? 0) ?? 0
        })
        exchangeItems.forEach((item) => {
            subTotal += Number(item.total ?? 0) ?? 0
        })
        return subTotal
    }
    const total = () => {
        let total = subTotal()
        costs.forEach((cost) => {
            total += Number(cost.total ?? 0) ?? 0
        })
        return total
    }

    const refundDate = new Date(trackingInfo?.refund_info?.refunded_at ?? '')
    const refundedAt = refundDate.toLocaleDateString(i18n.language, {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    })

    const showSchedulePickupButton =
        logisticsInfo?.pickup_info?.schedule_url !== undefined &&
        !isKeepYourItem
    const showDropoffPointsButton =
        logisticsInfo?.dropoff_info?.points_url !== undefined && !isKeepYourItem
    const showInStoreButton =
        logisticsInfo?.in_store_info?.store_list_url !== undefined &&
        !isKeepYourItem

    const showDownloadLabelButton =
        logisticsInfo?.in_store_info?.barcode_url !== undefined ||
        Boolean(labelURL)

    useEffect(() => {
        captureEventPosthog('Tracking visit (main)', {
            source
        })
    }, [])

    const group = useAppSelector((s) => s.settingsApi.settings.response.group)
    const isTropicfeel = group === 'tropicfeel'

    const handleClickDownloadLabel = () => {
        navigate(pages.TrackingReturnLabel)
    }
    const handleClickDropOff = () => {
        navigate(pages.TrackingDropOff)
    }
    const handleClickSchedulePickup = () => {
        navigate(pages.TrackingPickUp)
    }
    const [drawerOpen, setDrawerOpen] = useState(false)

    const buttonIcon = (name: string) => {
        const Icon = () => {
            switch (name) {
                case 'download':
                    return <DownloadLabelIcon />
                case 'dropoff':
                    return <DropOffIcon />
                case 'schedule':
                    return <SchedulePickupIcon />
                case 'money':
                    return <MoneyIcon />
                case 'question':
                    return <QuestionMarkIcon />
                default:
                    return <QuestionMarkIcon />
            }
        }
        return (
            <div className="absolute -left-4 -top-2 z-0 size-20 -rotate-3 items-center text-brand-100">
                {Icon()}
            </div>
        )
    }

    const uploadDocumentsEnabled =
        trackingInfo.upload_documents_info?.upload_documents_enabled ?? false

    const uploadedDocumentsAmount =
        trackingInfo?.upload_documents_info?.uploaded_documents?.length ?? 0

    const authorizationPending = trackingInfo.status === 'AUTHORIZATION_PENDING'
    const notAuthorized = trackingInfo.status === 'NOT_AUTHORIZED'
    const canceled = trackingInfo.status === 'CANCELED'

    const showRefundInformation =
        !refundNotNecessary &&
        (!isReverLite || (!authorizationPending && !notAuthorized))

    const onlyExchanges =
        trackingInfo?.summary?.returned_items?.length ===
        trackingInfo?.summary?.exchange_items?.length
    const isRefunded =
        trackingInfo?.refund_info?.status === 'FULLY_REFUNDED' ||
        trackingInfo?.refund_info?.status === 'PARTIALLY_REFUNDED'

    const showNpsButton = () => {
        if (showRefundInformation) {
            if (isRefunded) {
                return true
            } else {
                return false
            }
        } else if (
            onlyExchanges &&
            trackingInfo?.exchange_order_info?.order_number !== undefined
        ) {
            return true
        } else {
            return false
        }
    }

    const npsLanguage = i18n.language === 'es' ? 'es' : 'en'
    const npsLink = (npsLanguage: string) => {
        if (npsLanguage === 'es') {
            return (
                'https://form.typeform.com/to/GS6pApwY#returnid=' +
                processId +
                '&ecommerceid=' +
                ecommerceId +
                '&source=trackinginterface'
            )
        } else {
            return (
                'https://form.typeform.com/to/k7Jhs21E#returnid=' +
                processId +
                '&ecommerceid=' +
                ecommerceId +
                '&source=trackinginterface'
            )
        }
    }

    return (
        <>
            <MainDiv bgcolor={theme.colors.grey[5]}>
                <TrackingHeader showBackButton={false}>
                    <div className="w-full">
                        {isTropicfeel ? (
                            <div className="flex w-full flex-col items-center justify-center">
                                <div>
                                    <LogoContainer
                                        className="w-28"
                                        src={logoUrl}
                                    />
                                </div>
                                <p className="ml-2 mr-4 mt-1 flex font-light text-white">
                                    <span className="mr-2 text-xs text-brand-900">
                                        by
                                    </span>
                                    <img
                                        className="w-10 opacity-100"
                                        src={reverLogo}
                                    />
                                </p>
                            </div>
                        ) : (
                            <div className="mt-2 flex w-full items-center justify-center opacity-70">
                                <p
                                    className="mr-1 text-xs font-light text-slate-400 "
                                    style={{ color: 'rgb(34, 144, 255)' }}
                                >
                                    Powered by
                                </p>
                                <img
                                    className="w-10 opacity-100"
                                    src={reverLogo}
                                />
                            </div>
                        )}

                        {isKeepYourItem ? null : (
                            <TrackingProgressBar status={status} />
                        )}

                        {isDeclined ? (
                            <div className="flex">
                                <p className="mx-auto rounded-md bg-red-300 px-2 py-1 text-red-800">
                                    {t('tracking_layout.declined')}
                                </p>
                            </div>
                        ) : null}

                        <div className="mt-8 flex items-stretch justify-center gap-5">
                            <div
                                className="flex cursor-pointer flex-col items-center justify-center gap-8"
                                onClick={() =>
                                    navigate(pages.TrackingReturnDetails)
                                }
                            >
                                {showRefundInformation && (
                                    <div>
                                        <RefundInformation
                                            amountFormatted={amountFormatted}
                                            paymentMethod={
                                                trackingInfo?.refund_info
                                                    ?.payment_method
                                            }
                                            status={
                                                trackingInfo?.refund_info
                                                    ?.status
                                            }
                                            refundedAt={refundedAt}
                                        />
                                    </div>
                                )}

                                <div>
                                    {isExchange ? (
                                        <ExchangeInformation
                                            exchangedItems={exchangeItems}
                                            newExchangeOrderNumber={
                                                newExchangeOrderNumber
                                            }
                                            showItems={
                                                exchangeItems.length === 1
                                            }
                                            showOrderNumber={
                                                exchangeItems.length > 1
                                            }
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </TrackingHeader>
                <ContentDiv>
                    {uploadDocumentsEnabled && !notAuthorized ? (
                        <div className="-mt-4 mb-4 text-center">
                            <div className="mb-2 flex items-center text-xs text-slate-500">
                                {uploadedDocumentsAmount > 0 ? (
                                    <div className="flex w-full justify-center gap-2">
                                        <p className="size-5 rounded-full bg-blue-500 text-white">
                                            {uploadedDocumentsAmount}
                                        </p>
                                        <p>
                                            {t(
                                                'tracking_pages.documents_uploaded'
                                            )}
                                        </p>
                                    </div>
                                ) : (
                                    <>
                                        <span className="mr-1 inline-block size-6 text-yellow-400">
                                            <AlertTriangleIcon />
                                        </span>
                                        <p>
                                            {t(
                                                'tracking_pages.documents_required'
                                            )}
                                        </p>
                                    </>
                                )}
                            </div>
                            <a
                                onClick={() =>
                                    navigate(pages.TrackingUploadDocuments)
                                }
                                className="relative flex h-10 cursor-pointer items-center overflow-hidden rounded-xl border bg-white px-8 py-3 shadow-sm"
                            >
                                <div className="mr-2 w-5 text-slate-600">
                                    <UploadImagesIcon />
                                </div>
                                <p className="z-50 text-slate-800">
                                    {uploadedDocumentsAmount > 0 ? (
                                        <>
                                            {t(
                                                'tracking_pages.upload_more_documents'
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {t(
                                                'tracking_pages.upload_documents'
                                            )}
                                        </>
                                    )}
                                </p>
                            </a>
                        </div>
                    ) : null}

                    {canceled && (
                        <p className="text-base text-gray-700">
                            <Trans
                                i18nKey="tracking_pages.return_canceled"
                                values={{ ecommerce_name: ecommerceName }}
                            />
                        </p>
                    )}

                    {authorizationPending && (
                        <div className="my-2 flex items-center gap-2 rounded-lg border border-solid border-neutral-300 px-3 py-4 text-xs text-neutral-700">
                            <div className="flex size-8 text-blue-500">
                                <ClockIcon />
                            </div>
                            <p>
                                {t('tracking.pending_authorization', {
                                    ecommerce_name: ecommerceName
                                })}
                            </p>
                        </div>
                    )}
                    {notAuthorized && (
                        <div className="my-2 flex items-center gap-2 rounded-lg border border-solid border-neutral-300 px-2 py-3 text-xs text-neutral-700">
                            <div className="flex flex-col gap-2">
                                <div className="flex items-center justify-center gap-2">
                                    <div className="flex size-12 items-center justify-center text-red-500">
                                        <XIcon />
                                    </div>
                                    <p>
                                        {t('tracking.not_authorized', {
                                            ecommerce_name: ecommerceName
                                        })}
                                    </p>
                                </div>
                                <hr />
                                <div className="flex items-center justify-center gap-2">
                                    <div className="flex size-12 items-center justify-center text-neutral-300">
                                        <EmailIcon />
                                    </div>
                                    <p>{t('tracking.not_authorized_email')}</p>
                                </div>
                            </div>
                        </div>
                    )}
                    {!(authorizationPending || notAuthorized || canceled) && (
                        <div className="flex w-fit flex-col gap-3">
                            {!isReverLite && (
                                <ActionButton
                                    onClick={() =>
                                        navigate(pages.TrackingReturnDetails)
                                    }
                                    className="relative h-16 overflow-hidden rounded-xl border border-solid border-brand-200 bg-white bg-gradient-to-b from-slate-50 to-slate-100 px-12 py-3  shadow-lg"
                                >
                                    {buttonIcon('question')}
                                    <p className="z-50 font-bold text-brand-900">
                                        {t('tracking_pages.refund_status')}
                                    </p>
                                </ActionButton>
                            )}

                            {showDownloadLabelButton ? (
                                <ActionButton
                                    onClick={handleClickDownloadLabel}
                                    className="relative h-16 overflow-hidden rounded-xl border border-solid border-brand-200 bg-white bg-gradient-to-b from-slate-50 to-slate-100 px-12 py-3  shadow-lg"
                                >
                                    {buttonIcon('download')}
                                    <p className="z-50 font-bold text-brand-900">
                                        {t('tracking_pages.return_label')}
                                    </p>
                                </ActionButton>
                            ) : null}

                            {showDropoffPointsButton ? (
                                <ActionButton
                                    onClick={handleClickDropOff}
                                    className="relative h-16 overflow-hidden rounded-xl border border-solid border-brand-200 bg-white bg-gradient-to-b from-slate-50 to-slate-100 px-12 py-3  shadow-lg"
                                >
                                    {buttonIcon('dropoff')}
                                    <p className="z-50 font-bold text-brand-900">
                                        {t('tracking_pages.drop_off')}
                                    </p>
                                </ActionButton>
                            ) : null}

                            {showInStoreButton ? (
                                <ActionButton
                                    className="relative h-16 overflow-hidden rounded-xl border border-solid border-brand-200 bg-white bg-gradient-to-b from-slate-50 to-slate-100 px-12 py-3  shadow-lg"
                                    target="_blank"
                                    href={
                                        trackingInfo?.logistics_info
                                            ?.in_store_info?.store_list_url
                                    }
                                >
                                    {buttonIcon('dropoff')}
                                    <p className="z-50 font-bold text-brand-900">
                                        {t('tracking_pages.in_store')}
                                    </p>
                                </ActionButton>
                            ) : null}

                            {showSchedulePickupButton ? (
                                <ActionButton
                                    onClick={handleClickSchedulePickup}
                                    className="relative h-16 overflow-hidden rounded-xl border border-solid border-brand-200 bg-white bg-gradient-to-b from-slate-50 to-slate-100 px-12 py-3  shadow-lg"
                                >
                                    {buttonIcon('schedule')}
                                    <p className="z-50 font-bold text-brand-900">
                                        {t('tracking_pages.pickup')}
                                    </p>
                                </ActionButton>
                            ) : null}

                            {!isReverLite && (
                                <ActionButton
                                    onClick={() =>
                                        navigate(pages.TrackingReturnDetails)
                                    }
                                    className="relative h-16 overflow-hidden rounded-xl border border-solid border-brand-200 bg-white bg-gradient-to-b from-slate-50 to-slate-100 px-12 py-3  shadow-lg"
                                >
                                    {buttonIcon('money')}
                                    <p className="z-50 font-bold text-brand-900">
                                        {t('tracking_pages.compensation')}
                                    </p>
                                </ActionButton>
                            )}
                            {showNpsButton() && (
                                <div className="mt-4 flex justify-center">
                                    <a
                                        target="_blank"
                                        href={npsLink(npsLanguage)}
                                        className="relative flex w-fit justify-center gap-2 overflow-hidden rounded-xl border border-solid border-brand-100 bg-white px-6 py-2 text-center text-brand-500 shadow transition-all hover:bg-brand-50"
                                        rel="noreferrer"
                                    >
                                        <span className="size-5">
                                            <StarIcon />
                                        </span>
                                        <p>{t('tracking_layout.nps_button')}</p>
                                    </a>
                                </div>
                            )}
                        </div>
                    )}
                </ContentDiv>

                <div
                    style={{
                        boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.2)'
                    }}
                    onClick={() => setDrawerOpen(true)}
                    className="fixed bottom-0 z-50 mt-5 flex w-full cursor-pointer flex-col content-center border-solid border-slate-300 bg-white py-3"
                >
                    <div className="relative">
                        <div className="absolute -top-5 h-5 w-full md:w-1/2">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 90 5"
                            >
                                <path
                                    d="M 0 0 L 1.25 1.25 L 2.5 0 L 3.75 1.25 L 5 0 L 6.25 1.25 L 7.5 0 L 8.75 1.25 L 10 0 L 11.25 1.25 L 12.5 0 L 13.75 1.25 L 15 0 L 16.25 1.25 L 17.5 0 L 18.75 1.25 L 20 0 L 21.25 1.25 L 22.5 0 L 23.75 1.25 L 25 0 L 26.25 1.25 L 27.5 0 L 28.75 1.25 L 30 0 L 31.25 1.25 L 32.5 0 L 33.75 1.25 L 35 0 L 36.25 1.25 L 37.5 0 L 38.75 1.25 L 40 0 L 41.25 1.25 L 42.5 0 L 43.75 1.25 L 45 0 L 46.25 1.25 L 47.5 0 L 48.75 1.25 L 50 0 L 51.25 1.25 L 52.5 0 L 53.75 1.25 L 55 0 L 56.25 1.25 L 57.5 0 L 58.75 1.25 L 60 0 L 61.25 1.25 L 62.5 0 L 63.75 1.25 L 65 0 L 66.25 1.25 L 67.5 0 L 68.75 1.25 L 70 0 L 71.25 1.25 L 72.5 0 L 73.75 1.25 L 75 0 L 76.25 1.25 L 77.5 0 L 78.75 1.25 L 80 0 L 81.25 1.25 L 82.5 0 L 83.75 1.25 L 85 0 L 86.25 1.25 L 87.5 0 L 88.75 1.25 L 90 0 L 90 3.33333333333 L 0 3.33333333333 Z"
                                    fill="#ffffff"
                                />
                            </svg>
                        </div>
                        {device.md && (
                            <div className="absolute -top-5 right-0 h-5 w-full md:w-1/2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 90 5"
                                >
                                    <path
                                        d="M 0 0 L 1.25 1.25 L 2.5 0 L 3.75 1.25 L 5 0 L 6.25 1.25 L 7.5 0 L 8.75 1.25 L 10 0 L 11.25 1.25 L 12.5 0 L 13.75 1.25 L 15 0 L 16.25 1.25 L 17.5 0 L 18.75 1.25 L 20 0 L 21.25 1.25 L 22.5 0 L 23.75 1.25 L 25 0 L 26.25 1.25 L 27.5 0 L 28.75 1.25 L 30 0 L 31.25 1.25 L 32.5 0 L 33.75 1.25 L 35 0 L 36.25 1.25 L 37.5 0 L 38.75 1.25 L 40 0 L 41.25 1.25 L 42.5 0 L 43.75 1.25 L 45 0 L 46.25 1.25 L 47.5 0 L 48.75 1.25 L 50 0 L 51.25 1.25 L 52.5 0 L 53.75 1.25 L 55 0 L 56.25 1.25 L 57.5 0 L 58.75 1.25 L 60 0 L 61.25 1.25 L 62.5 0 L 63.75 1.25 L 65 0 L 66.25 1.25 L 67.5 0 L 68.75 1.25 L 70 0 L 71.25 1.25 L 72.5 0 L 73.75 1.25 L 75 0 L 76.25 1.25 L 77.5 0 L 78.75 1.25 L 80 0 L 81.25 1.25 L 82.5 0 L 83.75 1.25 L 85 0 L 86.25 1.25 L 87.5 0 L 88.75 1.25 L 90 0 L 90 3.33333333333 L 0 3.33333333333 Z"
                                        fill="#ffffff"
                                    />
                                </svg>
                            </div>
                        )}
                        <div className="flex justify-center">
                            <p className="mb-2 h-1 w-16 rounded-full bg-slate-200"></p>
                        </div>
                        <div className="flex justify-center">
                            {orderNumber ? (
                                <div className="mx-auto flex">
                                    <p className="mb-1 text-base font-semibold text-slate-400">
                                        {t('tracking_pages.return_from_order')}{' '}
                                        {orderNumber}
                                    </p>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </MainDiv>
            <Drawer
                anchor="bottom"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                sx={{ backdropFilter: 'blur(5px)' }}
                PaperProps={{
                    sx: {
                        overflow: 'visible'
                    }
                }}
            >
                <div className="relative h-[600px] opacity-100">
                    <div className="absolute -top-3 z-50 h-10 w-full md:w-1/2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 90 10"
                        >
                            <path
                                d="M 0 0 L 1.25 1.25 L 2.5 0 L 3.75 1.25 L 5 0 L 6.25 1.25 L 7.5 0 L 8.75 1.25 L 10 0 L 11.25 1.25 L 12.5 0 L 13.75 1.25 L 15 0 L 16.25 1.25 L 17.5 0 L 18.75 1.25 L 20 0 L 21.25 1.25 L 22.5 0 L 23.75 1.25 L 25 0 L 26.25 1.25 L 27.5 0 L 28.75 1.25 L 30 0 L 31.25 1.25 L 32.5 0 L 33.75 1.25 L 35 0 L 36.25 1.25 L 37.5 0 L 38.75 1.25 L 40 0 L 41.25 1.25 L 42.5 0 L 43.75 1.25 L 45 0 L 46.25 1.25 L 47.5 0 L 48.75 1.25 L 50 0 L 51.25 1.25 L 52.5 0 L 53.75 1.25 L 55 0 L 56.25 1.25 L 57.5 0 L 58.75 1.25 L 60 0 L 61.25 1.25 L 62.5 0 L 63.75 1.25 L 65 0 L 66.25 1.25 L 67.5 0 L 68.75 1.25 L 70 0 L 71.25 1.25 L 72.5 0 L 73.75 1.25 L 75 0 L 76.25 1.25 L 77.5 0 L 78.75 1.25 L 80 0 L 81.25 1.25 L 82.5 0 L 83.75 1.25 L 85 0 L 86.25 1.25 L 87.5 0 L 88.75 1.25 L 90 0 L 90 3.33333333333 L 0 3.33333333333 Z"
                                fill="#ffffff"
                            />
                        </svg>
                    </div>
                    {device.md && (
                        <div className="absolute -top-3 right-0 z-50 h-10 w-full md:w-1/2">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 90 10"
                            >
                                <path
                                    d="M 0 0 L 1.25 1.25 L 2.5 0 L 3.75 1.25 L 5 0 L 6.25 1.25 L 7.5 0 L 8.75 1.25 L 10 0 L 11.25 1.25 L 12.5 0 L 13.75 1.25 L 15 0 L 16.25 1.25 L 17.5 0 L 18.75 1.25 L 20 0 L 21.25 1.25 L 22.5 0 L 23.75 1.25 L 25 0 L 26.25 1.25 L 27.5 0 L 28.75 1.25 L 30 0 L 31.25 1.25 L 32.5 0 L 33.75 1.25 L 35 0 L 36.25 1.25 L 37.5 0 L 38.75 1.25 L 40 0 L 41.25 1.25 L 42.5 0 L 43.75 1.25 L 45 0 L 46.25 1.25 L 47.5 0 L 48.75 1.25 L 50 0 L 51.25 1.25 L 52.5 0 L 53.75 1.25 L 55 0 L 56.25 1.25 L 57.5 0 L 58.75 1.25 L 60 0 L 61.25 1.25 L 62.5 0 L 63.75 1.25 L 65 0 L 66.25 1.25 L 67.5 0 L 68.75 1.25 L 70 0 L 71.25 1.25 L 72.5 0 L 73.75 1.25 L 75 0 L 76.25 1.25 L 77.5 0 L 78.75 1.25 L 80 0 L 81.25 1.25 L 82.5 0 L 83.75 1.25 L 85 0 L 86.25 1.25 L 87.5 0 L 88.75 1.25 L 90 0 L 90 3.33333333333 L 0 3.33333333333 Z"
                                    fill="#ffffff"
                                />
                            </svg>
                        </div>
                    )}
                    <div className="px-10 py-6 ">
                        <p className="mb-5 text-xl text-slate-700">
                            Order {orderNumber}
                        </p>
                        <Summary
                            returnedItems={returnedItems}
                            exchangeItems={exchangeItems}
                            costs={costs}
                            totalRefundAmount={total()}
                            RDVSurplus={RDVSurplus}
                            isKeepYourItem={isKeepYourItem ?? false}
                            serviceFee={serviceFee}
                            originalPromotionBroken={originalPromotionBroken}
                        />
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default TrackingPage

const MainDiv = styled.div<{ bgcolor: string }>`
    flex-grow: 1;
`

const ContentDiv = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
    padding-bottom: 8rem;
`

const ActionButton = styled.a`
    display: flex;
    cursor: pointer;
    align-items: center;
    width: 100%;
    /* width: 320px; */
`

const LogoContainer = styled.img`
    /* filter: brightness(0);
    opacity: 0.5; */
`
