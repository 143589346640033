import { useTranslate } from '@/hooks'
import { useAppSelector } from '@/redux/hooks'

export const TermsAndConditions: React.FC = () => {
    const { translate } = useTranslate()

    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )

    const eCommerceName = settings.name
    const returnsPolicyUrl = settings.external_return_policy_url

    return (
        <>
            {returnsPolicyUrl ? (
                <a
                    className="mx-auto mt-4 text-center text-sm text-grey-3 underline"
                    href={returnsPolicyUrl}
                    target="_blank"
                    rel="noreferrer"
                    dangerouslySetInnerHTML={{
                        __html:
                            translate('landing_returns_policy') +
                            (eCommerceName ?? '')
                    }}
                />
            ) : null}
            <p className="my-4 text-center text-xs font-normal text-grey-3">
                {translate('landing_user_politics1') + ' '}
                <a
                    className="underline"
                    href="https://itsrever.com/user-terms/"
                    target="_blank"
                    rel="noreferrer"
                    dangerouslySetInnerHTML={{
                        __html: translate('landing_user_politics2')
                    }}
                />
                {' ' + translate('landing_user_politics3') + ' '}
                <a
                    className="underline"
                    href="https://itsrever.com/user-terms/"
                    target="_blank"
                    rel="noreferrer"
                    dangerouslySetInnerHTML={{
                        __html: translate('landing_user_politics4')
                    }}
                />
            </p>
        </>
    )
}
