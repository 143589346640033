import styled from 'styled-components'
import { useState } from 'react'
import { ProductCard, MobileVariantSelector } from '@/components/FullCatalog'
import { ShowMoreButton } from '@/components/FullCatalog'
import { CategorySelector } from '@/components/FullCatalog'
import { SwipeableDrawer } from '@mui/material'
import {
    PbshoppingProductInfo,
    ReturnsProductDetailsResponseVariantInfo
} from '@itsrever/rever-api'
import { Button, Spinner, useTheme } from '@itsrever/design-system'
import { ChevronLeftIcon } from '@itsrever/design-system'
import { useTranslation } from 'react-i18next'
import { use100vh } from 'react-div-100vh'
import {
    useStoreProducts,
    useStoreMenu,
    useStoreProductDetails,
    useCredit
} from '@/hooks'
import { CartView } from '../Components/CartView'
import {
    formatProductPrice,
    variantsHaveSamePrices
} from '@/components/FullCatalog/utils'
import { useAppSelector } from '@/redux/hooks'
import { totalNewItemsQuantity } from '@/domain/new-item'
import { CreditBar } from '../Components/CreditBar'
import { CartCreditBar } from '../Components/CartCreditBar'
import { MobileSearch } from './MobileSearch'
import EmptyCart from '@/assets/images/EmptyCart'
import { usePostHog } from '@/application/use-posthog'

interface MobileFullCatalogProps {
    returnedItemId: string
    handleClose: () => void
    handleContinue: () => void
    showCartView: boolean
    setShowCartView: (show: boolean) => void
}

export const MobileFullCatalog: React.FC<MobileFullCatalogProps> = ({
    returnedItemId,
    handleClose,
    handleContinue,
    showCartView,
    setShowCartView
}) => {
    const theme = useTheme()
    const isTransparentMode = theme.colors.primary.main === 'transparent'
    const { t, i18n } = useTranslation()
    const lang = i18n.language.toLocaleUpperCase() ?? 'EN'
    const { captureEventPosthog } = usePostHog()

    const height = use100vh()

    const [selectedItem, setSelectedItem] = useState<PbshoppingProductInfo>()
    const [searchExpanded, setSearchExpanded] = useState(false)

    const { selectedItemDetails, fetchProductDetails, loadingProductDetails } =
        useStoreProductDetails()

    const newItems = useAppSelector((store) => store.return.newItems)
    const cartQuantity = totalNewItemsQuantity(newItems)

    const { totalAvailableCredit, spentCredit } = useCredit()
    const remainingCredit = totalAvailableCredit - spentCredit

    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0)
    const [search, setSearch] = useState('')

    const { menu, isMenuLoaded, loadingMenu } = useStoreMenu()
    const { loadNextPage, isLoadingProducts, products, pageInfo } =
        useStoreProducts(menu, selectedCategoryIndex, search, isMenuLoaded)

    const handleChangeCategory = (idx: number) => {
        captureEventPosthog('FC - Change category', {
            categoryName: menu[idx]?.title,
            categoryIndex: idx
        })
        setShowCartView(false)
        setSelectedItem(undefined)
        setSelectedCategoryIndex(idx)
    }
    const [showVariantSelector, setShowVariantSelector] = useState(false)

    const onProductClick = (product: PbshoppingProductInfo) => {
        captureEventPosthog('FC - View item details', {
            product_id: product.title
        })
        setShowVariantSelector(true)
        setSelectedItem(product)
        fetchProductDetails(product.id ?? '')
    }

    const onClickShowMore = () => {
        captureEventPosthog('FC - Click show more')
        loadNextPage()
    }

    const onCloseVariantSelector = () => {
        setShowVariantSelector(false)
    }
    const onClickBackButton = () => {
        if (search !== '') {
            setSearch('')
        } else {
            if (showCartView) {
                captureEventPosthog('FC - Click close Cart')
                setShowCartView(false)
            } else {
                handleClose()
            }
        }
    }
    const hasStock = (variant: ReturnsProductDetailsResponseVariantInfo) => {
        return (
            !variant.is_inventory_enabled ||
            (variant.inventory_quantity !== undefined &&
                variant.inventory_quantity >= 1)
        )
    }
    const productPhotoList = () => {
        const productImages =
            selectedItemDetails?.product_info?.image_urls ?? []
        const variantsImages =
            selectedItemDetails?.product_info?.variants
                ?.filter((variant) => hasStock(variant))
                ?.map((variant) => variant?.image_urls?.[0] ?? '') ?? []
        return Array.from(new Set([...productImages, ...variantsImages]))
    }

    const computePrice = (product: PbshoppingProductInfo) => {
        return formatProductPrice(
            product.price_range?.min?.amount,
            product.price_range?.min?.currency,
            lang
        )
    }

    const computeCreditPrice = (product: PbshoppingProductInfo) => {
        const amountValue = product.price_range?.min?.amount ?? '0'
        const parsedAmount = parseFloat(amountValue)
        if (remainingCredit <= 0) {
            return ''
        }
        const restingAmount = remainingCredit < 0 ? 0 : remainingCredit
        const creditValue = parsedAmount - restingAmount
        const value = creditValue < 0 ? 0 : creditValue
        return formatProductPrice(
            value.toString(),
            product.price_range?.min?.currency,
            lang
        )
    }

    return (
        <Container
            className="relative flex flex-col px-3"
            style={{ height: (height ?? 500) * 0.9 + 'px' }}
        >
            {loadingMenu === 'pending' ? (
                <div className="flex h-full items-center justify-center">
                    <Spinner />
                </div>
            ) : loadingMenu === 'failed' ? (
                <>
                    <div className="my-3 flex shrink gap-1">
                        <button
                            onClick={onClickBackButton}
                            className="ml-2 mt-2 flex shrink-0 cursor-pointer items-center pr-3"
                        >
                            <ChevronLeftIcon
                                style={{ transform: 'scale(1.1)' }}
                                color={theme.colors.grey[2]}
                            />
                        </button>
                    </div>
                    <div className="flex size-full flex-col items-center justify-center gap-4">
                        <div className="opacity-75">
                            <EmptyCart
                                lightColor="#cbd5e1"
                                darkColor="#f1f5f9"
                            />
                        </div>
                        <div className="mb-20">
                            <h3 className="mt-2 text-center text-2xl font-semibold">
                                {t('full_catalog.menu_error_title')}
                            </h3>
                            <p className="mt-1 max-w-80 text-center text-base font-light text-slate-500">
                                {t('full_catalog.menu_error_description')}
                            </p>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="my-3 flex shrink gap-1">
                        <button
                            onClick={onClickBackButton}
                            className="flex shrink-0 cursor-pointer items-center pr-3"
                        >
                            <ChevronLeftIcon
                                style={{ transform: 'scale(1.1)' }}
                                color={theme.colors.grey[2]}
                            />
                        </button>
                        {showCartView ? (
                            <div className="flex h-10 items-center">
                                <h4 className="shrink-0 font-medium text-gray-800">
                                    {t('full_catalog.your_selected_items')}
                                </h4>
                            </div>
                        ) : (
                            <div className="flex grow items-center justify-between gap-2">
                                <div className="flex flex-1 grow">
                                    <MobileSearch
                                        placeholder={
                                            t('full_catalog.search') + '...'
                                        }
                                        value={search}
                                        setSearchExpanded={setSearchExpanded}
                                        onChange={setSearch}
                                    />
                                </div>
                                <div className="shrink-0">
                                    <CartCreditBar
                                        setShowCartView={setShowCartView}
                                        showCompactVersion={searchExpanded}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {showCartView ? (
                        <div className="mb-8 mt-2 px-1">
                            <CartView
                                returningItemId={returnedItemId}
                                onClose={() => setShowCartView(false)}
                            />
                        </div>
                    ) : (
                        <>
                            <div className="my-2">
                                <CategorySelector
                                    categories={menu}
                                    selected={selectedCategoryIndex}
                                    handleChange={handleChangeCategory}
                                />
                            </div>
                            {isLoadingProducts ? (
                                <div className="flex h-full items-center justify-center">
                                    <Spinner />
                                </div>
                            ) : (
                                <ProductsContainer
                                    $show={isLoadingProducts === false}
                                    className="flex flex-col"
                                >
                                    {products?.length > 0 ? (
                                        <>
                                            <ItemsDiv id="products-grid">
                                                {products?.map((product, i) => {
                                                    return (
                                                        <ProductCard
                                                            key={i}
                                                            name={
                                                                product.title ??
                                                                ''
                                                            }
                                                            id={
                                                                product.id ?? ''
                                                            }
                                                            imageUrl={
                                                                product.featured_image_url
                                                            }
                                                            description={
                                                                product.description ??
                                                                ''
                                                            }
                                                            price={computePrice(
                                                                product
                                                            )}
                                                            creditPrice={computeCreditPrice(
                                                                product
                                                            )}
                                                            onProductClick={() =>
                                                                onProductClick(
                                                                    product
                                                                )
                                                            }
                                                            isFrom={
                                                                !variantsHaveSamePrices(
                                                                    product?.price_range
                                                                )
                                                            }
                                                        />
                                                    )
                                                })}
                                            </ItemsDiv>
                                            {pageInfo?.has_next_page && (
                                                <div className="my-10 flex">
                                                    <ShowMoreButton
                                                        onClick={() =>
                                                            onClickShowMore()
                                                        }
                                                    />
                                                </div>
                                            )}
                                            <div className="mb-20">
                                                {search !== '' &&
                                                    products?.length === 0 && (
                                                        <div className="flex flex-col">
                                                            <p className="mx-auto mt-4 p-4 text-xl text-gray-400">
                                                                {t(
                                                                    'full_catalog.no_products_found'
                                                                )}
                                                            </p>
                                                        </div>
                                                    )}
                                                {!pageInfo?.has_next_page &&
                                                    products &&
                                                    products?.length > 0 && (
                                                        <p className="mx-auto mb-10 mt-5 border-b-2 border-solid border-gray-400 pb-5 text-sm text-gray-400">
                                                            {t(
                                                                'full_catalog.showing_of',
                                                                {
                                                                    number: products.length
                                                                }
                                                            )}
                                                        </p>
                                                    )}
                                            </div>
                                        </>
                                    ) : (
                                        <div className="flex w-full flex-col items-center justify-center">
                                            <div className="opacity-75">
                                                <EmptyCart
                                                    lightColor="#cbd5e1"
                                                    darkColor="#f1f5f9"
                                                />
                                            </div>
                                            <h3 className="mt-6 text-center text-2xl font-semibold">
                                                {t(
                                                    'full_catalog.empty_category_title'
                                                )}
                                            </h3>
                                            <p className="mt-1 max-w-80 text-center text-base font-light text-slate-500">
                                                {t(
                                                    'full_catalog.empty_category_description'
                                                )}
                                            </p>
                                        </div>
                                    )}
                                </ProductsContainer>
                            )}
                            <SwipeableDrawer
                                anchor={'right'}
                                open={showVariantSelector}
                                onClose={() => setShowVariantSelector(false)}
                                onOpen={() => setShowVariantSelector(true)}
                                hideBackdrop={true}
                                sx={{
                                    top: (height ?? 100) * 0.1 + 'px',
                                    height: (height ?? 100) * 0.9 + 'px'
                                }}
                                PaperProps={{
                                    style: {
                                        top: (height ?? 100) * 0.1 + 'px',
                                        height: (height ?? 100) * 0.9 + 'px',
                                        width: '100%'
                                    }
                                }}
                            >
                                <div className="relative h-full overflow-y-hidden">
                                    <MobileVariantSelector
                                        productId={selectedItem?.id ?? ''}
                                        selectedCategoryIndex={
                                            selectedCategoryIndex
                                        }
                                        returnedItemId={returnedItemId}
                                        name={selectedItem?.title ?? ''}
                                        description={
                                            selectedItem?.description ?? ''
                                        }
                                        loadingDetails={loadingProductDetails}
                                        product={selectedItemDetails ?? {}}
                                        onClose={onCloseVariantSelector}
                                        photoList={productPhotoList()}
                                        mainImage={
                                            selectedItem?.featured_image_url ??
                                            ''
                                        }
                                    />
                                </div>
                            </SwipeableDrawer>
                        </>
                    )}
                    {!showCartView && remainingCredit > 0 && (
                        <div className="absolute inset-x-0 bottom-0 flex justify-center">
                            <div className="flex flex-nowrap items-center gap-8 rounded-t-3xl border border-solid border-neutral-200 bg-white px-10 py-1 shadow-lg">
                                <CreditBar />
                            </div>
                        </div>
                    )}

                    {showCartView ? (
                        <div
                            className={
                                'absolute bottom-10 left-1/2 -translate-x-1/2' +
                                (isTransparentMode
                                    ? ' rounded-full bg-white shadow-lg'
                                    : '')
                            }
                        >
                            <Button onClick={handleContinue}>
                                <span className="mx-6 flex items-center justify-center gap-3 text-base">
                                    {t('full_catalog.confirm')}
                                    <span className="flex size-5 items-center justify-center rounded-full border border-solid border-white text-center text-xs font-bold">
                                        {cartQuantity}
                                    </span>
                                </span>
                            </Button>
                        </div>
                    ) : null}
                </>
            )}
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
`

const ItemsDiv = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
`

interface ProductsContainerProps {
    $show: boolean
}
const ProductsContainer = styled.div<ProductsContainerProps>`
    overflow-y: scroll;
    display: ${(p) => (p.$show ? 'flex' : 'none')};
`
