import {
    ReturnsApi,
    ReturnsCreateLiteOrderRequest,
    ReturnsExchangeOptionsRequest
} from '@itsrever/rever-api'
import { axiosInstance } from '@/redux/api/apiConfiguration'

const returnsApi = new ReturnsApi(undefined, undefined, axiosInstance)

export const getExchangeOptions = async (args: {
    ecommerceId: string
    returnsExchangeOptionsRequest: ReturnsExchangeOptionsRequest
}) => {
    const { ecommerceId, returnsExchangeOptionsRequest } = args
    const response = await returnsApi.exchangeOptions({
        ecommerceId,
        returnsExchangeOptionsRequest
    })
    return response.data
}

export const createLiteOrder = async (args: {
    ecommerceId: string
    returnsCreateLiteOrderRequest: ReturnsCreateLiteOrderRequest
}) => {
    const { ecommerceId, returnsCreateLiteOrderRequest } = args
    const response = await returnsApi.createLiteOrder({
        ecommerceId,
        returnsCreateLiteOrderRequest
    })
    return response
}
