import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import en from '@/locales/en/translation.json'
import es from '@/locales/es/translation.json'
import fr from '@/locales/fr/translation.json'
import de from '@/locales/de/translation.json'
import it from '@/locales/it/translation.json'
import pt from '@/locales/pt/translation.json'

const resources = {
    en: {
        translation: en
    },
    es: {
        translation: es
    },
    fr: {
        translation: fr
    },
    de: {
        translation: de
    },
    it: {
        translation: it
    },
    pt: {
        translation: pt
    }
}

function FindInitialLanguage() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const lang = urlParams.get('lang')

    if (
        lang === 'en' ||
        lang === 'es' ||
        lang === 'de' ||
        lang === 'fr' ||
        lang === 'it' ||
        lang === 'pt'
    ) {
        localStorage.setItem('userPreferredLang', lang)
        return lang
    }

    const storedLang = localStorage.getItem('userPreferredLang')
    if (storedLang) return storedLang

    const userLocale = navigator.languages
    const language = userLocale.find((lng) => {
        if (
            lng.substring(0, 2) === 'es' ||
            lng.substring(0, 2) === 'fr' ||
            lng.substring(0, 2) === 'de' ||
            lng.substring(0, 2) === 'en' ||
            lng.substring(0, 2) === 'it' ||
            lng.substring(0, 2) === 'pt'
        ) {
            return lng
        }
    })
    if (language) {
        localStorage.setItem('userPreferredLang', language.substring(0, 2))
        return language.substring(0, 2)
    }
    localStorage.setItem('userPreferredLang', 'en')
    return 'en'
}

// debug: true -> for more browser console info in .init()
i18next.use(initReactI18next).use(Backend).init({
    //.use(LanguageDetector)
    lng: FindInitialLanguage(),
    //fallbackLng: 'en',
    resources
})
