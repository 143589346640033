import { StripeElementLocale, loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { StripeElementsOptions } from '@stripe/stripe-js'
import { useAppSelector } from '@/redux/hooks'
import { useMemo } from 'react'
import { StripeCheckoutForm } from '../StripeCheckoutForm'
import { Loading } from '@/components/Loading'
import { useTranslation } from 'react-i18next'

const Checkout = () => {
    const { i18n } = useTranslation()
    const processApiCall = useAppSelector(
        (store) => store.returnsApi.process.response
    )
    const clientSecret = processApiCall.payment_session_id
    const stripeKey = processApiCall.payment_frontend_api_key

    const stripePromise = useMemo(() => {
        if (!stripeKey) return null
        const locale = i18n.language as StripeElementLocale
        return loadStripe(stripeKey, { locale })
    }, [stripeKey, i18n.language])

    const fontSource = window.location.origin + '/stripe.css'

    const options: StripeElementsOptions = useMemo(() => {
        return {
            clientSecret,
            fonts: [
                {
                    family: 'Roobert',
                    cssSrc: fontSource
                }
            ],
            appearance: {
                theme: 'flat',
                variables: {
                    fontFamily: 'Roobert, sans-serif',
                    borderRadius: '10px',
                    colorBackground: '#F6F8FA',
                    colorPrimaryText: '#262626'
                },
                rules: {
                    '.Block': {
                        backgroundColor: 'var(--colorBackground)',
                        boxShadow: 'none',
                        padding: '12px'
                    },
                    '.Input': {
                        padding: '12px'
                    },
                    '.Input:disabled, .Input--invalid:disabled': {
                        color: 'lightgray'
                    },
                    '.Tab': {
                        padding: '10px 12px 8px 12px',
                        border: 'none'
                    },
                    '.Tab:hover': {
                        border: 'none',
                        boxShadow:
                            '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
                    },
                    '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover':
                        {
                            border: 'none',
                            backgroundColor: '#fff',
                            boxShadow:
                                '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
                        },
                    '.Label': {
                        fontWeight: '500'
                    }
                }
            }
        }
    }, [clientSecret, fontSource])

    return (
        <>
            {clientSecret ? (
                <Elements stripe={stripePromise} options={options}>
                    <StripeCheckoutForm />
                </Elements>
            ) : (
                <div className="flex justify-center">
                    <Loading loading={true} />
                </div>
            )}
        </>
    )
}

export default Checkout
