import chroma from 'chroma-js'

function mapValue(
    value: number,
    oldMin: number,
    oldMax: number,
    newMin: number,
    newMax: number
): number {
    const normalizedValue = (value - oldMin) / (oldMax - oldMin)
    const newValue = normalizedValue * (newMax - newMin) + newMin

    return newValue
}

function findClosestNumber(initialNumber: number, array: number[]): number {
    let closest = array[0]
    for (const num of array) {
        if (Math.abs(num - initialNumber) < Math.abs(closest - initialNumber)) {
            closest = num
        }
    }

    return closest
}

const shadeList = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950]

export const computeColors = (colorInput: string) => {
    colorInput = colorInput.trim()
    let color = ''
    if (colorInput === 'transparent') {
        color = '#000'
    } else {
        color = colorInput
    }
    const colorH = chroma(color).get('hsl.h')
    const colorS = chroma(color).get('hsl.s')
    const colorL = chroma(color).get('hsl.l')

    const lightInvert = 1 - colorL
    const mappedLight = mapValue(lightInvert, 0, 1, 0, 1000)
    const shadeNumber = findClosestNumber(mappedLight, shadeList)
    const shadeIndex = shadeList.indexOf(shadeNumber)
    const hueStart = shadeIndex * 2
    const hueEnd = (11 - shadeIndex) * 2

    const initialHue = colorH - hueStart
    let finalHue = 0
    if (initialHue < 50) {
        finalHue = (colorH - hueEnd) % 360
    } else {
        finalHue = (colorH + hueEnd) % 360
    }

    const hueArray = shadeList.map((shade) => {
        return mapValue(shade, 0, 1000, initialHue, finalHue)
    })
    const lightArray = shadeList
        .sort((a, b) => b - a)
        .map((shade) => {
            return mapValue(shade, 0, 1000, 0.05, 1)
        })

    let shades = shadeList.map((shade, i) => {
        return chroma(hueArray[i], colorS, lightArray[i], 'hsl').hex()
    })
    shades = shades.reverse()

    shadeList.forEach((shade, i) => {
        document.documentElement.style.setProperty(
            '--brand-' + shade,
            shades[i]
        )
    })
}
