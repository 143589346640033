import { GithubComItsreverDomainModelsPkgModelsAddress as ModelsAddress } from '@itsrever/returns-api-types'
import { ModelsMoneyFormat as MoneyFormat } from '@itsrever/rever-api'
import { ReturnMethod } from '@/redux/return/returnSlice'
import {
    postcodeValidator,
    postcodeValidatorExistsForCountry
} from 'postcode-validator'

export * from './device'

export function formatCurrency(amount: string, currency: string) {
    return Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        maximumFractionDigits: 2
    }).format(Number(amount))
}

export function toRGB(hex: string) {
    const bigint = parseInt(hex.replace('#', ''), 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255

    return `rgb(${r}, ${g}, ${b}, 0.1)`
}

export const isNotNetworkError = (error: any): boolean => {
    const networkErrors = ['Network Error', 'Load failed', 'Request aborted']
    if (error.message) {
        if (networkErrors.some((err) => error.message.includes(err))) {
            return false
        }
    }
    return true
}

export const disabledForm = (
    address: ModelsAddress,
    provinces: number,
    nameNeeded?: boolean,
    pickupRanges?: number[],
    returnMethod?: number,
    pickupRangesNeeded?: boolean
) => {
    if (isValidAddress(address, provinces)) {
        if (nameNeeded) {
            if (address.first_name && address.last_name) {
                if (
                    address.first_name.length > 0 &&
                    address.last_name.length > 0
                ) {
                    return false
                } else return true
            } else return true
        } else {
            if (
                returnMethod === ReturnMethod.HomePickup &&
                pickupRangesNeeded
            ) {
                if (pickupRanges && pickupRanges.length > 0) {
                    return false
                } else return true
            } else return false
        }
    } else return true
}

export const isValidAddress = (
    address: ModelsAddress,
    provinces: number
): boolean => {
    if (
        address.address_line_1 &&
        address.postcode &&
        address.city &&
        address.country &&
        address.phone &&
        address.country_code
    ) {
        if (
            address.address_line_1.length &&
            address.postcode.length &&
            address.city.length &&
            address.country.length &&
            address.phone.length &&
            address.country_code.length
        ) {
            if (
                isValidAddressLine(address.address_line_1) &&
                isValidAddressLine2(address.address_line_2 ?? '') &&
                isValidCity(address.city) &&
                isValidPhone(address.phone) &&
                isValidPostcode(address.postcode, address.country_code)
            ) {
                if (provinces > 0) {
                    if (
                        address.state_province &&
                        address.state_province.length > 0
                    ) {
                        return true
                    } else return false
                } else return true
            } else return false
        } else return false
    } else return false
}

export const isValidName = (name: string): boolean => {
    if (name.length > 35) return false
    return true
}

export const isValidPhone = (checkphone: string): boolean => {
    const re = /^[+]?[0-9 || ) || ( || -]{1,20}$/i
    return re.test(checkphone)
}

export const isValidAddressLine2 = (addressline: string): boolean => {
    if (addressline.length > 24) return false
    return true
}

export const isValidAddressLine = (addressline: string): boolean => {
    if (addressline.length > 35) return false
    return true
}

export const isValidPostcode = (
    postcode: string,
    countryCode: string
): boolean => {
    if (!postcodeValidatorExistsForCountry(countryCode)) return true
    return postcodeValidator(postcode, countryCode)
}

export const isValidCity = (city: string): boolean => {
    if (city.length > 30) return false
    return true
}

export function deepCopy<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj))
}

export const isValidEmail = (email: string): boolean => {
    const re =
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
    return re.test(String(email).toLowerCase())
}

export const getDate = (
    utcDate: string,
    lng: string,
    month?: Intl.DateTimeFormatOptions['month']
): string => {
    if (!utcDate) return ''
    const date = new Date(utcDate)
    return date.toLocaleDateString(lng, {
        year: 'numeric',
        month: month || 'long',
        day: 'numeric'
    })
}

export const formatPrice = (
    price: number,
    moneyFormat: MoneyFormat,
    multiply = true
): string => {
    let ints = ''
    let decimals = ''
    let indexSeparator = undefined
    if (multiply && moneyFormat.amount_multiplied_by) {
        const realPrice = price / moneyFormat.amount_multiplied_by
        if (`${realPrice}`.includes('.')) {
            indexSeparator = `${realPrice}`.indexOf('.')
        }
        if (indexSeparator) {
            ints = `${realPrice}`.substring(0, indexSeparator)
            decimals = `${realPrice}`.substring(
                indexSeparator + 1,
                `${realPrice}`.length
            )
        } else {
            ints = `${realPrice}`
        }
    } else {
        ints = `${Math.trunc(price)}`
        decimals = `${price % 1}`
    }
    let addSign = false
    if (ints[0] === '-') {
        addSign = true
        ints = ints.substring(1, ints.length)
    }
    ints = ints.split('').reverse().join('')
    ints =
        ints.match(/.{1,3}/g)?.join(moneyFormat.thousand_separator ?? '') ?? ''
    ints = ints.split('').reverse().join('')
    if (addSign) ints = '-' + ints
    if (moneyFormat.visible_number_of_decimals) {
        if (decimals.length > moneyFormat.visible_number_of_decimals) {
            decimals = decimals.substring(
                0,
                moneyFormat.visible_number_of_decimals
            )
        } else if (decimals.length < moneyFormat.visible_number_of_decimals) {
            decimals = decimals.padEnd(
                moneyFormat.visible_number_of_decimals,
                '0'
            )
        }
    }
    if (moneyFormat.decimal_separator) {
        const value = ints + moneyFormat.decimal_separator + decimals
        if (moneyFormat.is_currency_left_position) {
            return `${moneyFormat.currency_symbol} ${value}`
        }
        return `${value} ${moneyFormat.currency_symbol}`
    }
    if (moneyFormat.is_currency_left_position) {
        return `${moneyFormat.currency_symbol}${price}`
    }
    return `${price}${moneyFormat.currency_symbol}`
}

export const formatStringPrice = (
    amount: string | undefined,
    currency: string | undefined,
    lang: string | undefined
) => {
    const currencyCode = currency || 'EUR'
    const amountValue = amount || '0'
    return new Intl.NumberFormat(lang ?? 'EN', {
        style: 'currency',
        currency: currencyCode
    }).format(parseFloat(amountValue))
}

export const formatErrorMessage = (error: any): string => {
    if (error.message) {
        const body = JSON.parse(
            JSON.parse(error.message.split('Body:')[1].split('Headers')[0])
        )
        let message = ''
        if (body.error.code) {
            message += `${body.error.code} - `
        }
        if (body.error.description) {
            message += `${body.error.description}`
        }
        return message
    }
    return JSON.stringify(error)
}
