import { RepeatIcon } from '@itsrever/design-system'
import { CartItem } from './CartItem'
import { useAppSelector } from '@/redux/hooks'
import { NewItem } from '@/domain/new-item'
import { CartEmptyState } from './CartEmptyState'

interface CartViewProps {
    returningItemId: string
    onClose?: () => void
}

export const CartView: React.FC<CartViewProps> = ({
    returningItemId,
    onClose
}) => {
    const cartItems = useAppSelector((store) => store.return.newItems)
    const orderItems = useAppSelector((store) => store.order.orderProducts)

    const groupedCartItems = cartItems.reduce((acc, item) => {
        if (!acc[item.returnedItemId]) {
            acc[item.returnedItemId] = []
        }
        acc[item.returnedItemId].push(item)
        return acc
    }, {} as Record<string, NewItem[]>)

    const sortedGroupedCartItems = Object.entries(groupedCartItems).sort(
        ([a], [b]) => {
            const indexA = orderItems.findIndex(
                (orderItem) => orderItem.lineItemId === a
            )
            const indexB = orderItems.findIndex(
                (orderItem) => orderItem.lineItemId === b
            )
            return indexA - indexB
        }
    )

    return (
        <div className="flex flex-col gap-4">
            {sortedGroupedCartItems.length ? (
                sortedGroupedCartItems.map(([returnedItemId, items]) => {
                    const returnedItemName =
                        orderItems.find(
                            (orderItem) =>
                                orderItem.lineItemId === returnedItemId
                        )?.product.name || 'Unknown Item'

                    return (
                        <div key={returnedItemId} className="mb-6">
                            <p className="mb-3 flex items-center gap-2 text-base text-neutral-500">
                                <RepeatIcon />
                                {returnedItemName}
                            </p>
                            <div className="flex flex-col gap-3">
                                {items.map((item, index) => (
                                    <CartItem
                                        key={index}
                                        item={item}
                                        index={index}
                                        returningItemId={returningItemId}
                                    />
                                ))}
                            </div>
                        </div>
                    )
                })
            ) : (
                <div className="flex w-full justify-center">
                    <div className="mt-10">
                        <CartEmptyState onClose={onClose} />
                    </div>
                </div>
            )}
        </div>
    )
}
