import { Loading, axiosInstance } from './apiConfiguration'
import {
    PkgInfrastructureRestGetReturnMethodsRequest as RestGetReturnMethodsRequest,
    ReturnMethodsApi,
    PkgInfrastructureRestGetReturnMethodsResponse as RestGetReturnMethodsResponse
} from '@itsrever/returns-api-types'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const returnMethodsApi = new ReturnMethodsApi(
    undefined,
    undefined,
    axiosInstance
)

interface ReturnMethodsCall {
    response: RestGetReturnMethodsResponse
    loading: Loading
    error: unknown | null
}

interface State {
    returnMethods: ReturnMethodsCall
}

const initialState: State = {
    returnMethods: {
        response: {},
        loading: 'idle',
        error: null
    }
}

export const getReturnMethods = createAsyncThunk(
    'returnMethodsApi/getReturnMethods',
    async (
        args: {
            ecommerceID: string
            request: RestGetReturnMethodsRequest
        },
        { rejectWithValue }
    ) => {
        const { ecommerceID, request } = args
        try {
            const returnMethodsResponse = await returnMethodsApi.returnMethods({
                ecommerceID,
                getReturnMethodsRequest: request
            })
            return returnMethodsResponse.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const returnMethodsApiSlice = createSlice({
    name: 'returnMethodsApi',
    initialState,
    reducers: {
        resetReturnMethods: (state) => {
            state.returnMethods = {
                ...initialState.returnMethods,
                response: state.returnMethods.response
            }
        },
        cleanReturnMethods: (state) => {
            state.returnMethods = initialState.returnMethods
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getReturnMethods.pending, (state) => {
            state.returnMethods.loading = 'pending'
            state.returnMethods.response = {}
            state.returnMethods.error = null
        })
        builder.addCase(getReturnMethods.fulfilled, (state, action) => {
            state.returnMethods.loading = 'succeeded'
            if (action.payload) state.returnMethods.response = action.payload
        })
        builder.addCase(getReturnMethods.rejected, (state, action) => {
            state.returnMethods.loading = 'failed'
            state.returnMethods.error = action.payload
        })
    }
})

export const { resetReturnMethods, cleanReturnMethods } =
    returnMethodsApiSlice.actions
export default returnMethodsApiSlice.reducer
