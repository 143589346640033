import styled from 'styled-components'
import { device } from '@/utils'

interface ProgressBarProps {
    color: string
}

const ProgressBarPending: React.FC<ProgressBarProps> = ({ color }) => {
    return (
        <SVGDiv>
            <svg
                width="100%"
                height="100%"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 280 16"
            >
                <line
                    x1="104"
                    y1="7.5"
                    x2="174"
                    y2="7.49999"
                    stroke="rgb(255, 255, 255)"
                />
                <line
                    x1="178"
                    y1="7.5"
                    x2="246"
                    y2="7.50001"
                    stroke="rgb(255, 255, 255)"
                />
                <line
                    x1="31"
                    y1="7.5"
                    x2="104"
                    y2="7.50001"
                    stroke="rgb(255,255,255)"
                />
                <circle cx="176" cy="8" r="3" fill="white" />
                <circle cx="248" cy="8" r="3" fill="white" />
                <circle cx="104" cy="8" r="3" fill="white" />
                <circle cx="32" cy="8" r="3" fill="white" />
            </svg>
        </SVGDiv>
    )
}

const SVGDiv = styled.svg`
    // Over lg
    width: 280px;
    height: 24px;
    @media ${device.lg} {
        width: 400px;
        height: 36px;
    }
`

export default ProgressBarPending
