import styled from 'styled-components'
import LanguageSwitcher from '@/components/LanguageSwitcher'
import { LandingForm, TermsAndConditions } from '@/components/LandingComponents'
import { lessThan, moreThan } from '@/utils'
import { useAppSelector } from '@/redux/hooks'
import { useTranslate } from '@/hooks'
import { PoweredByRever } from '@itsrever/design-system'

export const VerticalLanding: React.FC = () => {
    const { translate } = useTranslate()

    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )
    const backgroundUrl = settings.theme?.background_image_url
    const logoHeight = settings.theme?.logo_height
    const logoUrl = settings.theme?.logo_url

    return (
        <VerticalDiv data-testid="verticalForm">
            <VerticalBanner $bgImage={`url(${backgroundUrl})`} />
            <RightSection $bgImage={`url(${backgroundUrl})`}>
                <LanguageSwitcher />
                <div className="mb-auto" />
                <FormContainer>
                    {logoUrl ? (
                        <ImageContainer
                            mobileHeight={logoHeight?.mobile ?? ''}
                            desktopHeight={logoHeight?.desktop ?? ''}
                        >
                            <img
                                className="h-min max-h-full max-w-full"
                                src={logoUrl}
                            />
                        </ImageContainer>
                    ) : null}
                    <h4
                        className="text-center"
                        dangerouslySetInnerHTML={{
                            __html: translate('landing_title_layout2')
                        }}
                    />
                    <p
                        className="mb-6 mt-2 text-center md:mb-8"
                        dangerouslySetInnerHTML={{
                            __html: translate('landing_subtitle')
                        }}
                    />
                    <div className="max-w-[335px] md:max-w-[280px]">
                        <LandingForm />
                        <div className="flex flex-col items-center">
                            <TermsAndConditions />
                            <div className="md:hidden">
                                <PoweredByRever />
                            </div>
                        </div>
                    </div>
                </FormContainer>
                <Footer className="mt-auto">
                    <PoweredByRever />
                </Footer>
            </RightSection>
        </VerticalDiv>
    )
}

interface ImageContainerProps {
    mobileHeight: string
    desktopHeight: string
}

const ImageContainer = styled.div<ImageContainerProps>`
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    height: ${(p) => (p.mobileHeight ? p.mobileHeight : '1.5rem')};
    width: 100%;
    @media ${moreThan.md} {
        height: ${(p) => (p.desktopHeight ? p.desktopHeight : '2rem')};
    }
`

const Footer = styled.div`
    @media ${lessThan.md} {
        opacity: 0;
    }
`

const VerticalDiv = styled.div`
    width: 100%;
    height: 100%;
    @media ${moreThan.md} {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`

const RightSection = styled.div<{ $bgImage: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 1rem;
    @media ${lessThan.md} {
        background-image: ${(p) => p.$bgImage};
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
`

const VerticalBanner = styled.div<{ $bgImage: string }>`
    @media ${lessThan.md} {
        display: none;
    }
    @media ${moreThan.md} {
        background-color: lightgrey;
        background-image: ${(p) => p.$bgImage};
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
`

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media ${lessThan.md} {
        background-color: #fff;
        padding: 1.5rem;
        border-radius: 0.5rem;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    }
`
