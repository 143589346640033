import styled from 'styled-components'
import { GithubComItsreverReturnsApiPkgDomainSummaryExchangeItem as SummaryExchangeItem } from '@itsrever/returns-api-types'
import { formatPrice } from '@/utils'
import { useAppSelector } from '@/redux/hooks'
import { ReturnsCalculatedSummaryExchangeItem } from '@itsrever/rever-api'

interface ExchangedItemProps {
    exchangedItem: SummaryExchangeItem | ReturnsCalculatedSummaryExchangeItem
}

export const ExchangedItem: React.FC<ExchangedItemProps> = ({
    exchangedItem
}) => {
    const orderMoneyFormat =
        useAppSelector(
            (store) => store.returnsApi.order.response?.order?.money_format
        ) ?? {}

    return (
        <MainDiv>
            <div className="flex items-center gap-1">
                <p className="text-xs">{exchangedItem.quantity}&times;</p>
                <p className="text-xs">
                    {exchangedItem.name + ' - ' + exchangedItem.variant_name}
                </p>
            </div>
            <p className="whitespace-nowrap">
                {formatPrice(
                    Number(exchangedItem.total ?? 0) ?? 0,
                    orderMoneyFormat
                )}
            </p>
        </MainDiv>
    )
}

export default ExchangedItem

const MainDiv = styled.div`
    display: flex;
    margin-bottom: 0.5rem;
    align-items: center;
    justify-content: space-between;
    gap: 0.4rem;
`
