import styled from 'styled-components'
import { device } from '@/utils'

interface ReverCardProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode
}

const ReverCard: React.FC<ReverCardProps> = ({ children, ...props }) => {
    return <Card {...props}>{children}</Card>
}

export default ReverCard

const Card = styled.div`
    width: 83%;
    max-width: 30rem;
    background: #ffffff;
    margin-top: 1.5rem;
    @media ${device.lg} {
        margin-bottom: 1rem;
        padding: 1.5rem 2rem 1.5rem 2rem;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        margin-top: 2.5rem;
    }
`
