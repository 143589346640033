import { useCredit } from '@/hooks'
import { useAppSelector } from '@/redux/hooks'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import { useRef } from 'react'

export const CreditBar = () => {
    const { t, i18n } = useTranslation()
    const userPreferredLang = i18n.language
    const { totalAvailableCredit, spentCredit } = useCredit()
    const remainingCredit = totalAvailableCredit - spentCredit
    const barContainer = useRef<HTMLDivElement>(null)
    const barWidth = barContainer.current?.clientWidth ?? 200
    const creditPercentage = (spentCredit / totalAvailableCredit) * barWidth
    const currency = useAppSelector(
        (store) => store.returnsApi.order.response.order?.money_format?.currency
    )
    const remainingCreditFormatted = new Intl.NumberFormat(
        userPreferredLang ?? 'EN',
        {
            style: 'currency',
            currency,
            minimumFractionDigits: remainingCredit % 1 === 0 ? 0 : 2,
            maximumFractionDigits: 2
        }
    ).format(remainingCredit)

    return (
        <>
            {remainingCredit <= 0 ? null : (
                <div
                    ref={barContainer}
                    className="flex w-full flex-col items-center justify-center gap-2"
                >
                    <div className="flex items-center gap-4">
                        <p className="flex shrink-0 items-center gap-2 text-base font-semibold text-brand-600">
                            <span className="w-fit rounded-full bg-radial-gradient from-brand-600 to-brand-500 px-2 text-white">
                                {remainingCreditFormatted}
                            </span>
                            <span className="text-sm font-light text-neutral-500">
                                {t('full_catalog.to_spend_from_your_return')}
                            </span>
                        </p>
                    </div>
                    <div
                        className="relative h-2 shrink-0 rounded-full bg-brand-200"
                        style={{ width: barWidth + 'px' }}
                    >
                        <motion.div
                            initial={{ width: 0 }}
                            animate={{ width: creditPercentage + 12 + 'px' }}
                            transition={{ duration: 1 }}
                            className="absolute left-0 h-2 shrink-0 rounded-full bg-brand-500 bg-radial-gradient from-brand-600 to-brand-500"
                        />
                    </div>
                </div>
            )}
        </>
    )
}
