import { useAppSelector } from '@/redux/hooks'
import domains from '@/domains'
import { useParams } from 'react-router-dom'

export function useSlug() {
    const { slug } = useParams()
    const settingsSlug = useAppSelector(
        (store) => store.settingsApi.settings.response.slug
    )

    if (slug) return slug
    if (settingsSlug) return settingsSlug

    const isCustomDomain = domains[window.location.hostname] !== undefined
    if (isCustomDomain) return domains[window.location.hostname]
}
