import { ClipLoader } from 'react-spinners'
import { useTheme, Modal } from '@itsrever/design-system'

export interface LoadingProps {
    loading: boolean
}

export const LoadingModal: React.FC<LoadingProps> = ({ loading }) => {
    const theme = useTheme()

    const spinnerColor =
        theme?.colors?.primary.main === 'transparent'
            ? theme.colors.primary.contrastText
            : theme?.colors?.primary.main
    return (
        <>
            {loading && (
                <Modal
                    isOpen={loading}
                    onRequestClose={() => null}
                    showBorder={false}
                >
                    <div className="m-4">
                        <ClipLoader
                            color={spinnerColor}
                            loading={loading}
                            size="100px"
                        />
                    </div>
                </Modal>
            )}
        </>
    )
}

export default LoadingModal
