import { useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'

interface Props {
    children: React.ReactElement
}

const ScrollToTop: React.FC<Props> = ({ children }) => {
    const { pathname } = useLocation()
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])
    return children
}

export default ScrollToTop
