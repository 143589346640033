import { Button, useTheme, toast } from '@itsrever/design-system'
import { useAppSelector } from '@/redux/hooks'
import { GithubComItsreverDomainModelsPkgModelsAddress as ModelsAddress } from '@itsrever/returns-api-types'
import styled from 'styled-components'
import { device } from '@/utils'
import AddressForm from '@/components/AddressForm'
import { disabledForm } from '@/utils'
import TopBar from '@/components/TopBarComponent/TopBar'
import { setExchangeAddress } from '@/redux/return/returnSlice'
import { useCalculate, useTranslate, usePages, useProvinces } from '@/hooks'
import { useAppDispatch } from '@/redux/hooks'
import { ReverCard } from '@/components/ReverCard'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LoadingModal } from '@/components/Loading'

function ExchangeAddress() {
    const { pages } = usePages()
    const { t } = useTranslation()
    const { translate } = useTranslate()
    const navigate = useNavigate()
    const theme = useTheme()
    const dispatch = useAppDispatch()

    const { callCalculate, loadingCalculate } = useCalculate()
    const address = useAppSelector((store) => store.return.exchangeAddress)
    const returnMethod = useAppSelector((store) => store.return.returnMethod)
    const setAddress = (address: ModelsAddress) => {
        dispatch(setExchangeAddress(address))
    }

    const { provinces } = useProvinces(
        address.country_code,
        address.state_province_code
    )

    const order = useAppSelector(
        (store) => store.returnsApi.order?.response?.order
    )

    useEffect(() => {
        if (!order || !Object.keys(order)) {
            toast({
                text: t('toast_errors.lost_order'),
                variant: 'error'
            })
            navigate(pages.Landing)
        }
    }, [])

    return (
        <>
            {loadingCalculate ? <LoadingModal loading /> : null}
            <TopBar
                page="exchange-address"
                title={translate('exchange_address_exchange_info')}
            />
            <MainDiv color={theme.colors.grey[5]}>
                <ReverCard>
                    <Title
                        dangerouslySetInnerHTML={{
                            __html: translate(
                                'exchange_address_exchange_explanation'
                            )
                        }}
                    />
                    <AddressForm
                        addressObject={address}
                        nameNeeded={true}
                        setAddressObject={setAddress}
                    />
                    <div className="mt-6 flex w-full justify-center">
                        <div className="w-full md:max-w-[280px]">
                            <Button
                                onClick={() =>
                                    callCalculate(returnMethod, address)
                                }
                                id="exchangeAddressContinue"
                                fullWidth
                                disabled={disabledForm(
                                    address,
                                    provinces ? provinces.length : 0,
                                    undefined
                                )}
                            >
                                {translate('exchange_address_button_continue')}
                            </Button>
                        </div>
                    </div>
                </ReverCard>
            </MainDiv>
        </>
    )
}
export default ExchangeAddress

const Title = styled.div`
    font-family: 'Roobert';
    margin-bottom: 2rem;
    text-align: center;
`
interface MainProps {
    color?: string
}

const MainDiv = styled.div<MainProps>`
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    flex-grow: 1;
    @media ${device.lg} {
        background-color: ${(p) => p.color};
    }
`
