import { ClipLoader } from 'react-spinners'
import { Modal } from '@itsrever/design-system'

export interface LoadingProps {
    loading: boolean
}

export const LoadingModal: React.FC<LoadingProps> = ({ loading }) => {
    return (
        <>
            {loading ? (
                <Modal
                    isOpen={loading}
                    onRequestClose={() => null}
                    showBorder={false}
                >
                    <div className="m-4">
                        <ClipLoader loading={loading} size="100px" />
                    </div>
                </Modal>
            ) : null}
        </>
    )
}

export default LoadingModal
