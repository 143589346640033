import { animate } from 'framer-motion'

export async function animateCartItemAddition() {
    const variant = document.querySelector(
        `#main-image`
    ) as HTMLDivElement | null
    const cartButton = document.querySelector(
        '#cart-button'
    ) as HTMLDivElement | null

    if (variant && cartButton) {
        const variantRect = variant.getBoundingClientRect()
        const cartButtonRect = cartButton.getBoundingClientRect()

        const floatingVariant = variant.cloneNode(true) as HTMLElement
        floatingVariant.style.position = 'absolute'
        floatingVariant.style.top = `${variantRect.top}px`
        floatingVariant.style.left = `${variantRect.left}px`
        floatingVariant.style.width = `${variantRect.width}px`
        floatingVariant.style.height = `${variantRect.height}px`
        floatingVariant.style.zIndex = '9999'
        document.body.appendChild(floatingVariant)

        animate(
            floatingVariant,
            {
                x: cartButtonRect.left - variantRect.left,
                y: cartButtonRect.top - variantRect.top,
                width: cartButtonRect.width,
                height: cartButtonRect.height,
                scale: 0.5,
                opacity: 0.5
            },
            {
                duration: 0.3,
                delay: 0.1,
                ease: 'easeInOut',
                onComplete: () => {
                    floatingVariant.remove()
                    animate(
                        cartButton,
                        {
                            scaleX: [1, 1.4, 1],
                            scaleY: [1, 1.1, 1],
                            backgroundColor: [
                                'white',
                                'var(--brand-100)',
                                'white'
                            ]
                        },
                        {
                            duration: 0.5,
                            ease: 'easeInOut'
                        }
                    )
                }
            }
        )
    }
    setTimeout(() => {
        return
    }, 200)
}
