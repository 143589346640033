import { PbshoppingProductInfoPriceRange } from '@itsrever/rever-api'

export const formatProductPrice = (
    amount: string | undefined,
    currency: string | undefined,
    lang: string
) => {
    const currencyCode = currency || 'EUR'
    const amountValue = amount || '0'
    const parsedAmount = parseFloat(amountValue)
    const options: Intl.NumberFormatOptions = {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: parsedAmount % 1 === 0 ? 0 : 2,
        maximumFractionDigits: 2
    }
    return new Intl.NumberFormat(lang, options).format(parsedAmount)
}

export const variantsHaveSamePrices = (
    range: PbshoppingProductInfoPriceRange | undefined
) => {
    return (
        parseFloat(range?.min?.amount || '0') ===
        parseFloat(range?.max?.amount || '0')
    )
}

export function getGridPosition(
    itemId: string
): { row: number; col: number } | null {
    const gridContainer = document.querySelector('#products-grid')
    if (!gridContainer) return null

    const gridItems = Array.from(gridContainer.children) as HTMLElement[]
    const columnCount =
        getComputedStyle(gridContainer).gridTemplateColumns.split(' ').length
    const itemIndex = gridItems.findIndex(
        (item) => item.id === 'product-' + itemId
    )
    if (itemIndex === -1) return null

    const row = Math.floor(itemIndex / columnCount) + 1
    const col = (itemIndex % columnCount) + 1

    return { row, col }
}
