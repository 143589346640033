import { Outlet } from 'react-router-dom'
import { useTrackingInfo } from '@/hooks'
import { Loading } from '@/components/Loading'
import { createContext } from 'react'
import { ReturnsTrackingInfoResponse } from '@itsrever/rever-api'
import { computeColors } from '@/utils/colorPaletteGenerator'
import { useTheme } from '@itsrever/design-system'

export const TrackingContext = createContext({} as ReturnsTrackingInfoResponse)

export function TrackingLayout() {
    const { trackingInfo, loadingTrackingInfo } = useTrackingInfo()
    const theme = useTheme()
    computeColors(theme.colors.primary.main)

    if (loadingTrackingInfo) return <Loading loading />
    return (
        <TrackingContext.Provider value={trackingInfo ?? {}}>
            <Outlet />
        </TrackingContext.Provider>
    )
}
