import { ClipLoader } from 'react-spinners'
import { useTheme } from '@itsrever/design-system'

export interface LoadingProps {
    loading: boolean
    size?: string
}

export const Loading: React.FC<LoadingProps> = ({ loading, size }) => {
    const theme = useTheme()

    const spinnerColor = theme?.colors?.primary.main
    return (
        <div className="flex h-full w-full items-center justify-center p-4">
            <ClipLoader
                color={spinnerColor}
                loading={loading}
                size={size || '125px'}
            />
        </div>
    )
}

export default Loading
