import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { deepCopy } from '@/utils'
import { ReturnsLineItem as ModelsLineItem } from '@itsrever/rever-api'

export interface Subproduct {
    product: ModelsLineItem
    lineItemId: string | undefined
    subProductId: number
}

// Initial State:
interface State {
    orderNumber?: string
    email?: string
    orderProducts: Subproduct[]
    selectedProcessID: string | null
}
const initialState: State = {
    orderNumber: '',
    email: '',
    orderProducts: [],
    selectedProcessID: null
}

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setSelectedProcessID: (state, action) => {
            state.selectedProcessID = action.payload
        },
        setOrderNumber: (state, action) => {
            state.orderNumber = action.payload
        },
        setEmail: (state, action) => {
            state.email = action.payload.toLowerCase()
        },
        setOrderProducts: (
            state,
            action: PayloadAction<ModelsLineItem[] | undefined>
        ) => {
            if (!action.payload) return
            state.orderProducts = []
            action.payload.forEach((lineItem) => {
                if (!lineItem || !lineItem.quantity) return

                const lItem = deepCopy(lineItem)
                let i = 0
                while (lItem.quantity && i < lItem.quantity) {
                    // Check if there are non returnable items
                    if (
                        lItem.non_returnable_items &&
                        lItem.non_returnable_items.length > 0
                    ) {
                        // Group non returnable items by reason
                        const nonRetObject = deepCopy(
                            lItem.non_returnable_items[0]
                        )
                        const nonRetObjectQuantity = nonRetObject.quantity ?? 1
                        const uniqueProduct: ModelsLineItem = {
                            ...lineItem,
                            quantity: nonRetObjectQuantity,
                            total:
                                lItem.total &&
                                Math.round(
                                    (lItem.total / lItem.quantity) *
                                        nonRetObjectQuantity
                                ),
                            non_returnable_items: [nonRetObject]
                        }
                        const prodWithSubproductId: Subproduct = {
                            product: uniqueProduct,
                            lineItemId: lineItem.id,
                            subProductId: i
                        }
                        state.orderProducts.push(prodWithSubproductId)

                        // Add to i the number of items taken
                        i += nonRetObjectQuantity
                        // Eliminate first position of non returnable array
                        lItem.non_returnable_items.shift()
                    } else {
                        // Group returnable items
                        const totalRetObjs = lItem.quantity - i
                        const uniqueProduct: ModelsLineItem = {
                            ...lineItem,
                            quantity: totalRetObjs,
                            total:
                                lItem.total &&
                                Math.round(
                                    (lItem.total / lItem.quantity) *
                                        totalRetObjs
                                ),
                            non_returnable_items: []
                        }
                        const prodWithSubproductId: Subproduct = {
                            product: uniqueProduct,
                            lineItemId: lineItem.id,
                            subProductId: i
                        }
                        state.orderProducts.unshift(prodWithSubproductId)

                        // Add to i the number of items taken
                        i += totalRetObjs
                    }
                }
            })
        }
    }
})

export const {
    setOrderNumber,
    setEmail,
    setOrderProducts,
    setSelectedProcessID
} = orderSlice.actions
export default orderSlice.reducer
