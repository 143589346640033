import { Button, toast } from '@itsrever/design-system'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '@/redux/hooks'
import {
    ReturnMethod,
    FraudScore,
    FraudScores,
    FraudGroup,
    FraudGroups,
    RefundPaymentMethods,
    RefundPaymentMethod,
    RefundActions
} from '@/redux/return/returnSlice'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { device } from '@/utils'
import RefundCard from '@/components/RefundCard/RefundCard'
import RefundCardKYI from '@/components/RefundCard/RefundCardKYI'
import TopBar from '@/components/TopBarComponent/TopBar'
import { ReverCard } from '@/components/ReverCard'
import FallbackNotFound from '@/components/FallbackNotFound'
import { useCalculate, useTranslate, usePages, useReturnMethods } from '@/hooks'
import { useEffect } from 'react'
import { LoadingModal } from '@/components/Loading'
import {
    ModelsRefundPaymentMethod,
    PkgInfrastructureRestRefundMethod as RestRefundMethod
} from '@itsrever/returns-api-types'
import ExchangeKYICards from '@/components/ExchangeKYICards'
import { usePostHog } from '@/application/use-posthog'

export default function RefundMethodPage() {
    const { captureEventPosthog } = usePostHog()
    const refundMethodsResponse = useAppSelector(
        (store) => store.refundMethodsApi.refundMethods.response
    )
    const dynamicAvailableRefundMethods =
        refundMethodsResponse.result?.refund_methods ?? []

    const fraudScore = refundMethodsResponse.result?.fraud_result
        ?.outcome as FraudScore

    const FraudGroup = refundMethodsResponse.result?.fraud_result
        ?.group as FraudGroup

    useEffect(() => {
        captureEventPosthog('Fraud Score Result', {
            FraudScore: FraudScores[fraudScore],
            FraudGroup: FraudGroups[FraudGroup]
        })
    }, [])
    const { translate } = useTranslate()
    const { pages } = usePages()

    const { t } = useTranslation()

    const navigate = useNavigate()
    const address = useAppSelector((store) => store.return.returnAddress)
    const { callReturnMethods } = useReturnMethods(onSuccessReturnMethods)
    const isKeepYourItem = useAppSelector((store) => store.return.keepYourItem)

    useEffect(() => {
        if (!refundMethodsResponse.result_raw) {
            toast({
                text: t('toast_errors.lost_order'),
                variant: 'error'
            })
            navigate(pages.Landing)
        }
    }, [])

    const ret = useAppSelector((store) => store.return)
    const refundPaymentMethod = ret.refundPaymentMethod
    const returnedItems = ret.returnedItems
    const moreThanOneItem = returnedItems.length > 1

    const { callCalculate, loadingCalculate } = useCalculate()
    const provisionalRefundAmount = useAppSelector(
        (store) => store.refundMethodsApi.provisionalRefundAmount
    )
    const hasExchanges = returnedItems.some(
        (item) => item.item.action === RefundActions.ToExchange
    )
    const onlyExchanges = returnedItems.every(
        (item) => item.item.action === RefundActions.ToExchange
    )

    const refundMethods: RestRefundMethod[] = []
    const refundMethodsKYI: RestRefundMethod[] = []
    const isKYIForced = dynamicAvailableRefundMethods.some((method) => {
        return method.keep_your_item?.force === true
    })

    dynamicAvailableRefundMethods.forEach((method) => {
        if (method.keep_your_item?.force !== true) {
            refundMethods.push({
                method: method.method,
                timing: method.timing,
                rdv_surplus: method.rdv_surplus,
                shipping_costs: method.shipping_costs,
                service_fees: method.service_fees
            })
        }
        if (method.keep_your_item?.enabled === true) {
            refundMethodsKYI.push({
                method: method.method,
                timing: method.timing,
                keep_your_item: method.keep_your_item ?? {},
                rdv_surplus: method.rdv_surplus,
                shipping_costs: method.shipping_costs,
                service_fees: method.service_fees
            })
        }
    })

    const keepYourItemOffered = dynamicAvailableRefundMethods.some(
        (method) => method.keep_your_item?.enabled === true
    )
    const giftCardOffered = dynamicAvailableRefundMethods.some(
        (method) =>
            method.method ===
            ModelsRefundPaymentMethod.RefundPaymentMethod_GIFT_CARD
    )

    function onSuccessReturnMethods() {
        navigate(pages.ReturnMethod)
    }

    const handleContinue = () => {
        const method = (refundPaymentMethod.method ?? 0) as RefundPaymentMethod
        captureEventPosthog('Refund Method', {
            RefundPaymentMethod: RefundPaymentMethods[method],
            FraudScore: FraudScores[fraudScore],
            FraudGroup: FraudGroups[FraudGroup],
            isKeepYourItem,
            keepYourItemOffered
        })

        const onlyNotReceived = returnedItems.every(
            (retItem) => retItem.item.product_return_reason == 'NOT_RECEIVED'
        )

        if (onlyNotReceived) {
            if (hasExchanges) {
                navigate(pages.ExchangeAddress)
            } else {
                callCalculate(ReturnMethod.NoReturnMethod)
            }
            return
        } else {
            if (isKeepYourItem) {
                if (hasExchanges) {
                    navigate(pages.ExchangeAddress)
                } else {
                    callCalculate(ReturnMethod.NoReturnMethod)
                }
            } else {
                callReturnMethods(address)
            }
        }
    }

    return (
        <>
            {loadingCalculate ? <LoadingModal loading /> : null}
            <TopBar
                page="refund-method"
                title={translate('refund_page_title')}
            />
            <MainDiv>
                <ReverCard>
                    {keepYourItemOffered && onlyExchanges ? (
                        <>
                            <p className="mb-3 text-lg font-semibold text-grey-2">
                                {translate(
                                    'kyi_refund_page_only_exchanges_title'
                                )}
                            </p>
                            <div className="mt-4">
                                <ExchangeKYICards isKYIForced={isKYIForced} />
                            </div>
                        </>
                    ) : (
                        <>
                            {refundMethodsKYI.length > 0 && (
                                <>
                                    <p className="mb-3 text-lg font-semibold text-grey-2">
                                        {moreThanOneItem
                                            ? 'Keep your items'
                                            : translate(
                                                  'kyi_refund_page_kyi_title'
                                              )}
                                    </p>

                                    {refundMethodsKYI.map((method, i) => (
                                        <RefundCardKYI
                                            key={i}
                                            method={method}
                                            keepYourItem={
                                                method.keep_your_item ?? {}
                                            }
                                            totalRefundPrice={
                                                provisionalRefundAmount ?? 0
                                            }
                                            selected={
                                                refundPaymentMethod.method ===
                                                    method.method &&
                                                isKeepYourItem
                                            }
                                        />
                                    ))}
                                </>
                            )}
                            {refundMethodsKYI.length &&
                            refundMethods.length > 0 ? (
                                <p className="mb-5 mt-3 text-lg font-semibold text-grey-2">
                                    {moreThanOneItem
                                        ? 'Return your products'
                                        : translate(
                                              'kyi_refund_page_other_methods_title'
                                          )}
                                </p>
                            ) : null}
                            {refundMethods.length > 0 &&
                                refundMethods.map((method, i) => (
                                    <RefundCard
                                        key={i}
                                        method={method}
                                        totalRefundPrice={
                                            provisionalRefundAmount ?? 0
                                        }
                                        keepYourItemOffered={
                                            keepYourItemOffered
                                        }
                                        giftCardOffered={giftCardOffered}
                                        selected={
                                            refundPaymentMethod.method ===
                                                method.method && !isKeepYourItem
                                        }
                                        rdvSurplus={method.rdv_surplus}
                                        shippingCosts={method.shipping_costs}
                                        serviceFees={method.service_fees}
                                    />
                                ))}
                            {refundMethods.length === 0 &&
                                refundMethodsKYI.length === 0 && (
                                    <FallbackNotFound
                                        title={translate(
                                            'refund_page_no_methods_available'
                                        )}
                                    />
                                )}
                        </>
                    )}

                    <div className="mt-2 flex w-full justify-center">
                        <div className="w-full md:max-w-[280px]">
                            <Button
                                id="refundMethodContinue"
                                onClick={handleContinue}
                                fullWidth
                                disabled={
                                    refundPaymentMethod.method === 0 ||
                                    (refundMethods.length === 0 &&
                                        refundMethodsKYI.length === 0)
                                }
                            >
                                {translate('refund_page_button_continue')}
                            </Button>
                        </div>
                    </div>
                </ReverCard>
            </MainDiv>
        </>
    )
}

const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    flex-grow: 1;
    @media ${device.lg} {
        background-color: #f2f2f2;
    }
`
