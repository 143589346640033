import { ReturnsLineItem as ModelsLineItem } from '@itsrever/rever-api'
import { formatPrice } from '@/utils'
import { useTheme } from '@itsrever/design-system'
import { useAppSelector } from '@/redux/hooks'
import NoAvailable from '@/assets/images/noAvailable.png'

export interface ProductPreviewProps {
    product: ModelsLineItem
    disabled?: boolean
    selectedQuantity?: number
}

export const ProductPreview: React.FC<ProductPreviewProps> = ({
    product: lineItem,
    disabled = false
}) => {
    const theme = useTheme()
    const product = lineItem?.product
    const orderMoneyFormat =
        useAppSelector(
            (store) => store.returnsApi.order.response?.order?.money_format
        ) ?? {}

    const imgSrc = product?.images ? product.images[0].src : NoAvailable
    let productPrice = undefined
    if (lineItem?.total && lineItem.quantity)
        productPrice = formatPrice(
            Math.round(lineItem.total / lineItem.quantity),
            orderMoneyFormat
        )

    return (
        <div className="flex w-full flex-row gap-4">
            <div className="relative max-h-28 min-h-20 w-20 overflow-hidden rounded-lg">
                <img
                    src={imgSrc}
                    alt="ProductImage"
                    className="absolute inset-0 size-full rounded-lg object-cover transition-all duration-300 hover:scale-110"
                />
                {lineItem.quantity && lineItem.quantity > 1 ? (
                    <p className="absolute bottom-1 right-1 flex items-center rounded bg-white/50 p-0.5 px-1 text-xs text-neutral-600 backdrop-blur-sm">
                        <span className="mr-0.5">x</span>
                        <span>{lineItem.quantity}</span>
                    </p>
                ) : null}
            </div>

            <div className="flex flex-col justify-start">
                <div className="flex gap-2">
                    <p
                        className="text-sm font-light text-neutral-800"
                        style={disabled ? { color: theme.colors.grey[2] } : {}}
                    >
                        {lineItem.name}
                    </p>
                </div>
                <p
                    className="w-fit rounded-md  border-solid border-neutral-200 text-xs font-light text-neutral-400"
                    style={disabled ? { color: theme.colors.grey[2] } : {}}
                >
                    {lineItem.variant_name}
                </p>
                {productPrice && (
                    <p
                        className="mb-1 mt-auto rounded text-base font-light text-neutral-600"
                        dangerouslySetInnerHTML={{ __html: productPrice }}
                        style={disabled ? { color: theme.colors.grey[2] } : {}}
                    />
                )}
            </div>
        </div>
    )
}

export default ProductPreview
