import styled from 'styled-components'
import FakeMap from '@/assets/images/fake-map2.png'
import Gift from '@/assets/images/gift2.png'
import Drawer from '@mui/material/Drawer'
import { useState } from 'react'
import Location from '@/assets/images/icons/LocationFilledIcon.svg'

function OrderTracking() {
    const handleClose = () => {
        setDrawerOpen(false)
    }
    const [drawerOpen, setDrawerOpen] = useState(false)

    const items = [
        {
            name: 'Short two-tone small heart Double necklace My Other Half',
            variant: 'Necklace',
            price: '89€',
            href: 'https://cloud-media.tous.com/medias/sys_master/images/h00/h92/13072091152414/515Wx515H_product_1004099100_5-20240118180119/515Wx515H-product-1004099100-5-20240118180119.jpg'
        },
        {
            name: 'Set large two-tone heart and pink acetate Rings My Other Half',
            variant: 'Rings',
            price: '179€',
            href: 'https://cloud-media.tous.com/medias/sys_master/images/h07/h94/13049769197598/515Wx515H_product_c1004108712_6-20240111140526/515Wx515H-product-c1004108712-6-20240111140526.jpg'
        },
        {
            name: 'Mochila shell',
            variant: 'Cypress green',
            price: '179€',
            href: 'https://shop.tropicfeel.com/cdn/shop/products/Backpacks-Shell-20-42L-Backpack-SS23-Cypress-Green-3_420x.jpg?v=1681311102'
        },
        {
            name: 'Mochila shell',
            variant: 'Fog grey',
            price: '179€',
            href: 'https://shop.tropicfeel.com/cdn/shop/products/Backpacks-Shell-20-42L-Backpack-SS23-Fog-Grey-3_260x.jpg?v=1681311102'
        },
        {
            name: 'Mochila shelter',
            variant: 'Fog grey',
            price: '179€',
            href: 'https://shop.tropicfeel.com/cdn/shop/products/Backpacks-Shelter-30-66L-Backpack-SS23-Fog-Grey-3_260x.jpg?v=1685458248'
        },
        {
            name: 'Mochila shelter',
            variant: 'Core black',
            price: '179€',
            href: 'https://shop.tropicfeel.com/cdn/shop/products/Backpacks-Shelter-30-66L-Backpack-SS23-Core-Black-3_180x.jpg?v=1676028394'
        },
        {
            name: 'Mochila shelter',
            variant: 'Olive green',
            price: '179€',
            href: 'https://shop.tropicfeel.com/cdn/shop/products/Backpacks-Shelter-30-66L-Backpack-SS23-Olive-Green-3_180x.jpg?v=1685458248'
        },
        {
            name: 'Mochila Nest',
            variant: 'All black',
            price: '179€',
            href: 'https://shop.tropicfeel.com/cdn/shop/products/Backpacks-Nest-SS22-All-Black-4_260x.jpg?v=1685458419'
        }
    ]

    return (
        <>
            <div className="relative flex h-full flex-col justify-between overflow-x-hidden bg-slate-50">
                <div className="container relative z-40 mx-auto px-2 pt-3">
                    <div className="flex w-full justify-center">
                        <div className="rounded-full bg-blue-500 px-3 py-1 text-xs text-white shadow-sm">
                            Order #12345
                        </div>
                    </div>
                    <p className="text-blue-900">Arriving on</p>
                    {/* <h3 className="inline-block bg-gradient-to-r from-blue-600 to-blue-50 bg-clip-text text-4xl text-transparent"> */}
                    <h3 className="inline-block text-5xl text-blue-900">
                        Saturday
                    </h3>
                    <h3 className="text-2xl text-blue-900">July 26th</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <div className="mt-5">
                            <div className="flex items-center justify-between">
                                <p className="text-lg font-semibold text-white">
                                    Delivery address
                                </p>
                            </div>
                            <div className="mt-1 flex justify-between text-sm text-blue-900">
                                <div className="flex items-center">
                                    <img
                                        src={Location}
                                        className="mr-1 h-5 w-5 opacity-60"
                                    />
                                    <p>Hospital Clínic 08011, Barcelona</p>
                                </div>
                                <div className=" mr-2 text-sky-900">Edit</div>
                            </div>
                            <img
                                src={FakeMap}
                                className="mt-2 w-full rounded-2xl shadow-lg"
                            />
                        </div>

                        <div>
                            <p className="mt-5 text-base font-semibold text-slate-600 md:text-white">
                                Shipment progress
                            </p>
                            <div className="relative mt-2 flex flex-col rounded-2xl bg-white pb-1 pl-7 pt-3 shadow-lg">
                                <div className="flex items-center border-l-2 border-solid border-blue-300 pb-4">
                                    <div className="absolute left-[19px] -mt-6 h-5 w-5 rounded-full bg-blue-400 pl-1 text-sm text-white">
                                        ✓
                                    </div>
                                    <div className="ml-10 flex flex-col">
                                        <span>At Brand warehouse</span>
                                        <span className="text-sm text-slate-500">
                                            10.07.2023
                                        </span>
                                    </div>
                                </div>
                                <div className="flex items-center border-l-2 border-solid border-blue-300 pb-4">
                                    <div className="absolute left-[19px] -mt-6 h-5 w-5 rounded-full bg-blue-400 pl-1 text-sm text-white">
                                        ✓
                                    </div>
                                    <div className="ml-10 flex flex-col">
                                        <span>At carrier warehouse</span>
                                        <span className="text-sm text-slate-500">
                                            14.07.2023
                                        </span>
                                    </div>
                                </div>
                                <div className="flex items-center border-l-2 border-dotted border-blue-300 pb-4">
                                    <div className="absolute left-[19px] -mt-6 h-5 w-5 rounded-full bg-blue-400 pl-1 text-sm text-white">
                                        ✓
                                    </div>
                                    <div className="ml-10 flex flex-col">
                                        <span>Scheduled delivery</span>
                                        <span className="text-sm text-slate-500">
                                            16.07.2023
                                        </span>
                                    </div>
                                </div>
                                <div className="flex items-center border-l-2 border-dotted border-blue-300 pb-4 pt-2">
                                    <div className="absolute left-[23px] -mt-3 h-3 w-3 rounded-full border border-solid border-blue-400 bg-slate-50 p-1 text-white"></div>
                                    <div className="ml-10 flex flex-col">
                                        <span className="-mt-3">
                                            On delivery
                                        </span>
                                    </div>
                                </div>
                                <div className="flex items-center border-l-2 border-blue-300 pb-4 pt-2">
                                    <div className="absolute left-[23px] -mt-3 h-3 w-3 rounded-full border border-solid border-blue-400 bg-slate-50 p-1 text-white"></div>
                                    <div className="ml-10 flex flex-col">
                                        <span className="-mt-3">Delivered</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    onClick={() => setDrawerOpen(true)}
                    style={{ boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.2)' }}
                    className="z-50 mt-5 flex h-[100px] w-full flex-col content-center rounded-t-3xl border-solid border-slate-300 bg-white px-4 py-3"
                >
                    <span className="mx-auto h-2 w-16 rounded-full bg-slate-200"></span>
                    <div className="flex items-center">
                        <div className="mr-5">
                            <img src={Gift} className="w-16" />
                        </div>
                        <div className="flex flex-col text-sm font-light">
                            <span>Counting down to delivery!</span>
                            <span className="font-bold text-blue-500">
                                Swipe up to discover our offer for you.
                            </span>
                        </div>
                    </div>
                </div>
                <div className="absolute top-0 z-0 ml-[-50%] h-[300px] w-[200%] overflow-hidden rounded-b-[100%] bg-gradient-to-b from-blue-300 via-blue-400 to-blue-500"></div>
            </div>
            <Drawer
                anchor="bottom"
                open={drawerOpen}
                onClose={handleClose}
                sx={{ backdropFilter: 'blur(5px)' }}
                PaperProps={{
                    sx: {
                        borderRadius: '2rem 2rem 0 0',
                        backgroundColor: 'rgba(255,255,255, 1)'
                    }
                }}
            >
                <div className=" h-[600px] opacity-100">
                    <p className="ml-5 mt-5 text-xl font-bold text-slate-700">
                        Other people also like...
                    </p>
                    <div className="flex overflow-scroll">
                        <ItemsDiv className="mt-1 px-5">
                            {items.map((item, idx) => {
                                return (
                                    <ItemDiv
                                        key={idx}
                                        className="rounded-lg bg-white shadow-md"
                                    >
                                        <img
                                            src={item.href}
                                            className="h-fit w-64 rounded-t-lg"
                                        />
                                        <div className="p-2">
                                            <p className="mt-2">{item.name}</p>
                                            <p className="text-xs text-grey-3">
                                                {item.variant}
                                            </p>
                                            <p className="mt-1">{item.price}</p>
                                        </div>
                                    </ItemDiv>
                                )
                            })}
                        </ItemsDiv>
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default OrderTracking

const ItemsDiv = styled.div`
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); */
    grid-template-columns: 1fr 1fr;
    margin: 1rem 0;
    gap: 1rem;
`

const ItemDiv = styled.div`
    width: fit-content;
    cursor: pointer;
`
