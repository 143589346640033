import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import download from '@/assets/images/icons/download-label.svg'
import schedulePickup from '@/assets/images/icons/schedule-pickup.svg'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '@/redux/hooks'
import { usePages } from '@/hooks'
import { useTheme } from '@itsrever/design-system'
import { TrackingHeader } from '@/components/TrackingComponents'
import { TrackingContext } from '@/layout/TrackingLayout'
import { useContext } from 'react'
import { usePostHog } from '@/application/use-posthog'

function PickUp() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { pages } = usePages()
    const trackingInfo = useContext(TrackingContext)
    const theme = useTheme()
    const pickUpUrl = trackingInfo?.logistics_info?.pickup_info?.schedule_url
    const { captureEventPosthog } = usePostHog()

    const ecommerceName = useAppSelector(
        (store) => store.settingsApi.settings.response.name
    )
    const handleClickNewPickup = () => {
        captureEventPosthog('Schedule New Pickup')
    }

    return (
        <MainDiv bgcolor={theme.colors.grey[5]}>
            <TrackingHeader>
                <div className="flex w-full justify-center text-brand-900">
                    <div className="ml-7 w-[300px] md:ml-0">
                        <h4 className="text-2xl">
                            {t('tracking_pages.pickup')}
                        </h4>
                        <p className="mt-2">
                            {t('tracking_pages.description')}
                            {ecommerceName}
                        </p>
                    </div>
                </div>
            </TrackingHeader>
            <ContentDiv>
                <div className="grid grid-cols-1 gap-3">
                    <ActionButton
                        target="_blank"
                        href={pickUpUrl}
                        onClick={handleClickNewPickup}
                        className="rounded-xl bg-gradient-to-b from-brand-400 to-brand-500 shadow-md hover:bg-brand-600"
                    >
                        <Icon src={schedulePickup} />
                        {t(`tracking_pages.pickup`)}
                    </ActionButton>
                    <ActionButton
                        onClick={() => navigate(pages.TrackingReturnLabel)}
                        className="rounded-xl bg-gradient-to-b from-brand-400 to-brand-500 shadow-md hover:bg-brand-600"
                    >
                        <Icon src={download} />
                        {t(`tracking_logistics.download_label`)}
                    </ActionButton>
                </div>
                <div className="grid max-w-[450px] auto-rows-fr grid-cols-1 gap-6 rounded-lg p-5 text-slate-800">
                    {[...Array(5)].map((step, index) => (
                        <Step key={index}>
                            <div className="mr-3 flex size-10 shrink-0 items-center justify-center rounded-full border border-solid border-brand-500 bg-white p-2 font-bold leading-10 text-brand-700 shadow-md shadow-brand-200">
                                {index + 1}
                            </div>
                            <p>
                                {t(
                                    `tracking_logistics.schedule_pickup_${
                                        index + 1
                                    }`
                                )}
                            </p>
                        </Step>
                    ))}
                </div>
            </ContentDiv>
        </MainDiv>
    )
}

export default PickUp

const MainDiv = styled.div<{ bgcolor: string }>`
    flex-grow: 1;
`

const ContentDiv = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
`

const Step = styled.div`
    flex-basis: 150px;
    border-radius: 5px;
    position: relative;
    display: flex;
    align-items: center;
`

const ActionButton = styled.a`
    display: flex;
    cursor: pointer;
    align-items: center;
    background-color: #0148a5;
    padding: 0.75rem 1rem;
    width: 250px;
    color: white;
    &:hover {
        background-color: #2c77d9;
    }
`

const Icon = styled.img`
    height: 22px;
    width: 22px;
    margin-right: 0.5rem;
    filter: brightness(0) invert(1);
`
