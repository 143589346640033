const OPMIcon = () => (
    <svg
        width="17"
        height="12"
        viewBox="0 0 17 12"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.85 0H16.15C16.6195 0 17 0.380558 17 0.85V2.55H0V0.85C0 0.380558 0.380558 0 0.85 0ZM0 11.05C0 11.5195 0.380558 11.9 0.85 11.9H16.15C16.6195 11.9 17 11.5195 17 11.05V5.95H0V11.05ZM2.55 8.5C2.55 8.03055 2.93056 7.65 3.4 7.65H6.8C7.26945 7.65 7.65 8.03055 7.65 8.5V9.35C7.65 9.81945 7.26945 10.2 6.8 10.2H3.4C2.93056 10.2 2.55 9.81945 2.55 9.35V8.5Z"
            fill="currentColor"
        />
    </svg>
)

export default OPMIcon
