import { useTheme } from '@itsrever/design-system'
import { PbshoppingMenuItem } from '@itsrever/rever-api'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface CategorySelectorProps {
    categories: Array<PbshoppingMenuItem>
    selected: number
    handleChange: (i: number) => void
}

export const CategorySelector: React.FC<CategorySelectorProps> = ({
    categories,
    selected,
    handleChange
}) => {
    const { t } = useTranslation()
    const theme = useTheme()
    return (
        <CategoriesContainer className="min-h-[40px] overflow-x-scroll">
            <ul className="flex gap-4">
                {categories.map((category, i) => {
                    return (
                        <Category
                            key={i}
                            onClick={() => handleChange(i)}
                            $selected={selected === i}
                            $mainColor={
                                theme.colors.primary.main === 'transparent'
                                    ? '#000'
                                    : theme.colors.primary.main
                            }
                            $secondaryColor={theme.colors.primary.light}
                        >
                            <p className="text-base">
                                {!category?.title
                                    ? t('full_catalog.all_products')
                                    : category?.title}
                            </p>
                        </Category>
                    )
                })}
            </ul>
        </CategoriesContainer>
    )
}

const CategoriesContainer = styled.div`
    position: relative;
`

const Category = styled.li<{
    $selected: boolean
    $mainColor: string
    $secondaryColor: string
}>`
    cursor: pointer;
    flex-shrink: 0;
    ${(p) =>
        !p.$selected &&
        `
        :hover {
            text-underline-offset: 5px;
            text-decoration: underline;
            text-decoration-color: ${p.$secondaryColor};
            text-decoration-thickness: 2px;

        }`}
    ${(p) =>
        p.$selected &&
        `
            text-underline-offset: 5px;
            text-decoration: underline;
            text-decoration-color: ${p.$mainColor};
            text-decoration-thickness: 2px;
      `}
`
