import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { getCopies, getDefaultCopies } from '@/redux/api/settingsApiSlice'
import { useSlug } from '@/hooks'

export function useCopies() {
    const dispatch = useAppDispatch()
    const slug = useSlug()

    const copiesCall = useAppSelector((store) => store.settingsApi.copies)
    const loadingCopies = copiesCall.loading
    const errorCopies = copiesCall.error

    const defaultCopiesCall = useAppSelector((s) => s.settingsApi.defaultCopies)
    const loadingDefaultCopies = defaultCopiesCall.loading
    const errorDefaultCopies = defaultCopiesCall.error

    useEffect(() => {
        if (!slug) return
        const promise = dispatch(getCopies(slug))
        const second = dispatch(getDefaultCopies())
        return () => {
            promise.abort()
            second.abort()
        }
    }, [slug, dispatch])

    return {
        loadingCopies: loadingCopies || loadingDefaultCopies,
        errorCopies: errorCopies || errorDefaultCopies
    }
}
