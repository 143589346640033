import styled from 'styled-components'
import { device } from '@/utils'

interface ProgressBarProps {
    pendingColor: string
    successColor: string
}

const ProgressBarWarehouse: React.FC<ProgressBarProps> = ({
    pendingColor,
    successColor
}) => {
    return (
        <SVGDiv>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 280 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <line
                    x1="104"
                    y1="7.5"
                    x2="174"
                    y2="7.49999"
                    stroke={'white'}
                />
                <line
                    x1="178"
                    y1="7.5"
                    x2="246"
                    y2="7.50001"
                    stroke={'white'}
                />
                <circle cx="176" cy="8" r="8" fill={successColor} />
                <path d="M172 8L175 11L180 5" stroke="white" strokeWidth="2" />
                <circle cx="248" cy="8" r="8" fill={pendingColor} />
                <circle cx="248" cy="8" r="3" fill="white" />
                <line x1="31" y1="7.5" x2="104" y2="7.50001" stroke={'white'} />
                <circle cx="104" cy="8" r="8" fill={successColor} />
                <path d="M100 8L103 11L108 5" stroke="white" strokeWidth="2" />
                <circle cx="32" cy="8" r="8" fill={successColor} />
                <path d="M28 8L31 11L36 5" stroke="white" strokeWidth="2" />
            </svg>
        </SVGDiv>
    )
}

const SVGDiv = styled.svg`
    // Over lg
    width: 280px;
    height: 24px;
    @media ${device.lg} {
        width: 400px;
        height: 36px;
    }
`

export default ProgressBarWarehouse
