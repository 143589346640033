import { useAppSelector } from '@/redux/hooks'
import { useTheme } from '@itsrever/design-system'
import { PbshoppingMenuItem } from '@itsrever/rever-api'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface DesktopNavBarProps {
    categories: Array<PbshoppingMenuItem>
    selectedCategory: number
    handleChangeCategory: (value: number) => void
}

export const DesktopNavBar: React.FC<DesktopNavBarProps> = ({
    categories,
    selectedCategory,
    handleChangeCategory
}) => {
    const { t } = useTranslation()
    const logoUrl = useAppSelector(
        (store) => store.settingsApi.settings.response.theme?.logo_url
    )
    return (
        <Navbar>
            <LogoContainer>
                <img src={logoUrl} className="my-6 pr-4" />
            </LogoContainer>
            <div className="px-4 py-6">
                <h3 className="font-medium text-gray-800">
                    {t('desktop_catalog.categories')}
                </h3>
                <CategoriesList
                    categories={categories}
                    selected={selectedCategory}
                    handleChange={handleChangeCategory}
                />
            </div>
        </Navbar>
    )
}

const Navbar = styled.nav`
    height: 100%;
    width: 20rem;
    border-right: 1px solid #e5e5e5;
    background-color: #fff;
    overflow: scroll;
`

const LogoContainer = styled.div`
    border-bottom: 1px solid #e5e5e5;
    padding: 0.5rem 1.5rem;
`

interface CategoriesListProps {
    categories: Array<PbshoppingMenuItem>
    selected: number
    handleChange: (i: number) => void
}

const CategoriesList: React.FC<CategoriesListProps> = ({
    categories,
    selected,
    handleChange
}) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const isTransparentMode = theme.colors.primary.main === 'transparent'
    return (
        <div className="mt-6 px-4">
            <ul>
                {categories?.map((category, i) => {
                    const isSelected = selected === i
                    let bdColor = isSelected ? theme.colors.primary.main : ''
                    bdColor =
                        isTransparentMode && isSelected ? '#000000' : bdColor
                    let hoverBdColor = isSelected
                        ? ''
                        : theme.colors.primary.light
                    hoverBdColor = isTransparentMode ? '#8c8c8c' : bdColor

                    return (
                        <ListItem
                            key={i}
                            bdColor={bdColor}
                            hoverBdColor={hoverBdColor}
                            onClick={() => handleChange(i)}
                        >
                            <p
                                className={`hover:text-gray-900 ${
                                    selected === i
                                        ? 'text-gray-900'
                                        : 'text-gray-600'
                                }`}
                            >
                                {!category?.title
                                    ? t('full_catalog.all_products')
                                    : category?.title}
                            </p>
                        </ListItem>
                    )
                })}
            </ul>
        </div>
    )
}

const ListItem = styled.li<{ bdColor: string; hoverBdColor: string }>`
    width: 100%;
    cursor: pointer;
    border-left-width: 1px;
    padding: 0.5rem 1rem;
    transition: all 0.15s ease-in-out;
    border-color: ${(p) => p.bdColor};
    &:hover {
        border-color: ${(p) => p.hoverBdColor};
    }
`
