import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { useEffect } from 'react'
import { fetchMenu } from '@/redux/api/storeApiSlice'
import { StoreApiFetchMenuRequest } from '@itsrever/rever-api'
import { useTranslation } from 'react-i18next'

export function useStoreMenu() {
    const dispatch = useAppDispatch()
    const { i18n } = useTranslation()
    const countryCode =
        useAppSelector((store) => store.return.returnAddress.country_code) ??
        'ES'
    const language = i18n.language.toLocaleUpperCase() ?? 'EN'
    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )
    const ecommerceId = settings.ecommerce_id ?? ''
    const menuCall = useAppSelector((store) => store.store.menu)
    const menu = menuCall.response
    const loadingMenu = menuCall.loading
    const exchangeFlow = useAppSelector(
        (store) => store.settingsApi.settings.response.refund?.exchange_flow
    )

    useEffect(() => {
        if (exchangeFlow !== 'FULL') return
        if (loadingMenu === 'succeeded' || loadingMenu === 'failed') return
        const fetchStoreMenu = async () => {
            try {
                const fetchMenuParams: StoreApiFetchMenuRequest = {
                    ecommerceId,
                    language,
                    countryCode
                }
                await dispatch(fetchMenu(fetchMenuParams))
            } catch (error) {
                console.error('Failed to fetch menu:', error)
            }
        }
        fetchStoreMenu()
    }, [
        dispatch,
        language,
        countryCode,
        ecommerceId,
        loadingMenu,
        exchangeFlow
    ])

    return { menu, loadingMenu, isMenuLoaded: loadingMenu === 'succeeded' }
}
