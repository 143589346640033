import { getInstructions } from '@/api/trackingApi'
import { useEffect, useState } from 'react'
import { usePostHog } from '@/application/use-posthog'

export function useDownloadInstructions(
    processId: string | null,
    email?: string
) {
    const { captureEventPosthog } = usePostHog()
    const [instructionsURL, setInstructionsURL] = useState<string | null>(null)

    useEffect(() => {
        async function fetchInstructionsURL() {
            if (!processId || !email) return
            try {
                const response = await getInstructions({
                    process_id: processId,
                    email
                })
                setInstructionsURL(response.url ?? null)
            } catch (e) {
                console.warn(e)
            }
        }
        fetchInstructionsURL()
    }, [email, processId])

    async function downloadInstructions() {
        if (!instructionsURL) return
        captureEventPosthog('Download Instructions', {
            processId
        })

        if (
            /iphone/i.test(navigator.userAgent) ||
            /ipad/i.test(navigator.userAgent)
        ) {
            window.location.href = instructionsURL ?? ''
        } else {
            window.open(instructionsURL, '_blank')
        }
    }

    return { downloadInstructions, instructionsURL }
}
