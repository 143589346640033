import { useNavigate, usePages, useTranslate } from '@/hooks'
import ProgressBar from '@/layout/LayoutComponents/ProgressBar'
import { useAppSelector } from '@/redux/hooks'
import { Button, Modal } from '@itsrever/design-system'
import styled from 'styled-components'
import { useTheme } from '@itsrever/design-system'
import { useEffect, useState } from 'react'
import AddProductCard from '@/components/ReverLite/AddProductCard'
import { useMediaQuery, Drawer } from '@mui/material'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { useOrderLite } from '@/hooks/useOrderLite'
import { ProductLite } from '@/utils/types'
import { useTranslation } from 'react-i18next'
import { SelectReason } from '../ItemSelectionComponents'

const OrderLite = () => {
    const { translate } = useTranslate()
    const { t } = useTranslation()
    const theme = useTheme()
    const [parent] = useAutoAnimate({
        duration: 400,
        easing: 'cubic-bezier(0.68, -0.55, 0.27, 1.55)'
    })
    const order = useAppSelector((store) => store.order)
    const orderNumber = order.orderNumber
    const { navigateRelative } = useNavigate()
    const { pages } = usePages()
    const ecommerceId = useAppSelector(
        (store) => store.settingsApi.settings.response.ecommerce_id
    )
    const retItems = useAppSelector((store) => store.return.returnedItems)
    const orderItems = order.orderProducts
    const getInitialProducts = () => {
        if (!retItems.length || !orderItems.length) {
            return []
        } else {
            return retItems?.map(
                (item, index) =>
                    ({
                        name: orderItems[index].product.name,
                        reason: item.item.product_return_reason,
                        quantity: item.item.quantity
                    } as ProductLite)
            )
        }
    }
    const [products, setProducts] = useState<ProductLite[]>(
        getInitialProducts()
    )
    const { callOrderLite } = useOrderLite()
    const isMobile = useMediaQuery('(max-width:599px)')
    const [isOpen, setIsOpen] = useState(false)
    const handleClose = () => setIsOpen(false)

    const [selectedProduct, setSelectedProduct] = useState<undefined | number>(
        undefined
    )
    const [showAddProductWarning, setShowAddProductWarning] = useState(false)

    const [currentSelectedReason, setCurrentSelectedReason] = useState('')
    useEffect(() => {
        setCurrentSelectedReason(products[0]?.reason ?? '')
    }, [selectedProduct, products])

    const addProduct = () => {
        setProducts([...products, { name: '', reason: '', quantity: 1 }])
    }
    const updateProduct = (index: number, newProduct: ProductLite) => {
        const newProducts = [...products]
        newProducts[index] = newProduct
        setProducts(newProducts)
        setShowAddProductWarning(false)
    }
    const onRemoveProduct = (index: number) => {
        setProducts(products.filter((_, i) => i !== index))
        setShowAddProductWarning(false)
    }

    const onReasonSelected = (reason: string) => {
        if (selectedProduct === undefined) return
        products[selectedProduct].reason = reason
        setCurrentSelectedReason(reason)
    }
    const onClickContinue = () => {
        setIsOpen(false)
        setCurrentSelectedReason('')
    }

    const renderContainer = (children: React.ReactNode) => {
        if (isMobile) {
            return (
                <Drawer
                    anchor="bottom"
                    open={isOpen}
                    onClose={handleClose}
                    sx={{
                        backdropFilter: 'blur(5px)'
                    }}
                >
                    {children}
                </Drawer>
            )
        }
        return (
            <Modal isOpen={isOpen} onRequestClose={handleClose} closeButton>
                {children}
            </Modal>
        )
    }

    const handleClickContinue = async () => {
        if (products.some((prod) => prod.reason === '' || prod.name === '')) {
            setShowAddProductWarning(true)
            return
        }
        const returnsCreateLiteOrderRequest = {
            customer_printed_order_id: orderNumber,
            ecommerce_id: ecommerceId,
            email: order.email,
            products_to_return: products.map((prod) => ({
                product_name: prod.name,
                quantity: prod.quantity,
                reason: prod.reason
            }))
        }
        callOrderLite(returnsCreateLiteOrderRequest, products)

        navigateRelative(pages.ReturnAddress)
    }

    return (
        <>
            <TopBar>
                <h5
                    className="text-center"
                    dangerouslySetInnerHTML={{
                        __html: translate('item_selection_title')
                    }}
                />
                <ProgressBar page="order" />
            </TopBar>
            <MainDiv color={theme.colors.grey[5]}>
                <div className="mb-6 w-10/12 max-w-[800px] md:w-8/12">
                    <div className="my-4 flex items-center justify-between">
                        <div className="">
                            <span
                                className="text-xs text-grey-2"
                                dangerouslySetInnerHTML={{
                                    __html: translate('item_selection_order')
                                }}
                            />
                            <span className="ml-1 text-xs text-common-black">
                                {orderNumber}
                            </span>
                        </div>
                        {products.length > 0 && (
                            <div>
                                <button
                                    onClick={addProduct}
                                    className="flex w-fit items-center justify-center gap-2 rounded-md border border-solid border-grey-4 bg-white px-4 text-center shadow-sm"
                                >
                                    <span className="mb-1 mr-2 text-5xl leading-9 text-gray-400">
                                        +
                                    </span>
                                    <span>
                                        {t(
                                            'rever_lite.add_another_item_button'
                                        )}
                                    </span>
                                </button>
                            </div>
                        )}
                    </div>
                    <div ref={parent} className="flex flex-col gap-2">
                        {products.map((prod, index) => (
                            <div key={index}>
                                <AddProductCard
                                    product={prod}
                                    updateProduct={(newProduct) =>
                                        updateProduct(index, newProduct)
                                    }
                                    onClickReason={() => {
                                        setSelectedProduct(index)
                                        setIsOpen(!isOpen)
                                    }}
                                    onRemove={() => onRemoveProduct(index)}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="mt-8 flex w-full flex-col items-center justify-center">
                        {showAddProductWarning && (
                            <p className="mb-2 text-yellow-500">
                                {t('rever_lite.add_product_warning')}
                            </p>
                        )}
                    </div>
                </div>
                <div className="mb-10">
                    {products.length > 0 && (
                        <Button
                            disabled={false}
                            onClick={() => handleClickContinue()}
                            id="item-selection-continue-button"
                        >
                            {translate('item_selection_button_continue')}
                        </Button>
                    )}
                </div>
                {products.length === 0 && (
                    <div className="-mt-16 flex flex-col items-center justify-center">
                        <div className=" " onClick={addProduct}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                viewBox="0 0 21 21"
                                className="size-28 text-grey-4"
                            >
                                <g
                                    fill="none"
                                    fillRule="evenodd"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path d="m3.5 7.5l7-4l5.992 3.424A2 2 0 0 1 17.5 8.661v4.678a2 2 0 0 1-1.008 1.737l-5 2.857a2 2 0 0 1-1.984 0l-5-2.857A2 2 0 0 1 3.5 13.339v-2.802" />
                                    <path d="M9.552 10.99a2 2 0 0 0 1.896 0L17 8m-6.5 3.5V18" />
                                    <path d="m3.5 7.5l7 4l-3 1l-7-4zm7-4l7 4l2-2l-7-4z" />
                                </g>
                            </svg>
                        </div>
                        <p className="text-lg font-semibold text-grey-3">
                            {t('rever_lite.no_items_yet')}
                        </p>
                        <button
                            onClick={addProduct}
                            className="mt-6 flex w-fit items-center justify-center gap-2 rounded-md border border-solid border-grey-4 bg-white px-4 text-center shadow-sm"
                        >
                            <span className="mb-1 mr-2 text-5xl leading-9 text-gray-400">
                                +
                            </span>
                            <span>
                                {t('rever_lite.empty_items_add_button')}
                            </span>
                        </button>
                    </div>
                )}
            </MainDiv>

            {renderContainer(
                <SelectReason
                    product={products[selectedProduct ?? 0]}
                    selectedReason={currentSelectedReason}
                    setSelectedReason={onReasonSelected}
                    handleContinue={onClickContinue}
                    isFullCatalog={false}
                    showProductPreview={false}
                />
            )}
        </>
    )
}

export default OrderLite

const TopBar = styled.div`
    z-index: 10;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    margin-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
`

const MainDiv = styled.div<{ color: string }>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: ${(p) => p.color};
`
