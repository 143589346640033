import { ReturnsProductDetailsResponseVariantInfo } from '@itsrever/rever-api'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface MobileVariantButtonProps {
    variant: ReturnsProductDetailsResponseVariantInfo
    selectedVariantIndex: number | undefined
    price: string
    index: number
    creditPrice: string
    onVariantClick: (
        variant: ReturnsProductDetailsResponseVariantInfo,
        index: number
    ) => void
    hasStock: boolean
    productName: string
}

export const MobileVariantButton: React.FC<MobileVariantButtonProps> = ({
    variant,
    index,
    selectedVariantIndex,
    price,
    creditPrice,
    onVariantClick,
    hasStock,
    productName
}) => {
    const { t } = useTranslation()

    return (
        <>
            <VariantDiv
                onClick={() => {
                    onVariantClick(variant, index)
                }}
                $selected={selectedVariantIndex === index}
                $disabled={!hasStock}
                className="pl-2"
            >
                <div className="flex flex-1 items-center justify-between overflow-hidden p-1">
                    <p className="flex-1 shrink truncate">
                        {variant.name === 'Default Title'
                            ? productName
                            : variant.name}
                    </p>
                    {!hasStock ? (
                        <p className="ml-4 py-4 pr-2 text-xs text-slate-600">
                            {t('full_catalog.no_stock')}
                        </p>
                    ) : (
                        <>
                            {creditPrice ? (
                                <div className="mt-2 flex flex-col flex-wrap items-end gap-1 pr-2">
                                    <div
                                        style={{
                                            boxShadow:
                                                '0px 0px 5.7px 0px #FFF inset'
                                        }}
                                        className="flex w-fit shrink-0 justify-end rounded-full bg-radial-gradient from-brand-800 to-brand-700 px-3 py-0.5 shadow shadow-brand-300"
                                    >
                                        <p className="text-sm text-white drop-shadow-sm">
                                            {creditPrice}
                                        </p>
                                    </div>
                                    <p className="flex shrink-0 text-xs font-light text-neutral-400">
                                        {price}{' '}
                                        {t('full_catalog.original_price')}
                                    </p>
                                </div>
                            ) : (
                                <p
                                    className={
                                        'bg- shrink-0 rounded my-2 px-2 text-sm ' +
                                        (selectedVariantIndex === index
                                            ? 'bg-brand-100 text-brand-700'
                                            : 'bg-neutral-100 text-neutral-500')
                                    }
                                >
                                    {price}
                                </p>
                            )}
                        </>
                    )}
                </div>
            </VariantDiv>
        </>
    )
}

interface VariantDivProps {
    $selected: boolean
    $disabled: boolean
}

const VariantDiv = styled.div<VariantDivProps>`
    margin-bottom: 0.5rem;
    display: flex;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    background-color: ${(p) => (p.$disabled ? '#d1d5db' : '')};
    border-radius: 0.375rem;
    border: ${(p) =>
        p.$selected ? `1px solid var(--brand-600)` : '1px solid #d1d5db'};
    &:hover {
        background-color: '#fff';
    }
`
