import { useEffect } from 'react'
import { useAppSelector } from '@/redux/hooks'
import { usePostHog } from 'posthog-js/react'
import { useLocation } from 'react-router-dom'
import { captureEventPosthog } from '@/services/Posthog'

export function usePageVisit() {
    const location = useLocation()
    const posthog = usePostHog()
    const isTesting = useAppSelector(
        (state) => state.settingsApi.settings.response.is_testing_account
    )

    useEffect(() => {
        if (isTesting) return
        captureEventPosthog('$pageview')
    }, [location.pathname, posthog, isTesting])
}
