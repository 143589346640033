import { useAppSelector } from '@/redux/hooks'
import { useEffect } from 'react'

export function useShortcutKeybind() {
    const baseURL = window.location.origin

    const slug = useAppSelector((s) => s.settingsApi.settings.response.slug)
    const orderNumber = useAppSelector((s) => s.order.orderNumber)
    const email = useAppSelector((s) => s.order.email)

    useEffect(() => {
        document.addEventListener('keydown', function (event) {
            if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
                event.preventDefault()
                navigator.clipboard.writeText(
                    `${baseURL}/${slug}?orderNumber=${encodeURIComponent(
                        orderNumber ?? ''
                    )}&email=${encodeURIComponent(email ?? '')}`
                )
            }
        })
    }, [baseURL, slug, orderNumber, email])
}
