const GiftCardIcon = () => (
    <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            id="TOP"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.5714 6.881V4.85719C14.5714 4.41011 14.209 4.04767 13.7619 4.04767H9.27714L11.819 2.10481C11.9908 1.97599 12.1043 1.78421 12.1347 1.57167C12.1651 1.35913 12.1097 1.14323 11.981 0.971475L11.4952 0.323856C11.227 -0.0338126 10.7196 -0.1063 10.3619 0.161951L7.28571 2.53386L4.20952 0.161951C3.85185 -0.1063 3.34444 -0.0338126 3.07619 0.323856L2.59048 0.971475C2.32222 1.32915 2.39471 1.83656 2.75238 2.10481L5.29429 4.04767H0.809524C0.36244 4.04767 0 4.41011 0 4.85719V6.881H14.5714Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.817619 9.30957H6.07952L6.07143 14.5715H1.61905C1.17196 14.5715 0.809524 14.2091 0.809524 13.762L0.817619 9.30957ZM13.7619 9.30957V13.762C13.7619 14.2091 13.3995 14.5715 12.9524 14.5715H8.5V9.30957H13.7619Z"
            fill="currentColor"
        />
    </svg>
)
export default GiftCardIcon
