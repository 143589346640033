import { NewItem } from '@/domain/new-item'

interface SelectedExchangeItemsProps {
    selectedItems: NewItem[]
}
export const SelectedExchangeItems: React.FC<SelectedExchangeItemsProps> = ({
    selectedItems
}) => {
    return (
        <div className="flex items-center">
            {selectedItems?.slice(0, 3).map((item, i) => {
                return (
                    <img
                        key={i}
                        className={`-ml-2 size-10 shrink-0 rounded-xl object-cover shadow-lg shadow-neutral-500/25 outline outline-2 outline-offset-0 outline-white ${
                            i % 2 === 0 ? 'rotate-3' : '-rotate-3'
                        } ${i === 0 ? 'ml-0' : ''}`}
                        src={item.productVariant.imageUrl}
                    />
                )
            })}
            {selectedItems.length > 3 && (
                <div className="-ml-1 flex size-9 shrink-0 items-center justify-center rounded-xl bg-radial-gradient from-brand-600 to-brand-500 font-semibold text-white shadow-lg shadow-neutral-500/25 outline outline-2 outline-offset-0 ring-brand-300">
                    <p className="drop-shadow-md">
                        +{selectedItems.length - 3}
                    </p>
                </div>
            )}
        </div>
    )
}
