import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { useTranslation } from 'react-i18next'
import {
    calculateReturn,
    cleanProcess,
    resetCalculate
} from '@/redux/api/returnsApiSlice'
import { toast } from '@itsrever/design-system'
import { useNavigate } from 'react-router-dom'
import {
    ApiCalculateRequest,
    ApiExchangeSelection,
    GithubComItsreverDomainModelsPkgModelsAddress as ModelsAddress
} from '@itsrever/returns-api-types'
import { ReturnMethod, ReturnMethods } from '@/redux/return/returnSlice'
import { usePages } from '@/hooks'
import { AxiosError } from 'axios'
import { usePostHog } from '@/application/use-posthog'

export function useCalculate() {
    const { t, i18n } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { pages } = usePages()
    const { captureEventPosthog } = usePostHog()

    const calculateCall = useAppSelector((store) => store.returnsApi.calculate)
    const loadingCalculate = calculateCall.loading
    const errorCalculate = calculateCall.error

    const orderInfo = useAppSelector((store) => store.order)
    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )
    const ecommerceID = settings.ecommerce_id
    const order = useAppSelector((store) => store.returnsApi.order.response)

    const returnStatus = useAppSelector((store) => store.return)
    const userSelectedCarrier = returnStatus.userSelectedCarrier
    const userSelectedProvider = returnStatus.userSelectedProvider

    const returnedItems = returnStatus.returnedItems.map((p) => {
        return p.item
    })
    const newItems = returnStatus.newItems
    const exchangeSelections = newItems.map((item) => {
        const exchangeItem: ApiExchangeSelection = {
            line_item_platform_id: item.returnedItemId,
            quantity: item.quantity,
            variant_id: item.productVariant.variantId,
            signed_product_raw: item.signedProductRaw
        }
        return exchangeItem
    })
    const refundPaymentMethod = returnStatus.refundPaymentMethod

    const pickupRanges = returnStatus.pickUpRanges
    const returnAddress = returnStatus.returnAddress

    const keepYourItemSelected = useAppSelector(
        (store) => store.return.keepYourItem
    )

    const callCalculate = (
        returnMethod: ReturnMethod,
        exchangeShippingAddress?: ModelsAddress
    ) => {
        if (ecommerceID && order.checkout_id) {
            const calculateData: ApiCalculateRequest = {
                checkout_id: order.checkout_id,
                drop_off_address: returnAddress,
                exchange_shipping_address: exchangeShippingAddress,
                exchange_selections: exchangeSelections,
                pickup_address: returnAddress,
                pickup_ranges: pickupRanges,
                return_method: returnMethod,
                items_to_return: returnedItems,
                user_preferred_lang: i18n.language,
                user_selected_carrier: userSelectedCarrier,
                user_selected_provider: userSelectedProvider,
                refund_method: refundPaymentMethod.method,
                keep_your_item_selected: keepYourItemSelected
            }
            dispatch(
                calculateReturn({
                    ecommerceID,
                    calculateData
                })
            )
            captureEventPosthog('Return Method', {
                ReturnMethod: ReturnMethods[returnMethod]
            })
        }
    }

    function handleReject() {
        dispatch(resetCalculate())
        console.warn(errorCalculate)
        const error = errorCalculate as AxiosError

        if (error.response?.status === 406) {
            toast({
                text: t('toast_errors.error_406'),
                variant: 'error'
            })
            return
        }

        if (error.response?.status === 401 || error.response?.status === 419) {
            toast({
                text: t('toast_errors.error_419'),
                variant: 'error'
            })
            navigate(
                `${pages.Landing}?orderNumber=${encodeURIComponent(
                    orderInfo.orderNumber || ''
                )}&email=${encodeURIComponent(orderInfo.email || '')}`
            )
            return
        }

        navigate(pages.Error)
    }

    useEffect(() => {
        if (loadingCalculate === 'succeeded') {
            dispatch(resetCalculate())
            dispatch(cleanProcess())
            navigate(pages.Confirmation)
            return
        }

        if (loadingCalculate === 'failed') {
            handleReject()
            return
        }
    }, [loadingCalculate])

    return { callCalculate, loadingCalculate: loadingCalculate === 'pending' }
}
