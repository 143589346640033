import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    ApiExchangeSelection,
    PkgInfrastructureRestItemToReturn as ApiReturnedItem,
    GithubComItsreverDomainModelsPkgModelsAddress as ModelsAddress,
    ModelsPickupRange,
    PkgInfrastructureRestRefundMethod as RestRefundMethod
} from '@itsrever/returns-api-types'
import { ReturnsProductDetailsResponse as ProductDetails } from '@itsrever/rever-api'
import {
    addNewItem as domainAddNewItem,
    NewItem,
    removeNewItem as domainRemoveNewItem,
    removeNewItems as domainRemoveNewItems,
    updateNewItemQuantity as domainUpdateNewItemQuantity
} from '@/domain/new-item'

export interface ApiExchangeSelectionFront {
    exchangeSelection: ApiExchangeSelection
    productName: string
    variantName: string
    originalItemSubproductId: number
    productDetails?: ProductDetails
}

export interface ApiReturnedItemSubproduct {
    item: ApiReturnedItem
    lineItemId: string
    subProductId: number
    action: (typeof ItemActions)[keyof typeof ItemActions]
}

export const ItemActions = {
    NoAction: 'NO_ACTION',
    ToReturn: 'TO_RETURN',
    ToExchangeOneOnOne: 'TO_EXCHANGE_ONE_ON_ONE',
    ToExchangeFullCatalog: 'TO_EXCHANGE_FULL_CATALOG'
}

export enum RefundActions {
    NoAction,
    ToExchange,
    ToReturn
}

export enum FraudScore {
    Unspecified,
    Fraud,
    NonFraud,
    NonApplicable
}

export const FraudScores = {
    [FraudScore.Unspecified]: 'UNSPECIFIED',
    [FraudScore.Fraud]: 'FRAUD',
    [FraudScore.NonFraud]: 'NON_FRAUD',
    [FraudScore.NonApplicable]: 'NON_APPLICABLE'
}

export enum FraudGroup {
    Unspecified,
    Control,
    NoControl,
    NonApplicable
}

export const FraudGroups = {
    [FraudGroup.Unspecified]: 'UNSPECIFIED',
    [FraudGroup.Control]: 'CONTROL',
    [FraudGroup.NoControl]: 'NO_CONTROL',
    [FraudGroup.NonApplicable]: 'NON_APPLICABLE'
}

export enum RefundPaymentMethod {
    NoPaymentMethod,
    BankTransfer,
    Original,
    PromoCode,
    GiftCard
}

export const RefundPaymentMethods = {
    [RefundPaymentMethod.NoPaymentMethod]: 'NO_PAYMENT_METHOD',
    [RefundPaymentMethod.BankTransfer]: 'BANK_TRANSFER',
    [RefundPaymentMethod.Original]: 'ORIGINAL_PM',
    [RefundPaymentMethod.PromoCode]: 'PROMO_CODE',
    [RefundPaymentMethod.GiftCard]: 'GIFT_CARD'
}

export enum ReturnMethod {
    NoReturnMethod,
    HomePickup,
    CollectionPoint,
    DeliveryPickup,
    InStore
}

export const ReturnMethods = {
    [ReturnMethod.NoReturnMethod]: 'NO_RETURN_METHOD',
    [ReturnMethod.HomePickup]: 'HOME_PICKUP',
    [ReturnMethod.CollectionPoint]: 'COLLECTION_POINT',
    [ReturnMethod.DeliveryPickup]: 'DELIVERY_PICKUP',
    [ReturnMethod.InStore]: 'IN_STORE'
}

export enum RefundTimings {
    NoTiming,
    OnStartReturnProcess,
    OnItemSent,
    OnItemVerified
}

export const LineItemSubtypes = {
    OrderedItem: 'ORDERED_ITEM',
    Promotion: 'PROMOTION',
    ExchangePromotion: 'EXCHANGE_PROMOTION',
    ShippingCost: 'RETURN_SHIPPING_COST',
    OriginalShipping: 'ORIGINAL_SHIPPING',
    ReturnedItem: 'RETURNED_ITEM',
    OpenExchange: 'OPEN_EXCHANGE',
    RevertedExchangePromotion: 'REVERTED_EXCHANGE_PROMOTION',
    RdvSurplus: 'RDV_SURPLUS',
    ServiceFee: 'SERVICE_FEE',
    OriginalPromotionBroken: 'ORIGINAL_PROMOTION_BROKEN'
}

// Initial State:
export interface ReturnState {
    returnedItems: ApiReturnedItemSubproduct[]
    newItems: NewItem[]
    refundPaymentMethod: RestRefundMethod
    keepYourItem: boolean
    returnMethod: ReturnMethod
    userSelectedCarrier: string
    userSelectedProvider: string
    IBAN: string
    exchangeAddress: ModelsAddress
    returnAddress: ModelsAddress
    SWIFT: string
    BBAN: string
    countryCode: string
    pickUpRanges: ModelsPickupRange[]
}
const initialState: ReturnState = {
    returnedItems: [],
    newItems: [],
    refundPaymentMethod: {
        method: RefundPaymentMethod.NoPaymentMethod
    } as RestRefundMethod,
    keepYourItem: false,
    returnMethod: ReturnMethod.NoReturnMethod,
    userSelectedCarrier: '',
    userSelectedProvider: '',
    IBAN: '',
    exchangeAddress: {},
    returnAddress: {},
    SWIFT: '',
    BBAN: '',
    countryCode: '',
    pickUpRanges: []
}

// Slice:
const returnSlice = createSlice({
    name: 'return',
    initialState,
    reducers: {
        setPickUpRanges: (state, action) => {
            state.pickUpRanges = action.payload
        },
        setReturnAddress: (state, action: PayloadAction<ModelsAddress>) => {
            state.returnAddress = action.payload
        },
        setExchangeAddress: (state, action: PayloadAction<ModelsAddress>) => {
            state.exchangeAddress = action.payload
        },
        addReturnedItem: (
            state,
            action: PayloadAction<ApiReturnedItemSubproduct>
        ) => {
            const isAlready = state.returnedItems.find((i) => {
                return (
                    i.lineItemId === action.payload.item.line_item_id &&
                    i.subProductId === action.payload.subProductId
                )
            })
            if (!isAlready) {
                state.returnedItems.push(action.payload)
            } else {
                isAlready.item.quantity = action.payload.item.quantity
                isAlready.action = action.payload.action
                isAlready.item.action = action.payload.item.action
            }
        },
        updateReturnedItem: (
            state,
            action: PayloadAction<{
                lineItemId: string
                item: ApiReturnedItem
            }>
        ) => {
            const item = state.returnedItems.find(
                (i) => i.lineItemId === action.payload.lineItemId
            )
            if (item) {
                item.item = action.payload.item
            }
        },
        removeReturnedItem: (
            state,
            action: PayloadAction<{ lineItemId: string }>
        ) => {
            state.returnedItems = state.returnedItems.filter(
                (retItem) => retItem.lineItemId !== action.payload.lineItemId
            )
        },
        clearReturnedItems: (state) => {
            state.returnedItems = []
        },
        removeNewItems: (
            state,
            action: PayloadAction<{ returnedItemId: string }>
        ) => {
            state.returnedItems = state.returnedItems.filter(
                (retItem) =>
                    retItem.lineItemId !== action.payload.returnedItemId
            )
            state.newItems = domainRemoveNewItems(
                action.payload.returnedItemId,
                state.newItems
            )
        },
        removeNewItem: (
            state,
            action: PayloadAction<{
                returnedItemId: string
                variantId: string
            }>
        ) => {
            state.newItems = domainRemoveNewItem(
                action.payload.returnedItemId,
                action.payload.variantId,
                state.newItems
            )
        },
        updateNewItemQuantity: (
            state,
            action: PayloadAction<{
                returnedItemId: string
                variantId: string
                quantity: number
            }>
        ) => {
            state.newItems = domainUpdateNewItemQuantity(
                action.payload.returnedItemId,
                action.payload.variantId,
                action.payload.quantity,
                state.newItems
            )
        },
        addNewItem: (state, action: PayloadAction<NewItem>) => {
            state.newItems = domainAddNewItem(action.payload, state.newItems)
        },
        setRefundPaymentMethod: (
            state,
            action: PayloadAction<RestRefundMethod>
        ) => {
            state.refundPaymentMethod = action.payload
        },
        setKeepYourItem: (state, action: PayloadAction<boolean>) => {
            state.keepYourItem = action.payload
        },
        setReturnMethod: (
            state,
            action: PayloadAction<{
                returnMethod: ReturnMethod
                userSelectedCarrier: string
                userSelectedProvider: string
            }>
        ) => {
            state.returnMethod = action.payload.returnMethod
            state.userSelectedCarrier = action.payload.userSelectedCarrier
            state.userSelectedProvider = action.payload.userSelectedProvider
        },
        resetReturnMethod: (state) => {
            state.returnMethod = ReturnMethod.NoReturnMethod
            state.userSelectedCarrier = ''
            state.userSelectedProvider = ''
        },
        setIBAN: (state, action: PayloadAction<string>) => {
            const iban = action.payload
                .toUpperCase()
                .replace(/\s/g, '') // remove spaces
                .replace(/[^A-Z0-9]/g, '') // remove non alphanumeric
            state.IBAN = iban.match(/.{1,4}/g)?.join(' ') ?? ''
        },
        setCountryCode: (state, action) => {
            state.countryCode = action.payload
        },
        setSWIFT: (state, action: PayloadAction<string>) => {
            const swift = action.payload
            state.SWIFT = swift
        },
        setBBAN: (state, action: PayloadAction<string>) => {
            const bban = action.payload
                .toUpperCase()
                .replace(/\s/g, '') // remove spaces
                .replace(/[^A-Z0-9]/g, '') // remove non alphanumeric
            state.BBAN = bban
        },
        resetReturn: (state) => {
            state.returnedItems = []
            state.newItems = []
            state.refundPaymentMethod = {
                method: RefundPaymentMethod.NoPaymentMethod
            } as RestRefundMethod
            state.returnMethod = ReturnMethod.NoReturnMethod
            state.keepYourItem = false
            state.userSelectedCarrier = ''
            state.userSelectedProvider = ''
            state.exchangeAddress = {}
            state.returnAddress = {}
            state.IBAN = ''
            state.SWIFT = ''
            state.BBAN = ''
            state.countryCode = ''
            state.pickUpRanges = []
        },
        resetKeepYourItem: (state) => {
            state.keepYourItem = false
        }
    }
})

export const {
    addReturnedItem,
    addNewItem,
    removeReturnedItem,
    clearReturnedItems,
    updateReturnedItem,
    removeNewItems,
    removeNewItem,
    updateNewItemQuantity,
    setRefundPaymentMethod,
    setKeepYourItem,
    setReturnMethod,
    resetReturnMethod,
    setIBAN,
    resetReturn,
    setExchangeAddress,
    setSWIFT,
    setBBAN,
    setCountryCode,
    setReturnAddress,
    setPickUpRanges,
    resetKeepYourItem
} = returnSlice.actions
export default returnSlice.reducer
