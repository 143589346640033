import flagrClient from '@/infrastructure/flagr'
import { useAppSelector } from '@/redux/hooks'
import { useEffect, useState } from 'react'

export function useFeatureFlag(flagID: number) {
    const [isFlagEnabled, setIsFlagEnabled] = useState<boolean>(false)
    const settings = useAppSelector((s) => s.settingsApi.settings.response)

    useEffect(() => {
        async function getResponse() {
            try {
                const response = await flagrClient.callFlagrFlag(flagID, {
                    group: settings.group,
                    ecommerceId: settings.ecommerce_id
                })
                setIsFlagEnabled(response)
            } catch (error) {
                setIsFlagEnabled(false)
            }
        }
        getResponse()
    }, [flagID, settings.group, settings.ecommerce_id])

    return { isFlagEnabled }
}
