import IntroduceIban from '@/components/IntroduceIban'
import { useTranslate } from '@/hooks'
import { useAppSelector } from '@/redux/hooks'
import {
    PoweredByRever,
    useTheme,
    SuccessIcon,
    ErrorIcon,
    ReverLoading
} from '@itsrever/design-system'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { changeToBankTransfer } from '@/api/processesApi'

const ChangeCompensationMethod = () => {
    const theme = useTheme()
    const { translate } = useTranslate()

    const [searchParams] = useSearchParams()
    const processId = searchParams.get('process_id') ?? ''
    const email = searchParams.get('email') ?? ''

    const IBAN = useAppSelector((store) => store.return.IBAN)
    const cleanIban = IBAN.replace(/\s/g, '')
    const SWIFT = useAppSelector((store) => store.return.SWIFT)
    const BBAN = useAppSelector((store) => store.return.BBAN).trim()
    const countryCode = useAppSelector((store) => store.return.countryCode)
    const [isFailed, setIsFailed] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const onContinue = async () => {
        setIsLoading(true)
        try {
            await changeToBankTransfer(
                processId,
                email,
                cleanIban,
                SWIFT,
                BBAN,
                countryCode
            )
            setIsSuccess(true)
            setIsFailed(false)
        } catch (error) {
            console.error(error)
            setIsSuccess(false)
            setIsFailed(true)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <MainDiv
            bgcolor={theme.colors.grey[5]}
            className="flex flex-col justify-between"
        >
            {isLoading ? (
                <div className="flex size-full">
                    <div className="m-auto">
                        <ReverLoading />
                    </div>
                </div>
            ) : (
                <>
                    {!isSuccess && !isFailed ? (
                        <ContentDiv className="px-2 py-6">
                            <h4
                                className="text-2xl"
                                dangerouslySetInnerHTML={{
                                    __html: translate(
                                        'change_compensation_method_page_title'
                                    )
                                }}
                            />
                            <p
                                className="mx-2 max-w-lg"
                                dangerouslySetInnerHTML={{
                                    __html: translate(
                                        'change_compensation_method_page_description'
                                    )
                                }}
                            />

                            <div className="mt-5">
                                <IntroduceIban onContinue={onContinue} />
                            </div>
                        </ContentDiv>
                    ) : (
                        <>
                            {isSuccess && (
                                <div className="flex size-full">
                                    <div className="m-auto">
                                        <div className="flex w-full justify-center">
                                            <SuccessIcon
                                                width={50}
                                                height={50}
                                            />
                                        </div>
                                        <p
                                            className="mt-4 text-lg text-grey-1"
                                            dangerouslySetInnerHTML={{
                                                __html: translate(
                                                    'change_compensation_method_page_success'
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            {isFailed && (
                                <div className="flex size-full">
                                    <div className="m-auto">
                                        <div className="flex w-full justify-center">
                                            <ErrorIcon width={30} height={30} />
                                        </div>
                                        <p
                                            className="mt-4 text-lg text-grey-1"
                                            dangerouslySetInnerHTML={{
                                                __html: translate(
                                                    'change_compensation_method_page_fail'
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}

            <div className="flex items-center justify-center pb-4">
                <PoweredByRever />
            </div>
        </MainDiv>
    )
}

export default ChangeCompensationMethod

const MainDiv = styled.div<{ bgcolor: string }>`
    flex-grow: 1;
    height: 100%;
`

const ContentDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
`
