import { ReturnsLineItem as ModelsLineItem } from '@itsrever/rever-api'
import {
    useTheme,
    CoinIcon,
    RepeatIcon,
    CrossIcon
} from '@itsrever/design-system'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { useState, useEffect } from 'react'
import ProductPreview from '../ProductPreview'
import {
    ItemActions,
    RefundActions,
    removeReturnedItem
} from '@/redux/return/returnSlice'
import styled from 'styled-components'
import { device } from '@/utils'
import { useNavigate, usePages, useTranslate } from '@/hooks'
import { FlowManager } from '@/components/ItemSelectionComponents'
import { SelectedExchangeItems } from './SelectedExchangeItems'
import { useUpdateCart } from '@/application/use-update-cart'

export type FlowSteps =
    | 'INITIAL'
    | 'SELECT_REASON'
    | 'SELECT_EXCHANGE_ONE_ON_ONE'
    | 'SELECT_EXCHANGE_OPEN'
    | 'FULL_CATALOG'
    | 'SUGGESTION_BY_REASON'
    | 'PRODUCT_SUGGESTIONS'

export interface OrderItemProps {
    orderItem: ModelsLineItem
    subProductId: number
}

export const OrderItem: React.FC<OrderItemProps> = ({
    orderItem,
    subProductId
}) => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const urlLineItemId = urlParams.get('lineItemId')

    const { translate } = useTranslate()
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const { clearReturnedItemCartItems } = useUpdateCart()
    const { pages, currentPage } = usePages()
    const { navigateRelative } = useNavigate()

    const returnProgress = useAppSelector((store) => store.return)
    const returnedItems = returnProgress.returnedItems
    const returnedItem = returnedItems.find(
        (retItem) =>
            retItem.item.line_item_id === orderItem.id &&
            retItem.subProductId === subProductId
    )
    const quantity = returnedItem?.item.quantity
    const isExchanged = returnedItem?.item.action === RefundActions.ToExchange
    const isExchangeFullCatalog =
        returnedItem?.action === ItemActions.ToExchangeFullCatalog
    const isSelected = returnedItem ? true : false

    const newItems = useAppSelector((store) => store.return.newItems)?.filter(
        (item) => item.returnedItemId === orderItem.id
    )

    const [isSelectionFlowOpen, setIsSelectionFlowOpen] = useState(false)
    const [defaultStep, setDefaultStep] = useState<FlowSteps>()
    const [showFullCatalogCart, setShowFullCatalogCart] = useState(false)

    const handleClickCross = () => {
        dispatch(
            removeReturnedItem({
                lineItemId: orderItem.id ?? ''
            })
        )
        setDefaultStep('SELECT_REASON')
        setShowFullCatalogCart(false)
        clearReturnedItemCartItems(orderItem.id ?? '')
    }

    const handleClose = () => {
        if (isExchangeFullCatalog && newItems.length === 0) {
            dispatch(
                removeReturnedItem({
                    lineItemId: returnedItem.lineItemId
                })
            )
        }
        setDefaultStep('SELECT_REASON')
        setShowFullCatalogCart(false)
        closeSelectionFlow()
    }

    const handleClickCard = () => {
        if (isSelected) return
        openSelectionFlow()
    }

    const onClickExchangeItems = () => {
        if (isExchangeFullCatalog) {
            setShowFullCatalogCart(true)
            setDefaultStep('FULL_CATALOG')
            openSelectionFlow()
        }
    }
    const openSelectionFlow = () => {
        setIsSelectionFlowOpen(true)
        navigateRelative(pages.ItemOptions, { lineItemId: orderItem.id ?? '' })
    }
    const closeSelectionFlow = () => {
        setIsSelectionFlowOpen(false)
        navigateRelative(pages.ItemSelection)
    }

    useEffect(() => {
        if (currentPage === 'ItemOptions' && urlLineItemId === orderItem.id) {
            setIsSelectionFlowOpen(true)
        } else if (currentPage === 'ItemSelection' && isSelectionFlowOpen) {
            handleClose()
        }
    }, [currentPage])

    return (
        <>
            <Card
                selected={isSelected}
                borderColor={theme.colors.grey[2]}
                onClick={handleClickCard}
                className="p-2 outline-1 outline-offset-0 outline-neutral-300 transition-all hover:shadow-lg hover:outline"
            >
                <ProductPreview
                    product={orderItem}
                    selectedQuantity={isSelected ? quantity : undefined}
                />

                {isSelected ? (
                    <>
                        <div
                            data-testid="selected-box"
                            className="mb-1 flex w-full grow justify-end lg:h-auto lg:content-center lg:items-center"
                        >
                            {isExchanged ? (
                                <div
                                    className={`flex shrink items-center transition-all ${
                                        isExchangeFullCatalog
                                            ? 'mr-1 mt-2 cursor-pointer rounded-lg px-2 py-1 hover:bg-neutral-100 lg:mt-0 '
                                            : ''
                                    }`}
                                    onClick={() => onClickExchangeItems()}
                                >
                                    <div className="flex items-center gap-1">
                                        <span className="shrink-0 text-xs text-neutral-400">
                                            {quantity} x
                                        </span>
                                        <span
                                            className="mr-2 max-w-28 shrink truncate text-xs text-neutral-600"
                                            dangerouslySetInnerHTML={{
                                                __html: isExchangeFullCatalog
                                                    ? orderItem.name ?? ''
                                                    : orderItem.variant_name ??
                                                      ''
                                            }}
                                        />
                                    </div>

                                    <span className="flex size-8 shrink-0 items-center justify-center rounded-full bg-neutral-100">
                                        <RepeatIcon
                                            color={theme.colors.grey[1]}
                                        />
                                    </span>

                                    {isExchangeFullCatalog ? (
                                        <div className="ml-3">
                                            <SelectedExchangeItems
                                                selectedItems={newItems}
                                            />
                                        </div>
                                    ) : (
                                        <span className="mx-2 max-w-28 shrink truncate text-xs text-neutral-600">
                                            {newItems[0]?.productVariant
                                                .variantName ?? ''}
                                        </span>
                                    )}
                                </div>
                            ) : (
                                <div className="mr-2 flex items-center gap-1">
                                    <span className="flex size-8 items-center justify-center rounded-full bg-neutral-100">
                                        <CoinIcon
                                            color={theme.colors.grey[1]}
                                        />
                                    </span>
                                    <span
                                        className="mr-2 truncate text-sm font-light text-neutral-600"
                                        dangerouslySetInnerHTML={{
                                            __html: translate('icons_return')
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        <button
                            id="crossbox"
                            color={theme.colors.grey[4]}
                            onClick={(e) => {
                                e.stopPropagation()
                                handleClickCross()
                            }}
                            className="absolute right-1 top-2 mx-1 flex size-7 shrink-0 cursor-pointer items-center justify-center rounded-md bg-white hover:bg-neutral-100 lg:relative lg:right-0 lg:top-0"
                        >
                            <CrossIcon color={theme.colors.grey[1]} />
                        </button>
                    </>
                ) : null}
            </Card>

            {isSelectionFlowOpen ? (
                <FlowManager
                    orderItem={orderItem}
                    subProductId={subProductId}
                    isOpen={isSelectionFlowOpen}
                    handleClose={handleClose}
                    defaultStep={defaultStep}
                    showFullCatalogCart={showFullCatalogCart}
                    setShowFullCatalogCart={setShowFullCatalogCart}
                />
            ) : null}
        </>
    )
}

const Card = styled.div<{ selected: boolean; borderColor: string }>`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    border: 1px solid;
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    border-color: ${(p) => (p.selected ? p.borderColor : 'transparent')};
    background-color: white;
    cursor: ${(p) => (p.selected ? 'auto' : 'pointer')};
    @media ${device.lg} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`
