import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Popover, MenuItem } from '@mui/material'
import { ChevronDownIcon, useTheme } from '@itsrever/design-system'
import { device } from '@/utils'
import styled from 'styled-components'

export const LanguageLabels = {
    en: 'English',
    es: 'Español',
    fr: 'Français',
    de: 'Deutsch',
    it: 'Italiano',
    pt: 'Português'
}

export const LanguageSwitcher = () => {
    const { i18n } = useTranslation()
    const theme = useTheme()

    const [language, setLanguage] = useState(i18n.language)
    const [menu, setMenu] = useState(null)
    const menuOpen = (event: any) => {
        setMenu(event.currentTarget)
    }
    const menuClose = () => {
        setMenu(null)
    }

    function handleChange(lang: string) {
        setLanguage(lang)
        i18n.changeLanguage(lang)
        localStorage.setItem('userPreferredLang', lang)
        menuClose()
    }

    const mainColor = theme.colors.primary.main
    const contrastText = theme.colors.primary.contrastText

    return (
        <LanguageDiv color={mainColor}>
            <div onClick={menuOpen}>
                <div className="flex items-center">
                    <span className="mr-2" style={{ color: contrastText }}>
                        {
                            LanguageLabels[
                                language as keyof typeof LanguageLabels
                            ]
                        }
                    </span>
                    <ChevronDownIcon color={contrastText} />
                </div>
            </div>
            <Popover
                data-testid="Popover"
                open={Boolean(menu)}
                onClose={menuClose}
                anchorEl={menu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                {Object.entries(LanguageLabels).map(([lang, label]) => (
                    <MenuItem key={lang} onClick={() => handleChange(lang)}>
                        <span>{label}</span>
                    </MenuItem>
                ))}
            </Popover>
        </LanguageDiv>
    )
}

export default LanguageSwitcher

interface LanguageDivProps {
    color: string
}

const LanguageDiv = styled.div<LanguageDivProps>`
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 50;
    margin-top: 1rem;
    margin-right: 1rem;
    border-radius: 0.2rem;
    border: 1px solid ${(p) => p.color};
    background-color: ${(p) => p.color};
    box-shadow: ${(p) =>
        p.color === 'transparent' ? '' : '0px 1px 4px rgba(0, 0, 0, 0.25)'};
    padding: 4px;
    @media ${device.md} {
        padding: 8px;
    }
    @media ${device.lg} {
        margin-top: 2rem;
        margin-right: 2rem;
    }
`
