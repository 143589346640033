export default function NotFoundPage() {
    return (
        <div className="flex h-full flex-col items-center justify-center">
            <h1>404</h1>
            <h4 className="mt-8">Page not found</h4>
            <p className="mt-4">
                The page you are looking for is not available!
            </p>
        </div>
    )
}
