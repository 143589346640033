import { useMediaQuery } from '@mui/material'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

interface UploadImagesProps {
    onUpload: (files: FileList) => void
    isInitial: boolean
}

export const UploadImages: React.FC<UploadImagesProps> = ({
    onUpload,
    isInitial
}) => {
    const { t } = useTranslation()
    const fileUpload = useRef<any>()
    const isMobile = useMediaQuery('(max-width:599px)')

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files
        if (files) onUpload(files)
    }
    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        const files = event.dataTransfer.files
        if (files) onUpload(files)
    }

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
    }

    const handleUpload = () => {
        if (fileUpload.current) {
            fileUpload.current.click()
        }
    }

    return (
        <>
            <div
                className="flex cursor-pointer justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-2 md:p-10"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={handleUpload}
            >
                <div className="text-center">
                    {isInitial && (
                        <div className="mb-2 flex items-center justify-center gap-3">
                            <svg
                                className="h-16 w-16 text-gray-300"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <span className="text-gray-200">
                                {t('upload_images.or')}
                            </span>
                            <svg
                                className="mb-1 h-16 w-16 text-gray-300"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                            >
                                <g fill="currentColor">
                                    <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0a2.5 2.5 0 0 1 5 0" />
                                    <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4zm.5 2a.5.5 0 1 1 0-1a.5.5 0 0 1 0 1m9 2.5a3.5 3.5 0 1 1-7 0a3.5 3.5 0 0 1 7 0" />
                                </g>
                            </svg>
                        </div>
                    )}

                    <div className="flex items-center text-sm leading-6 text-gray-600">
                        <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md bg-white font-medium text-gray-600 hover:text-gray-500"
                        >
                            <span>{t('upload_images.upload_files')}</span>
                            <input
                                ref={fileUpload}
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                className="sr-only"
                                multiple
                                onChange={handleFileChange}
                                accept="image/*"
                            />
                        </label>
                        <p className="pl-1">
                            {isMobile
                                ? ' ' + t('upload_images.existing_or_take')
                                : t('upload_images.or_drag_drop')}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
