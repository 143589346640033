import { ProductVariant } from '@/domain/product-variant'

export interface NewItem {
    returnedItemId: string
    quantity: number
    productVariant: ProductVariant
    price: number
    signedProductRaw: string
}

export function addNewItem(item: NewItem, items: NewItem[]): NewItem[] {
    return [...items, item]
}

export function removeNewItems(
    returnedItemId: string,
    items: NewItem[]
): NewItem[] {
    return items.filter((item) => item.returnedItemId !== returnedItemId)
}

export function removeNewItem(
    returnedItemId: string,
    variantId: string,
    items: NewItem[]
): NewItem[] {
    return items.filter(
        (item) =>
            !(
                item.returnedItemId === returnedItemId &&
                item.productVariant.variantId === variantId
            )
    )
}

export function updateNewItemQuantity(
    returnedItemId: string,
    variantId: string,
    quantity: number,
    items: NewItem[]
): NewItem[] {
    return items
        .map((item) => {
            if (
                item.returnedItemId === returnedItemId &&
                item.productVariant.variantId === variantId
            ) {
                if (quantity <= 0) {
                    return null
                }
                return {
                    ...item,
                    quantity: quantity
                }
            }
            return item
        })
        .filter((item) => item !== null)
}

export function totalNewItemsQuantity(items: NewItem[]): number {
    return items.reduce((acc, item) => acc + item.quantity, 0)
}
