import Resizer from 'react-image-file-resizer'

export function useResizeImage() {
    function resizeFileAndTransformToBase64(file: File): Promise<string> {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1000,
                1000,
                'JPEG',
                100,
                0,
                (uri) => {
                    resolve(uri as string)
                },
                'base64'
            )
        })
    }

    return { resizeFileAndTransformToBase64 }
}
