import { Button, ChevronLeftIcon, useTheme } from '@itsrever/design-system'
import {
    ReturnsProductDetailsResponse,
    ReturnsProductDetailsResponseVariantInfo
} from '@itsrever/rever-api'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
    formatProductPrice,
    getGridPosition
} from '@/components/FullCatalog/utils'
import { QuantitySelector } from '@/components/QuantitySelector'
import { useUpdateCart } from '@/application/use-update-cart'
import { animateCartItemAddition } from '@/components/FullCatalog/Components/Animations'
import { useCredit } from '@/hooks'
import { MobileVariantButton } from './MobileVariantButton'
import { usePostHog } from '@/application/use-posthog'
import { MobileVariantImage } from './MobileVariantImage'

interface MobileVariantSelectorProps {
    productId: string
    selectedCategoryIndex: number
    returnedItemId: string
    name: string
    description?: string
    product: ReturnsProductDetailsResponse
    onClose: () => void
    loadingDetails: boolean
    photoList: Array<string>
    mainImage: string
}

export const MobileVariantSelector: React.FC<MobileVariantSelectorProps> = ({
    productId,
    selectedCategoryIndex,
    returnedItemId,
    name,
    description,
    product,
    onClose,
    photoList,
    loadingDetails,
    mainImage
}) => {
    const { t, i18n } = useTranslation()
    const theme = useTheme()
    const { addItemToCart } = useUpdateCart()
    const lang = i18n.language.toLocaleUpperCase() ?? 'EN'
    const { captureEventPosthog } = usePostHog()
    const [selectedQuantity, setSelectedQuantity] = useState<number>(1)
    const [selectedVariantIndex, setSelectedVariantIndex] = useState<number>()
    const [displayImage, setDisplayImage] = useState<string>('')

    useEffect(() => {
        setDisplayImage(
            mainImage
                ? mainImage
                : product.product_info?.featured_image_url ?? photoList[0] ?? ''
        )
    }, [mainImage, product.product_info?.featured_image_url, photoList])

    const { totalAvailableCredit, spentCredit } = useCredit()
    const remainingCredit = totalAvailableCredit - spentCredit

    const variants = product.product_info?.variants ?? []

    const maxQuantity =
        selectedVariantIndex !== undefined
            ? variants[selectedVariantIndex]?.inventory_quantity ?? 1
            : 1

    const onChangeQuantity = (newQuantity: number) => {
        setSelectedQuantity(newQuantity)
    }

    const changeMainImage = (newImage: string) => {
        setDisplayImage(
            newImage ?? product.product_info?.featured_image_url ?? ''
        )
    }

    const onVariantClick = (
        variant: ReturnsProductDetailsResponseVariantInfo,
        index: number
    ) => {
        if (!hasStock(variant)) return
        changeMainImage((variant.image_urls && variant.image_urls[0]) ?? '')
        if (selectedVariantIndex === index) {
            setSelectedVariantIndex(undefined)
        } else {
            setSelectedVariantIndex(index)
        }
    }

    const hasStock = (variant: ReturnsProductDetailsResponseVariantInfo) => {
        return (
            !variant.is_inventory_enabled ||
            (variant?.inventory_quantity !== undefined &&
                variant?.inventory_quantity >= 1)
        )
    }

    const sortByStock = (
        a: ReturnsProductDetailsResponseVariantInfo,
        b: ReturnsProductDetailsResponseVariantInfo
    ) => {
        const aHasStock = hasStock(a)
        const bHasStock = hasStock(b)

        if (aHasStock && !bHasStock) {
            return -1
        } else if (!aHasStock && bHasStock) {
            return 1
        } else {
            return 0
        }
    }

    const onConfirmVariant = async () => {
        await animateCartItemAddition()
        const gridPosition = getGridPosition(productId)
        captureEventPosthog('Product added to cart', {
            row: gridPosition?.row,
            col: gridPosition?.col,
            categoryIndex: selectedCategoryIndex
        })
        const selectedSortedVariant = sortedVariants[selectedVariantIndex ?? 0]
        const variantIndex = variants.findIndex(
            (variant) => variant === selectedSortedVariant
        )
        addItemToCart(
            returnedItemId,
            selectedQuantity,
            variantIndex ?? 0,
            product
        )
        onClose()
    }

    const computeVariantPrice = (
        variant: ReturnsProductDetailsResponseVariantInfo
    ) => {
        return formatProductPrice(
            variant.price?.amount,
            variant.price?.currency_code,
            lang
        )
    }
    const computeVariantCreditPrice = (
        variant: ReturnsProductDetailsResponseVariantInfo
    ) => {
        const amountValue = variant.price?.amount ?? '0'
        const parsedAmount = parseFloat(amountValue)
        if (remainingCredit <= 0) {
            return ''
        }
        const restingAmount = remainingCredit < 0 ? 0 : remainingCredit
        const creditValue = parsedAmount - restingAmount
        const value = creditValue < 0 ? 0 : creditValue
        return formatProductPrice(
            value.toString(),
            variant.price?.currency_code,
            lang
        )
    }

    const sortedVariants = [...variants].sort(sortByStock)

    return (
        <>
            <div className="absolute inset-0 p-4">
                <div className="relative flex h-full min-h-0 flex-1 flex-col overflow-auto">
                    <div className="flex flex-1 flex-col overflow-y-scroll">
                        <div className="shrink-0">
                            <div className="flex">
                                <button
                                    onClick={onClose}
                                    className="mr-4 flex shrink-0 cursor-pointer items-center p-2 text-xl "
                                >
                                    <ChevronLeftIcon
                                        color={theme.colors.grey[2]}
                                    />
                                </button>

                                <h4>{name}</h4>
                            </div>
                            <p>{description}</p>
                            <hr className="my-2" />
                        </div>
                        <div className="flex shrink flex-col overflow-auto rounded-lg bg-white p-2">
                            <div className="flex w-full gap-2">
                                <img
                                    id="main-image"
                                    className="aspect-square max-h-[60vw] w-full min-w-20 max-w-[60vw] shrink rounded-lg object-cover"
                                    src={displayImage}
                                />

                                <div className="flex max-h-[60vw] flex-1 flex-col gap-2 overflow-scroll">
                                    {loadingDetails
                                        ? [...Array(3)].map((_, i) => {
                                              return (
                                                  <div
                                                      key={i}
                                                      className="relative w-full overflow-hidden rounded-lg bg-neutral-100 pb-[100%]"
                                                  />
                                              )
                                          })
                                        : photoList.map((photo, i) => {
                                              return (
                                                  <MobileVariantImage
                                                      key={i}
                                                      src={photo}
                                                      onClick={() =>
                                                          changeMainImage(photo)
                                                      }
                                                  />
                                              )
                                          })}
                                </div>
                            </div>
                            <p className="mb-1 mt-4">
                                {t('full_catalog.select_option')}
                            </p>
                            <div className="mt-2 min-h-0 shrink grow overflow-scroll">
                                {loadingDetails
                                    ? [...Array(5)].map((_, index) => (
                                          <div
                                              className="mb-2 h-14 w-full rounded border border-solid border-neutral-200 bg-neutral-50 py-1 pl-4 pr-2"
                                              key={index}
                                          />
                                      ))
                                    : sortedVariants.map((variant, i) => {
                                          return (
                                              <MobileVariantButton
                                                  key={i}
                                                  index={i}
                                                  variant={variant}
                                                  productName={name ?? ''}
                                                  hasStock={hasStock(variant)}
                                                  onVariantClick={
                                                      onVariantClick
                                                  }
                                                  selectedVariantIndex={
                                                      selectedVariantIndex
                                                  }
                                                  price={computeVariantPrice(
                                                      variant
                                                  )}
                                                  creditPrice={computeVariantCreditPrice(
                                                      variant
                                                  )}
                                              />
                                          )
                                      })}
                            </div>
                        </div>
                    </div>
                    <ButtonContainer
                        $show={selectedVariantIndex !== undefined}
                        className="flex items-center justify-center gap-4 rounded-full bg-white px-8 py-3 shadow"
                    >
                        <QuantitySelector
                            disabled={selectedVariantIndex === undefined}
                            onUpdate={onChangeQuantity}
                            min={1}
                            max={maxQuantity}
                        />
                        <Button onClick={onConfirmVariant}>
                            {t('full_catalog.confirm_variant')}
                        </Button>
                    </ButtonContainer>
                </div>
            </div>
        </>
    )
}

interface ButtonContainerProps {
    $show: boolean
}
const ButtonContainer = styled.div<ButtonContainerProps>`
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: ${(p) => (p.$show ? '20px' : '-200px')};
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
`
