import { usePostHog } from '@/application/use-posthog'
import { totalNewItemsQuantity } from '@/domain/new-item'
import { useCredit } from '@/hooks'
import { useAppSelector } from '@/redux/hooks'
import { CartIcon } from '@itsrever/design-system'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

interface CartCreditBarProps {
    setShowCartView: (show: boolean) => void
    showCompactVersion?: boolean
}

export const CartCreditBar: React.FC<CartCreditBarProps> = ({
    setShowCartView,
    showCompactVersion = false
}) => {
    const { t, i18n } = useTranslation()
    const { captureEventPosthog } = usePostHog()
    const userPreferredLang = i18n.language
    const newItems = useAppSelector((store) => store.return.newItems)
    const cartQuantity = totalNewItemsQuantity(newItems)
    const { amountToPay } = useCredit()
    const currency = useAppSelector(
        (store) => store.returnsApi.order.response.order?.money_format?.currency
    )
    const amountToPayFormatted = new Intl.NumberFormat(
        userPreferredLang ?? 'EN',
        {
            style: 'currency',
            currency,
            minimumFractionDigits: (amountToPay ?? 1) % 1 === 0 ? 0 : 2,
            maximumFractionDigits: 2
        }
    ).format(amountToPay ?? 1)

    return (
        <>
            <div
                onClick={() => {
                    captureEventPosthog('FC - Click show cart')
                    setShowCartView(true)
                }}
                className="flex items-center gap-2 rounded-full bg-radial-gradient from-brand-200 to-brand-100 shadow ring-2 ring-brand-200 transition-all hover:shadow-md"
            >
                {newItems.length > 0 ? (
                    <motion.div
                        initial={{ width: 0 }}
                        animate={{
                            width: showCompactVersion ? 0 : 'fit-content'
                        }}
                        transition={{ duration: 0.3, delay: 0.2 }}
                        className={
                            'cursor-pointer items-center gap-1 py-1 overflow-hidden pl-4 ' +
                            (showCompactVersion ? 'hidden' : 'flex')
                        }
                    >
                        <span className=" flex shrink-0 items-center gap-1 overflow-hidden text-base font-semibold text-brand-700">
                            <span className="mr-0.5 text-sm font-light">
                                {t('full_catalog.pay_only')}
                            </span>
                            <span>{amountToPayFormatted}</span>
                        </span>
                    </motion.div>
                ) : null}
                <button
                    id="cart-button"
                    className="m-1 flex cursor-pointer items-center gap-2 rounded-full bg-white px-4 py-1 shadow transition-all hover:bg-neutral-100"
                >
                    <span>
                        <CartIcon scale={0.75} />
                    </span>
                    <p>
                        <span className="mr-1">{cartQuantity}</span>
                        <span>{t('full_catalog.items')}</span>
                    </p>
                </button>
            </div>
        </>
    )
}
