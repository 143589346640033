import styled from 'styled-components'
import { Outlet } from 'react-router-dom'
import { Footer } from '@/layout/LayoutComponents'

export function PortalLayout() {
    return (
        <MainDiv>
            <Outlet />
            <Footer />
        </MainDiv>
    )
}

const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`
