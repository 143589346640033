import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { setReturnMethod, ReturnMethod } from '@/redux/return/returnSlice'
import { useTheme } from '@itsrever/design-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PkgInfrastructureRestReturnMethod as RestReturnMethod } from '@itsrever/returns-api-types'
import Sustainable from '@/assets/images/icons/sustainable.svg'
import DeliveryPickup from '@/assets/images/icons/delivery_pickup.svg'
import Dropoff from '@/assets/images/icons/dropoff.svg'
import HomePickup from '@/assets/images/icons/homepickup.svg'
import InStore from '@/assets/images/icons/instore.svg'
import Search from '@/assets/images/icons/search.svg'
import { formatCurrency, toRGB } from '@/utils'
import { useTranslate } from '@/hooks'

interface ReturnCardProps {
    method: RestReturnMethod
}

const ReturnCard: React.FC<ReturnCardProps> = ({ method }) => {
    const { t } = useTranslation()
    const { translate } = useTranslate()
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const returnMethod = useAppSelector((store) => store.return.returnMethod)
    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )

    const selected = returnMethod === method.name

    let id: string | undefined
    if (method.name) {
        id = method.name.toString()
    }

    const iconSource = () => {
        switch (method.name) {
            case ReturnMethod.CollectionPoint:
                return Dropoff
            case ReturnMethod.DeliveryPickup:
                return DeliveryPickup
            case ReturnMethod.HomePickup:
                return HomePickup
            case ReturnMethod.InStore:
                return InStore
        }
    }

    const greenOption = method.name === ReturnMethod.InStore

    const showButton =
        method.name === ReturnMethod.InStore ||
        method.name === ReturnMethod.CollectionPoint

    const href = () => {
        if (method.name === ReturnMethod.CollectionPoint) {
            return method.drop_off_point_url
        } else {
            return settings.store_list_url
        }
    }
    const isFree = parseInt(method.costs?.amount || '0') === 0
    const price = formatCurrency(
        method.costs?.amount || '0',
        method.costs?.currency || 'EUR'
    )

    return (
        <div
            id={id}
            className="relative flex flex-col content-center items-center"
            onClick={() =>
                dispatch(
                    setReturnMethod({
                        returnMethod: method.name as ReturnMethod,
                        userSelectedCarrier: method.carrier ?? '',
                        userSelectedProvider: method.provider ?? ''
                    })
                )
            }
        >
            {greenOption ? (
                <GreenOptionLabel>
                    <img src={Sustainable} />
                    <p className="text-xs">
                        <b
                            dangerouslySetInnerHTML={{
                                __html: translate('return_card_green_option')
                            }}
                        />
                    </p>
                </GreenOptionLabel>
            ) : null}
            {(isFree && method.costs) || greenOption ? (
                <ZeroCurve
                    $isGreenOption={greenOption}
                    $textColor={theme.colors.primary.contrastText}
                    $bgColor={theme.colors.primary.main}
                >
                    <p
                        className="whitespace-nowrap text-xs"
                        dangerouslySetInnerHTML={{
                            __html: translate('return_card_free')
                        }}
                    />
                </ZeroCurve>
            ) : null}
            <div className="mb-1 flex items-center">
                <Card
                    selected={selected}
                    isGreenOption={greenOption}
                    bgColor={theme.colors.primary.main}
                    col={toRGB(theme.colors.primary.main)}
                >
                    <div className="w-full">
                        <div className="mb-2 flex items-center gap-2">
                            <Icon src={iconSource()} selected={selected} />
                            <p className="text-lg">
                                <b
                                    dangerouslySetInnerHTML={{
                                        __html: translate(
                                            `return_card_method${method.name}_title`
                                        )
                                    }}
                                />
                            </p>
                        </div>
                        {method.costs ? (
                            <p className="text-orange-400">
                                <b>
                                    {t('return_card.return_fee')}
                                    {price}
                                </b>
                            </p>
                        ) : null}
                        <p
                            className="font-normal"
                            style={{
                                color: selected
                                    ? theme.colors.common.black
                                    : theme.colors.grey[0]
                            }}
                            dangerouslySetInnerHTML={{
                                __html: translate(
                                    `return_card_method${method.name}_description`
                                )
                            }}
                        />
                    </div>
                    {showButton ? (
                        <div className="mt-4 flex justify-center">
                            <ActionLink
                                bgColor={theme.colors.primary.main}
                                col={theme.colors.primary.contrastText}
                                isGreenOption={greenOption}
                                target="_blank"
                                href={href()}
                            >
                                <img src={Search} />
                                <p
                                    className="text-xs"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            method.name === ReturnMethod.InStore
                                                ? translate(
                                                      'return_card_in_store_link'
                                                  )
                                                : translate(
                                                      'return_card_dropoff_link'
                                                  )
                                    }}
                                />
                            </ActionLink>
                        </div>
                    ) : null}
                </Card>
            </div>
        </div>
    )
}

export default ReturnCard

const ActionLink = styled.a<{
    bgColor: string
    col: string
    isGreenOption: boolean
}>`
    background-color: ${(p) => (p.isGreenOption ? '#20904d' : p.bgColor)};
    color: ${(p) => (p.isGreenOption ? 'white' : p.col)};
    border-radius: 20px;
    padding: 0.2rem 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.35rem;
    :hover {
        text-decoration: underline;
    }
`

const ZeroCurve = styled.div<{
    $isGreenOption: boolean
    $textColor: string
    $bgColor: string
}>`
    color: ${(p) => (p.$isGreenOption ? 'white' : p.$textColor)};
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0px;
    height: 25px;
    padding: 0.2rem 0.4rem 0.2rem 0.75rem;
    border-top-right-radius: 0.25rem;
    border-bottom: 1px solid
        ${(p) => (p.$isGreenOption ? '#20904d' : p.$bgColor)};
    border-bottom-left-radius: 70px;
    background-color: ${(p) => (p.$isGreenOption ? '#20904d' : p.$bgColor)};
`

const Icon = styled.img<{ selected: boolean }>`
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(-30deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
    animation: ${(p) => (p.selected ? 'spin 0.5s linear' : '')};

    height: 15px;
    width: 15px;
`

const GreenOptionLabel = styled.div`
    position: absolute;
    top: -10px;
    left: 16px;
    padding: 0.1rem 0.8rem;
    border: 1px solid #20904d;
    border-radius: 20px;
    background: radial-gradient(circle at 78px 10px, #20904d, #4caf50 100%);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
`

interface CardProps {
    selected: boolean
    isGreenOption: boolean
    bgColor: string
    col: string
}

const Card = styled.div<CardProps>`
    padding: ${(p) => (p.selected ? '12px 10px' : '14px 12px')};
    cursor: pointer;
    border: ${(p) => (p.selected ? '2px' : '0px')} solid;
    border-radius: 0.25rem;
    width: 100%;
    margin-bottom: 1.5rem;
    border-color: ${(p) =>
        p.selected ? (p.isGreenOption ? '#20904d' : p.bgColor) : ''};
    background-color: ${(p) =>
        p.selected ? (p.isGreenOption ? 'rgb(0, 117, 0, 0.1)' : p.col) : ''};
    box-shadow: ${(p) =>
        p.selected ? '' : '0px 0px 2px 2px rgb(0 0 0 / 0.1)'};
`
