import { getLabelURL } from '@/api/trackingApi'
import { useEffect, useState } from 'react'
import { usePostHog } from '@/application/use-posthog'

export function useDownloadLabel(processId: string | null, email?: string) {
    const { captureEventPosthog } = usePostHog()
    const [labelURL, setLabelURL] = useState<string | null>(null)

    useEffect(() => {
        async function fetchLabelURL() {
            if (!processId || !email) return
            try {
                const return_process_identification = {
                    process_id: processId,
                    email
                }
                const response = await getLabelURL({
                    return_process_identification
                })
                setLabelURL(response.url ?? null)
            } catch (e) {
                console.warn(e)
            }
        }
        fetchLabelURL()
    }, [email, processId])

    async function downloadLabel() {
        if (!labelURL) return
        captureEventPosthog('Download Label', {
            processId
        })

        if (
            /iphone/i.test(navigator.userAgent) ||
            /ipad/i.test(navigator.userAgent)
        ) {
            window.location.href = labelURL ?? ''
        } else {
            window.open(labelURL, '_blank')
        }
    }

    return { downloadLabel, labelURL }
}
