import { LocationsApi } from '@itsrever/returns-api-types'
import { axiosInstance } from '@/redux/api/apiConfiguration'

const locationApi = new LocationsApi(undefined, undefined, axiosInstance)

export const getCountries = async (ecommerceID: string) => {
    const countries = await locationApi.countries({ ecommerceID })
    return countries.data
}

export const getProvinces = async (
    ecommerceID: string,
    countryCode: string
) => {
    const provinces = await locationApi.provinces({ ecommerceID, countryCode })
    return provinces.data.options
}
