import styled from 'styled-components'
import OPMIcon from '@/assets/images/icons/OpmIcon'
import GiftCardIcon from '@/assets/images/icons/GiftCardIcon'
import BankTransferIcon from '@/assets/images/icons/BankTransferIcon'
import { useTranslation } from 'react-i18next'
import {
    ReturnsTrackingInfoResponseRefundInfoPaymentMethodEnum,
    ReturnsTrackingInfoResponseRefundInfoStatusEnum
} from '@itsrever/rever-api'

type Props = {
    status: ReturnsTrackingInfoResponseRefundInfoStatusEnum | undefined
    paymentMethod:
        | ReturnsTrackingInfoResponseRefundInfoPaymentMethodEnum
        | undefined
    amountFormatted: string
    refundedAt?: string
}

const RefundInformation: React.FC<Props> = ({
    status,
    paymentMethod,
    amountFormatted,
    refundedAt
}) => {
    const { t } = useTranslation()

    const refunded =
        status === 'FULLY_REFUNDED' || status === 'PARTIALLY_REFUNDED'

    const paymentMethodIcon = () => {
        switch (paymentMethod) {
            case 'ORIGINAL':
                return <OPMIcon />
            case 'PROMO_CODE':
                return <GiftCardIcon />
            case 'GIFT_CARD':
                return <GiftCardIcon />
            case 'BANK_TRANSFER':
                return <BankTransferIcon />
            default:
                return <OPMIcon />
        }
    }
    return (
        <RefundInfo
            refunded={refunded}
            className={
                'z-50 relative rounded-2xl bg-gradient-to-t border border-solid  shadow-slate-400 shadow-md ' +
                (refunded
                    ? ' from-green-100 to-green-50 border-green-200'
                    : ' from-orange-100 to-orange-50 border-orange-200')
            }
        >
            <RefundLabel refunded={refunded} className="px-3 py-1">
                <p className="text-xs">
                    <span className="font-bold">
                        {refunded
                            ? t('tracking_refund.refunded')
                            : t('tracking_refund.pending_refund')}
                    </span>
                </p>
            </RefundLabel>

            <div className="flex flex-col text-slate-700">
                <RefundAmount className="text-slate-600">
                    <div
                        className={
                            'absolute -left-6 top-[50%] translate-y-[-50%] h-12 w-12  -rotate-12 overflow-hidden rounded-full border border-solid p-3 shadow-md ' +
                            (refunded
                                ? 'border-green-200 bg-green-50 text-green-700'
                                : 'border-orange-200 bg-orange-50 text-orange-700')
                        }
                    >
                        <p className="ml-[2px] mt-1 scale-150 opacity-75">
                            {paymentMethodIcon()}
                        </p>
                    </div>
                    {amountFormatted}
                </RefundAmount>
                {paymentMethod ? (
                    <p className="col-span-2 mt-2 text-xs font-medium">
                        {t(`tracking_refund.${paymentMethod}`)}
                    </p>
                ) : null}
                {(paymentMethod === 'BANK_TRANSFER' ||
                    paymentMethod === 'ORIGINAL') &&
                status === 'FULLY_REFUNDED' &&
                refundedAt ? (
                    <p className="mt-3 rounded-xl border border-solid border-green-200 bg-green-50 px-3 py-1 text-xs text-slate-600">
                        {t('tracking_refund.refunded_at')}
                        <span> {refundedAt}</span>
                    </p>
                ) : null}
            </div>
        </RefundInfo>
    )
}

export default RefundInformation

const RefundLabel = styled.div<{ refunded: boolean }>`
    position: absolute;
    top: -13px;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid ${(p) => (p.refunded ? 'rgb(69, 178, 102)' : '#f5a623')};
    border-radius: 20px;
    background: ${(p) =>
        p.refunded
            ? 'radial-gradient(circle at 78px 10px, rgb(69, 178, 102), rgb(111, 207, 140) 100%)'
            : 'radial-gradient(circle at 78px 10px, #f5a623, #f8bd5f 100%)'};
    color: white;
    white-space: nowrap;
`

const RefundInfo = styled.div<{ refunded: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    padding: 1rem 2.5rem 1rem 2.5em;
    text-align: center;
    background-color: rgba(255, 255, 255, 1);
`

const RefundAmount = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.25rem;
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
`
