import { useAppSelector } from '@/redux/hooks'
import { captureEventPosthog, isPosthogFFEnabled } from '@/services/Posthog'

export function usePostHog() {
    const isTesting = useAppSelector(
        (store) => store.settingsApi.settings.response.is_testing_account
    )

    function captureEvent(
        eventName: string,
        properties?: Record<string, unknown>
    ) {
        if (isTesting) return
        captureEventPosthog(eventName, properties)
    }

    return { captureEventPosthog: captureEvent, isPosthogFFEnabled }
}
