import { useEffect, useState } from 'react'
import { useAppSelector } from '@/redux/hooks'
import { useTranslation } from 'react-i18next'
import { toast } from '@itsrever/design-system'
import { GithubComItsreverReturnsApiPkgDomainLocationsCountry as LocationsCountry } from '@itsrever/returns-api-types'
import { useNavigate } from 'react-router-dom'
import { usePages } from '@/hooks'
import axios from 'axios'
import { getCountries } from '@/api/locationsApi'

export function useCountries(defaultCountryCode?: string) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { pages } = usePages()

    const orderInfo = useAppSelector((store) => store.order)
    const orderNumber = orderInfo.orderNumber
    const email = orderInfo.email

    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )
    const ecommerceID = settings.ecommerce_id

    const [countries, setCountries] = useState<LocationsCountry[]>()
    const [defaultCountry, setDefaultCountry] = useState<LocationsCountry>()
    const [loadingCountries, setLoading] = useState(true)

    useEffect(() => {
        async function callCountries() {
            if (!ecommerceID) return
            try {
                setLoading(true)
                const response = await getCountries(ecommerceID)

                setCountries(response)
                const defaultCountry = response.find(
                    (x) => x.iso === defaultCountryCode
                )
                setDefaultCountry(defaultCountry)
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (
                        error.response?.status === 401 ||
                        error.response?.status === 419
                    ) {
                        toast({
                            text: t('toast_errors.error_419'),
                            variant: 'error'
                        })
                        navigate(
                            `${pages.Landing}?orderNumber=${encodeURIComponent(
                                orderNumber || ''
                            )}&email=${encodeURIComponent(email || '')}`
                        )
                        return
                    }
                    toast({
                        text: t('toast_errors.country_500'),
                        variant: 'error'
                    })
                    navigate(pages.Error)
                }
            } finally {
                setLoading(false)
            }
        }

        callCountries()
    }, [
        ecommerceID,
        defaultCountryCode,
        navigate,
        orderNumber,
        email,
        t,
        pages.Landing,
        pages.Error
    ])

    return {
        countries,
        defaultCountry,
        loadingCountries
    }
}
