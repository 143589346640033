import { useTranslation } from 'react-i18next'

function ErrorPage() {
    const { i18n, t } = useTranslation()

    return (
        <div className="grid h-screen w-full place-items-center">
            <div className="flex flex-col content-center items-center ">
                <h1>{t('error_page.title')}</h1>
                <h4 className="mt-8">{t('error_page.subtitle')}</h4>
                <p className="mt-4 text-center">
                    {t('error_page.filler')}{' '}
                    <a className="underline" href="mailto:help@itsrever.com">
                        help@itsrever.com
                    </a>
                </p>
                <h6 className="mt-7">
                    <a
                        className="hover:underline"
                        href={`${
                            i18n.language === 'es'
                                ? 'https://i3ewc41prex.typeform.com/to/R7q9Q0ZP'
                                : 'https://i3ewc41prex.typeform.com/to/jXeP1WBT'
                        }`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t('error_page.form')}
                        {t('error_page.link')}
                    </a>
                </h6>
            </div>
        </div>
    )
}
export default ErrorPage
