import { configureStore } from '@reduxjs/toolkit'
import settingsApiReducer from './api/settingsApiSlice'
import returnsApiReducer from './api/returnsApiSlice'
import orderReducer from './order/orderSlice'
import returnReducer from './return/returnSlice'
import returnMethodsApiReducer from './api/returnMethodsApiSlice'
import refundMethodsApiSlice from './api/refundMethodsApiSlice'
import storeApiSlice from './api/storeApiSlice'

export const store = configureStore({
    reducer: {
        settingsApi: settingsApiReducer,
        returnsApi: returnsApiReducer,
        returnMethodsApi: returnMethodsApiReducer,
        refundMethodsApi: refundMethodsApiSlice,
        order: orderReducer,
        return: returnReducer,
        store: storeApiSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
})
if ((window as any).Cypress) {
    ;(window as any).store = store
}

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
