import { Modal, TextInput, Button } from '@itsrever/design-system'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { setTheming } from '@/redux/api/settingsApiSlice'
import { useState, useRef, useEffect } from 'react'
import { useSlug } from '@/hooks'
export interface SettingsDemoModalProps {
    isOpen: boolean
    requestClose: () => void
}

export const SettingsDemoModal: React.FC<SettingsDemoModalProps> = ({
    isOpen,
    requestClose
}) => {
    const ecommerceId = useAppSelector(
        (store) => store.settingsApi.settings.response.ecommerce_id
    )
    const slug = useSlug()
    const dispatch = useAppDispatch()
    const [logoUrl, setLogoUrl] = useState<string | undefined>(undefined)
    const [faviconUrl, setFaviconUrl] = useState<string | undefined>(undefined)
    const [backgroundImageUrl, setBackgroundImageUrl] = useState<
        string | undefined
    >(undefined)
    const [backgroundTextColor, setBackgroundTextColor] = useState<
        string | undefined
    >(undefined)
    const [title, setTitle] = useState<string | undefined>(undefined)

    const toJSON = () => {
        return {
            ...(logoUrl && { logo_url: logoUrl }),
            ...(faviconUrl && { favicon_url: faviconUrl }),
            ...(backgroundImageUrl && {
                background_image_url: backgroundImageUrl
            }),
            ...(backgroundTextColor && {
                background_text_color: backgroundTextColor
            }),
            ...(title && { title })
        }
    }

    // Download settings
    const handleDownload = () => {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(toJSON())
        )}`
        const link = document.createElement('a')
        link.href = jsonString
        link.download = `${slug}.json`

        link.click()
    }

    // Upload settings
    const [uploadedData, setUploadedData] = useState<string>('')
    useEffect(() => {
        if (uploadedData) {
            const data = JSON.parse(uploadedData)
            if (data.logoUrl) setLogoUrl(data.logoUrl)
            if (data.faviconUrl) setFaviconUrl(data.faviconUrl)
            if (data.backgroundImageUrl)
                setBackgroundImageUrl(data.backgroundImageUrl)
            if (data.backgroundTextColor)
                setBackgroundTextColor(data.backgroundTextColor)
            if (data.title) setTitle(data.title)
        }
    }, [uploadedData])

    const readFileOnUpload = (uploadedFile: File) => {
        const fileReader = new FileReader()
        fileReader.onloadend = () => {
            try {
                setUploadedData(fileReader.result as string)
            } catch (e) {
                console.warn('**Not valid JSON file!**')
            }
        }
        if (uploadedFile !== undefined) fileReader.readAsText(uploadedFile)
    }

    const fileInput = useRef<HTMLInputElement>(null)
    const handleUpload = () => {
        if (fileInput.current) fileInput.current.click()
    }

    const handleContinue = () => {
        dispatch(setTheming(toJSON()))
        requestClose()
    }

    return (
        <Modal isOpen={isOpen} onRequestClose={requestClose} closeButton={true}>
            <div className="h-fit w-full overflow-hidden px-16">
                <h4>Select the settings</h4>
                <div className="mt-4">
                    <TextInput
                        fullWidth
                        label={'Slug'}
                        placeholder={slug}
                        disabled
                    />
                </div>
                <div className="mt-0.5">
                    <TextInput
                        fullWidth
                        label={'eCommerceId'}
                        placeholder={ecommerceId}
                        disabled
                    />
                </div>
                <div className="mt-0.5">
                    <TextInput
                        fullWidth
                        label={'Logo URL'}
                        value={logoUrl}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            setLogoUrl(e.currentTarget.value)
                        }
                        autoFocus
                    />
                </div>
                <div className="mt-0.5">
                    <TextInput
                        fullWidth
                        label={'Favicon URL'}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            setFaviconUrl(e.currentTarget.value)
                        }
                        value={faviconUrl}
                    />
                </div>
                <div className="mt-0.5">
                    <TextInput
                        fullWidth
                        label={'Background Image URL'}
                        value={backgroundImageUrl}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            setBackgroundImageUrl(e.currentTarget.value)
                        }
                    />
                </div>
                <div className="mt-0.5">
                    <TextInput
                        fullWidth
                        label={'Background Text Color'}
                        value={backgroundTextColor}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            setBackgroundTextColor(e.currentTarget.value)
                        }
                    />
                </div>
                <div className="mt-0.5">
                    <TextInput
                        fullWidth
                        label={'Title'}
                        value={title}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            setTitle(e.currentTarget.value)
                        }
                    />
                </div>
                <div className="mb-2 mt-1 flex flex-row justify-between">
                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={handleDownload}
                    >
                        Download
                    </Button>
                    <Button fullWidth onClick={handleUpload}>
                        Upload
                    </Button>
                    <input
                        type="file"
                        id="file"
                        accept="application/json"
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            e.preventDefault()
                            const target = e.target as HTMLInputElement
                            if (
                                target.files &&
                                (target.files as FileList).length > 0
                            ) {
                                readFileOnUpload(target.files[0])
                            }
                        }}
                        style={{ display: 'none' }}
                        ref={fileInput}
                    />
                </div>
                <div className="my-4 flex flex-row justify-between">
                    <Button fullWidth onClick={handleContinue}>
                        Continue
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
