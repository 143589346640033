import styled from 'styled-components'
import LanguageSwitcher from '@/components/LanguageSwitcher'
import { LandingForm, TermsAndConditions } from '@/components/LandingComponents'
import { moreThan } from '@/utils'
import { useAppSelector } from '@/redux/hooks'
import { useTranslate } from '@/hooks'
import { PoweredByRever } from '@itsrever/design-system'

export const HorizontalLanding: React.FC = () => {
    const { translate } = useTranslate()

    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )
    const backgroundUrl = settings.theme?.background_image_url
    const logoUrl = settings.theme?.logo_url
    const bannerTextColor = settings.theme?.background_text_color
    return (
        <MainDiv>
            <LanguageSwitcher />
            <Banner
                className="bg-grey-2"
                data-testid="banner"
                style={{
                    backgroundImage: `url(${backgroundUrl})`
                }}
            >
                <div className="absolute bottom-2 sm:bottom-4 xl:bottom-16">
                    <div className="mb-4 h-8 md:h-16">
                        <img className="max-h-full max-w-full" src={logoUrl} />
                    </div>
                    <h4
                        style={{ color: bannerTextColor }}
                        dangerouslySetInnerHTML={{
                            __html: translate('landing_title')
                        }}
                    />
                    <p
                        className="mt-2 md:mb-4"
                        style={{ color: bannerTextColor }}
                        dangerouslySetInnerHTML={{
                            __html: translate('landing_subtitle')
                        }}
                    />
                </div>
            </Banner>
            <div className="mt-8 max-w-[280px] md:max-w-[335px]">
                <LandingForm />
                <TermsAndConditions />
            </div>
            <div className="mt-auto">
                <PoweredByRever />
            </div>
        </MainDiv>
    )
}

export default HorizontalLanding

const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-bottom: 1rem;
`

const Banner = styled.div`
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    height: 255px;
    @media ${moreThan.md} {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        height: 275px;
    }
    @media ${moreThan.lg} {
        height: 300px;
    }
    @media ${moreThan.xl} {
        height: 400px;
    }
    @media ${moreThan['2xl']} {
        height: 450px;
    }
`
