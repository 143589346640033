import { SearchIcon, useTheme } from '@itsrever/design-system'
import { useRef, useState } from 'react'
import styled from 'styled-components'

interface MobileSearchProps {
    setSearchExpanded: (expanded: boolean) => void
    value: string
    onChange: (value: string) => void
    placeholder: string
}

export const MobileSearch: React.FC<MobileSearchProps> = ({
    setSearchExpanded,
    onChange,
    placeholder,
    ...props
}) => {
    const theme = useTheme()
    const [isFocused, setIsFocused] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)
    const value = props.value
    const onClearInput = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        e.preventDefault()
        onChange('')
        setIsFocused(false)
        if (inputRef) inputRef.current?.blur()
        setSearchExpanded(false)
    }
    const onBlurInput = () => {
        setIsFocused(false)
        if (value === '') {
            setSearchExpanded(false)
        }
    }
    const onClickContainer = () => {
        if (isFocused) return
        setSearchExpanded(true)
        setIsFocused(true)
        inputRef.current?.focus()
    }
    return (
        <div
            onClick={onClickContainer}
            onBlur={onBlurInput}
            className={
                'relative flex h-10 items-center justify-center shrink-0 flex-col rounded-full border border-solid border-gray-200 bg-white outline outline-2 transition-[width] duration-300 ease-in-out ' +
                (isFocused || value ? 'w-full' : 'w-10')
            }
            style={{
                outlineColor: isFocused
                    ? theme.colors.primary.main === 'transparent'
                        ? '#000'
                        : theme.colors.primary.main
                    : 'transparent'
            }}
        >
            <span
                className={
                    'absolute left-2 flex items-center justify-center text-gray-400 '
                }
            >
                <SearchIcon scale={1.5} />
            </span>

            <SearchInput
                ref={inputRef}
                {...props}
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value)}
                className="absolute inset-x-10 inset-y-0 appearance-none text-sm text-gray-500"
                onFocus={() => setIsFocused(true)}
                onBlur={() => onBlurInput()}
            />

            {value ? (
                <div
                    id="clear-input"
                    onMouseDown={(e) => onClearInput(e)}
                    className="absolute inset-y-0 right-1 z-10 my-auto size-7 cursor-pointer rounded-full p-0 text-gray-400 hover:bg-gray-200"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path d="M12.72 13.78a.75.75 0 1 0 1.06-1.06l-2.72-2.72 2.72-2.72a.75.75 0 0 0-1.06-1.06l-2.72 2.72-2.72-2.72a.75.75 0 0 0-1.06 1.06l2.72 2.72-2.72 2.72a.75.75 0 1 0 1.06 1.06l2.72-2.72 2.72 2.72Z" />
                    </svg>
                </div>
            ) : null}
        </div>
    )
}

const SearchInput = styled.input`
    border-width: 0px;
    outline: none;
    box-shadow: none;
    outline-offset: 0px;
    &:focus {
        outline: none;
        box-shadow: none;
        outline-offset: 0px;
    }
`
