import { useAppSelector } from '@/redux/hooks'
import { getProductDetails } from '@/redux/api/storeApiSlice'
import {
    ReturnsApiProductDetailsRequest,
    ReturnsProductDetailsResponse
} from '@itsrever/rever-api'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

export function useStoreProductDetails() {
    const [loadingProductDetails, setLoadingProductDetails] = useState(false)
    const [selectedItemDetails, setSelectedItemDetails] =
        useState<ReturnsProductDetailsResponse>()
    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )
    const { i18n } = useTranslation()
    const countryCode =
        useAppSelector((store) => store.return.returnAddress.country_code) ??
        'ES'
    const language = i18n.language.toLocaleUpperCase() ?? 'EN'
    const ecommerceId = settings.ecommerce_id ?? ''

    const currency =
        useAppSelector(
            (store) => store.returnsApi.order.response?.order?.money_format
        )?.currency ?? 'unknown'

    const fetchProductDetails = async (productId: string) => {
        try {
            setSelectedItemDetails(undefined)
            setLoadingProductDetails(true)
            const details = {
                ecommerceId,
                productId,
                language,
                currency,
                countryCode
            } as ReturnsApiProductDetailsRequest
            const response = await getProductDetails(details)
            setSelectedItemDetails(response)
        } catch (error) {
            console.error('Error fetching product details', error)
        } finally {
            setLoadingProductDetails(false)
        }
    }

    return {
        fetchProductDetails,
        loadingProductDetails,
        selectedItemDetails
    }
}
