import {
    ReturnsTrackingInfoRequest,
    ReturnsApi,
    ReturnsLabelPresignedURLRequest,
    ReturnsDeliveryNotePresignedURLRequest,
    ReturnsReturnProcessIdentification
} from '@itsrever/rever-api'
import { axiosInstance } from '@/redux/api/apiConfiguration'

const returnsApi = new ReturnsApi(undefined, undefined, axiosInstance)

export async function getLabelURL(
    returnsLabelPresignedURLRequest: ReturnsLabelPresignedURLRequest
) {
    const response = await returnsApi.labelPresignedURL({
        returnsLabelPresignedURLRequest
    })
    return response.data
}

export async function getDeliveryNoteURL(
    returnsDeliveryNotePresignedURLRequest: ReturnsDeliveryNotePresignedURLRequest
) {
    const response = await returnsApi.deliveryNotePresignedURL({
        returnsDeliveryNotePresignedURLRequest
    })
    return response.data
}

export async function getInstructions(
    return_process_identification: ReturnsReturnProcessIdentification
) {
    const response = await returnsApi.instructionsPresignedURL({
        returnsInstructionsPresignedURLRequest: {
            return_process_identification
        }
    })
    return response.data
}

export async function getTrackingInfo(
    returnsTrackingInfoRequest: ReturnsTrackingInfoRequest
) {
    const response = await returnsApi.trackingInfo({
        returnsTrackingInfoRequest
    })
    return response.data
}
