import styled from 'styled-components'
import NoLabelImage from '@/assets/images/NoLabelImage'
import {
    useDownloadLabel,
    useDownloadDeliveryNote,
    useDownloadInstructions
} from '@/hooks'
import { Trans, useTranslation } from 'react-i18next'
import download from '@/assets/images/icons/download-label.svg'
import dropoff from '@/assets/images/icons/dropoff.svg'
import shedulePickup from '@/assets/images/icons/schedule-pickup.svg'
import instructions from '@/assets/images/icons/instructions.svg'
import note from '@/assets/images/icons/note.svg'
import { useAppSelector } from '@/redux/hooks'
import { useTheme } from '@itsrever/design-system'
import { TrackingHeader } from '@/components/TrackingComponents'
import { TrackingContext } from '@/layout/TrackingLayout'
import { useContext } from 'react'
import { usePostHog } from '@/application/use-posthog'

function Label() {
    const { t } = useTranslation()
    const trackingInfo = useContext(TrackingContext)
    const theme = useTheme()
    const { captureEventPosthog } = usePostHog()

    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )
    const logisticsInfo = trackingInfo.logistics_info

    const ecommerceName = settings.name

    const carrier = logisticsInfo?.carrier
    const returnMethod = logisticsInfo?.return_method
    const labelStatus = logisticsInfo?.label_status
    const dropOffUrl = logisticsInfo?.dropoff_info?.points_url
    const pickUpUrl = logisticsInfo?.pickup_info?.schedule_url
    const barCodeUrl = logisticsInfo?.in_store_info?.barcode_url
    const deliveryNoteStatus = logisticsInfo?.delivery_note_status

    const email = useAppSelector((store) => store.order.email)
    const processId = useAppSelector((store) => store.order.selectedProcessID)

    const { downloadLabel, labelURL } = useDownloadLabel(processId, email)
    const { downloadDeliveryNote, deliveryNoteURL } = useDownloadDeliveryNote(
        processId,
        email
    )
    const { downloadInstructions, instructionsURL } = useDownloadInstructions(
        processId,
        email
    )

    const handleClickNewPickup = () => {
        captureEventPosthog('Schedule New Pickup')
    }

    const handleClickDropoff = () => {
        captureEventPosthog('Find DropOff Points')
    }

    const handleClickBarcode = () => {
        captureEventPosthog('Download Barcode')
    }

    const seeInstructionsButton =
        returnMethod !== 'IN_STORE' &&
        trackingInfo.logistics_info?.instructions_status !== 'NOT_REQUIRED'

    return (
        <MainDiv bgcolor={theme.colors.grey[5]} className="text-slate-700">
            <TrackingHeader>
                <div className="flex w-full justify-center text-brand-900">
                    <div className="ml-7 w-[300px] md:ml-0">
                        <h4 className="text-2xl">
                            {t('tracking_pages.return_label')}
                        </h4>
                        <p className="mt-2">
                            {t('tracking_pages.description')}
                            {ecommerceName}
                        </p>
                    </div>
                </div>
            </TrackingHeader>
            <ContentDiv>
                <div className="z-50">
                    {labelStatus === 'PENDING' ? (
                        <>
                            <div className="mb-4 text-brand-800">
                                <NoLabelImage />
                            </div>
                            <p className="max-w-[300px] text-lg text-slate-800">
                                <Trans i18nKey="tracking_logistics.no_label" />
                            </p>
                        </>
                    ) : (
                        <div className="mt-6 flex flex-col justify-center align-middle text-slate-800">
                            <Trans>
                                <p className="max-w-[400px]">
                                    {returnMethod === 'DROP_OFF_POINT' ? (
                                        t(`tracking_logistics.drop_off`)
                                    ) : (
                                        <></>
                                    )}
                                    {returnMethod === 'HOME_PICK_UP' ? (
                                        carrier !== 'GLS' ? (
                                            <span>
                                                <span>
                                                    {t(
                                                        `tracking_logistics.pickup_1`
                                                    )}
                                                </span>
                                                {carrier === 'UPS' ? (
                                                    <span>
                                                        {t(
                                                            `tracking_logistics.pickup_ups`
                                                        )}
                                                    </span>
                                                ) : null}
                                                <span>
                                                    {t(
                                                        `tracking_logistics.pickup_2`
                                                    )}
                                                </span>
                                            </span>
                                        ) : (
                                            <>
                                                <span>
                                                    {t(
                                                        `tracking_logistics.pickup_gls`
                                                    )}
                                                </span>
                                            </>
                                        )
                                    ) : (
                                        <></>
                                    )}
                                </p>
                            </Trans>
                            {returnMethod === 'IN_STORE' &&
                            barCodeUrl !== undefined ? (
                                <img
                                    className="mb-4 mt-8 opacity-80"
                                    src={barCodeUrl}
                                />
                            ) : null}
                            <div className="mt-10 grid grid-cols-1 place-items-center gap-4">
                                {seeInstructionsButton && instructionsURL ? (
                                    <InstructionsButton
                                        key="instructions"
                                        onClick={downloadInstructions}
                                        className="rounded-xl bg-gradient-to-b from-brand-400 to-brand-500 text-white shadow-md hover:bg-brand-600"
                                    >
                                        <img src={instructions} />
                                        <p>
                                            {t(
                                                `tracking_logistics.see_instructions`
                                            )}
                                        </p>
                                    </InstructionsButton>
                                ) : null}
                                {dropOffUrl ? (
                                    <ActionButton
                                        target="_blank"
                                        href={dropOffUrl}
                                        key="dropoff"
                                        onClick={handleClickDropoff}
                                        className="rounded-xl bg-gradient-to-b from-brand-400 to-brand-500 shadow-md hover:bg-brand-600"
                                    >
                                        <Icon src={dropoff} />
                                        <p>{t(`tracking_pages.drop_off`)}</p>
                                    </ActionButton>
                                ) : null}
                                {returnMethod !== 'IN_STORE' && labelURL ? (
                                    carrier !== 'GLS' && (
                                        <ActionButton
                                            onClick={downloadLabel}
                                            className="rounded-xl bg-gradient-to-b from-brand-400 to-brand-500 shadow-md hover:bg-brand-600"
                                            key="downloadlabel"
                                        >
                                            <Icon src={download} />
                                            <p>
                                                {t(
                                                    `tracking_logistics.download_label`
                                                )}
                                            </p>
                                        </ActionButton>
                                    )
                                ) : barCodeUrl ? (
                                    <ActionButton
                                        target="_blank"
                                        href={barCodeUrl}
                                        key="downloadBarcode"
                                        download="barcode"
                                        onClick={handleClickBarcode}
                                        className="rounded-xl bg-gradient-to-b from-brand-400 to-brand-500 shadow-md hover:bg-brand-600"
                                    >
                                        <Icon src={download} />
                                        <p>
                                            {t(`tracking_pages.return_label`)}
                                        </p>
                                    </ActionButton>
                                ) : null}
                                {pickUpUrl ? (
                                    <ActionButton
                                        target="_blank"
                                        href={pickUpUrl}
                                        key="pickup"
                                        onClick={handleClickNewPickup}
                                        className="rounded-xl bg-gradient-to-b from-brand-400 to-brand-500 shadow-md hover:bg-brand-600"
                                    >
                                        <Icon src={shedulePickup} />
                                        <p>{t(`tracking_pages.pickup`)}</p>
                                    </ActionButton>
                                ) : null}
                                {deliveryNoteStatus !== 'NOT_REQUIRED' &&
                                deliveryNoteURL ? (
                                    <ActionButton
                                        onClick={downloadDeliveryNote}
                                        key="deliverynote"
                                        className="rounded-xl bg-gradient-to-b from-brand-400 to-brand-500 shadow-md hover:bg-brand-600"
                                    >
                                        <Icon src={note} />
                                        <p>
                                            {t(
                                                `tracking_logistics.delivery_note`
                                            )}
                                        </p>
                                    </ActionButton>
                                ) : null}
                            </div>
                        </div>
                    )}
                </div>
            </ContentDiv>
        </MainDiv>
    )
}

export default Label

const MainDiv = styled.div<{ bgcolor: string }>`
    flex-grow: 1;
`

const ContentDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
`
const InstructionsButton = styled.a`
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 0.75rem 1rem;
    width: 250px;
    &:hover {
        background-color: #cee0f7;
    }
    img {
        filter: brightness(0) invert(1);
        height: 22px;
        width: 22px;
        margin-right: 0.5rem;
    }
`

const ActionButton = styled.a`
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 0.75rem 1rem;
    width: 250px;
    color: white;
    &:hover {
        background-color: #2c77d9;
    }
`

const Icon = styled.img`
    height: 22px;
    width: 22px;
    margin-right: 0.5rem;
    filter: brightness(0) invert(1);
`
