import styled from 'styled-components'
import { useTheme } from '@itsrever/design-system'
import { GithubComItsreverDomainModelsPkgModelsAddress as ModelsAddress } from '@itsrever/returns-api-types'
import ReverTextField from '../ReverTextField'
import {
    isValidAddressLine,
    isValidAddressLine2,
    isValidCity,
    isValidPhone,
    isValidName,
    isValidPostcode
} from '@/utils'
import { TextField, Autocomplete } from '@mui/material'
import { useCountries, useProvinces, useTranslate } from '@/hooks'
import { Loading } from '../Loading'
import { useTranslation } from 'react-i18next'

interface AddressProps {
    addressObject: ModelsAddress
    setAddressObject: (value: ModelsAddress) => void
    nameNeeded?: boolean
}

export const AddressForm: React.FC<AddressProps> = ({
    addressObject,
    setAddressObject,
    nameNeeded = false
}) => {
    const { t } = useTranslation()
    const { translate } = useTranslate()
    const theme = useTheme()

    const { countries, defaultCountry, loadingCountries } = useCountries(
        addressObject.country_code
    )
    const { provinces, defaultProvince, loadingProvinces } = useProvinces(
        addressObject.country_code,
        addressObject.state_province_code
    )

    if (
        addressObject.country_code &&
        !addressObject.country &&
        countries &&
        countries.length
    ) {
        const country = countries?.find(
            (c) => c.iso === addressObject.country_code
        )
        setAddressObject({ ...addressObject, country: country?.nicename })
    }

    const name =
        addressObject.first_name && addressObject.last_name
            ? addressObject.first_name + addressObject.last_name
            : addressObject.first_name
            ? addressObject.first_name
            : addressObject.last_name
            ? addressObject.last_name
            : ''

    const variant = theme.inputVariant.toLowerCase()

    const autoCompleteColor =
        theme.colors.primary.main === 'transparent'
            ? '#000'
            : theme.colors.primary.main

    if (loadingCountries || loadingProvinces) return <Loading loading />
    return (
        <div className="flex flex-col content-center items-center">
            <FormBox>
                {nameNeeded && (
                    <div className="mb-4 grid grid-cols-2 gap-4">
                        <ReverTextField
                            label={translate('exchange_address_first_name')}
                            placeholder={translate(
                                'exchange_address_first_name'
                            )}
                            fullWidth
                            value={addressObject.first_name}
                            error={!isValidName(name)}
                            onChange={(e) => {
                                setAddressObject({
                                    ...addressObject,
                                    first_name: e.currentTarget.value
                                })
                            }}
                        />
                        <ReverTextField
                            label={translate('exchange_address_last_name')}
                            placeholder={translate(
                                'exchange_address_last_name'
                            )}
                            fullWidth
                            value={addressObject.last_name}
                            error={!isValidName(name)}
                            helperText={
                                isValidName(name ?? '')
                                    ? ''
                                    : translate('exchange_address_name_error')
                            }
                            onChange={(e) => {
                                setAddressObject({
                                    ...addressObject,
                                    last_name: e.currentTarget.value
                                })
                            }}
                        />
                    </div>
                )}
                <ReverTextField
                    type="tel"
                    label={translate('exchange_address_phone')}
                    placeholder={translate('exchange_address_phone')}
                    fullWidth
                    value={addressObject.phone}
                    onChange={(e) =>
                        setAddressObject({
                            ...addressObject,
                            phone: e.currentTarget.value
                        })
                    }
                    error={!isValidPhone(addressObject.phone ?? '')}
                    helperText={
                        !isValidPhone(addressObject.phone ?? '')
                            ? translate('exchange_address_phone_error')
                            : addressObject.phone &&
                              addressObject.phone.length > 0
                            ? ''
                            : addressObject.country &&
                              addressObject.country.length > 0
                            ? translate('exchange_address_helper_phone') +
                              addressObject.country
                            : translate(
                                  'exchange_address_helper_phone_no_country'
                              )
                    }
                />
                {countries && countries.length > 0 && (
                    <Autocomplete
                        className="mt-4"
                        clearIcon={''}
                        popupIcon={''}
                        id="country-select"
                        options={countries}
                        isOptionEqualToValue={(option) =>
                            option.iso === defaultCountry?.iso
                        }
                        defaultValue={defaultCountry}
                        getOptionLabel={(option) => option.nicename ?? ''}
                        onChange={(_, value) => {
                            setAddressObject({
                                ...addressObject,
                                country: value?.nicename,
                                country_code: value?.iso,
                                state_province: '',
                                state_province_code: ''
                            })
                        }}
                        ListboxProps={{
                            sx: {
                                fontFamily: 'Roobert'
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    sx: {
                                        fontFamily: 'Roobert'
                                    }
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    sx: {
                                        fontFamily: 'Roobert'
                                    }
                                }}
                                variant={
                                    variant === 'standard'
                                        ? 'standard'
                                        : 'outlined'
                                }
                                label={translate('exchange_address_country')}
                                sx={{
                                    '& label.Mui-focused': {
                                        color: autoCompleteColor
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderColor: autoCompleteColor
                                        }
                                    },
                                    '& .MuiInput-underline:before': {
                                        borderBottomColor: autoCompleteColor
                                    },
                                    '& .MuiInput-underline:after': {
                                        borderBottomColor: autoCompleteColor
                                    }
                                }}
                            />
                        )}
                    />
                )}

                {provinces && provinces?.length > 0 && (
                    <Autocomplete
                        className="mt-4"
                        clearIcon={''}
                        popupIcon={''}
                        options={provinces}
                        defaultValue={defaultProvince}
                        getOptionLabel={(option) => option.name ?? ''}
                        onChange={(_, value) =>
                            setAddressObject({
                                ...addressObject,
                                state_province: value?.name,
                                state_province_code: value?.code
                            })
                        }
                        ListboxProps={{
                            sx: {
                                fontFamily: 'Roobert'
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    sx: {
                                        fontFamily: 'Roobert'
                                    }
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    sx: {
                                        fontFamily: 'Roobert'
                                    }
                                }}
                                variant={
                                    variant === 'standard'
                                        ? 'standard'
                                        : 'outlined'
                                }
                                label={translate('exchange_address_province')}
                                sx={{
                                    '& label.Mui-focused': {
                                        color: autoCompleteColor
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderColor: autoCompleteColor
                                        }
                                    },
                                    '& .MuiInput-underline:before': {
                                        borderBottomColor: autoCompleteColor
                                    },
                                    '& .MuiInput-underline:after': {
                                        borderBottomColor: autoCompleteColor
                                    }
                                }}
                            />
                        )}
                    />
                )}

                <div className="my-4 grid grid-cols-2 gap-4">
                    <ReverTextField
                        label={translate('exchange_address_city')}
                        placeholder={translate('exchange_address_city')}
                        fullWidth
                        value={addressObject.city}
                        error={!isValidCity(addressObject.city ?? '')}
                        helperText={
                            isValidCity(addressObject.city ?? '')
                                ? ''
                                : translate('exchange_address_city_error')
                        }
                        onChange={(e) => {
                            setAddressObject({
                                ...addressObject,
                                city: e.currentTarget.value
                            })
                        }}
                    />
                    <ReverTextField
                        label={translate('exchange_address_zip')}
                        placeholder={translate('exchange_address_zip')}
                        fullWidth
                        value={addressObject.postcode}
                        onChange={(e) => {
                            setAddressObject({
                                ...addressObject,
                                postcode: e.currentTarget.value
                            })
                        }}
                        error={
                            !isValidPostcode(
                                addressObject.postcode ?? '',
                                addressObject.country_code ?? ''
                            )
                        }
                        helperText={
                            !isValidPostcode(
                                addressObject.postcode ?? '',
                                addressObject.country_code ?? ''
                            )
                                ? t('address_form.postcode_error')
                                : ''
                        }
                    />
                </div>

                <ReverTextField
                    label={translate('exchange_address_address')}
                    placeholder={translate('exchange_address_address')}
                    fullWidth
                    value={addressObject.address_line_1}
                    onChange={(e) => {
                        setAddressObject({
                            ...addressObject,
                            address_line_1: e.currentTarget.value
                        })
                    }}
                    error={
                        !isValidAddressLine(addressObject.address_line_1 ?? '')
                    }
                    helperText={
                        isValidAddressLine(addressObject.address_line_1 ?? '')
                            ? ''
                            : translate('exchange_address_addressline_error')
                    }
                />
                <div className="mt-4">
                    <ReverTextField
                        label={translate('exchange_address_address_2')}
                        placeholder={translate('exchange_address_address_2')}
                        fullWidth
                        value={addressObject.address_line_2}
                        onChange={(e) => {
                            setAddressObject({
                                ...addressObject,
                                address_line_2: e.currentTarget.value
                            })
                        }}
                        error={
                            !isValidAddressLine2(
                                addressObject.address_line_2 ?? ''
                            )
                        }
                        helperText={
                            isValidAddressLine2(
                                addressObject.address_line_2 ?? ''
                            )
                                ? ''
                                : t('address_form.address_line_2_error')
                        }
                    />
                </div>
            </FormBox>
        </div>
    )
}

export default AddressForm

const FormBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
