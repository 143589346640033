import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { FormEvent } from 'react'
import { Button } from '@itsrever/design-system'
import { useState } from 'react'
import { Loading } from '@/components/Loading'
import { useAppSelector } from '@/redux/hooks'
import { toast } from '@itsrever/design-system'
import { useTranslate, usePages } from '@/hooks'
import { notifyError } from '@/services/Sentry'
import { ItemActions, RefundActions } from '@/redux/return/returnSlice'
import { formatPrice } from '@/utils'

const StripeCheckoutForm = () => {
    const { translate } = useTranslate()
    const { pages } = usePages()
    const orderInfo = useAppSelector((store) => store.order)
    const summary = useAppSelector(
        (store) =>
            store.returnsApi.calculate.response.signed_summary?.return_summary
    )

    const returnedItems = useAppSelector((store) => store.return.returnedItems)
    const returnedItemsCount = returnedItems.reduce((acc, item) => {
        if (item.item.action === RefundActions.ToReturn)
            return acc + item.item.quantity
        return acc
    }, 0)

    const exchangedOneOnOneItemsCount = returnedItems.reduce((acc, item) => {
        if (item.action === ItemActions.ToExchangeOneOnOne)
            return acc + item.item.quantity
        return acc
    }, 0)

    const exchangedFullCatalogItemsCount = returnedItems.reduce((acc, item) => {
        if (item.action === ItemActions.ToExchangeFullCatalog)
            return acc + item.item.quantity
        return acc
    }, 0)

    const totalPaymentAmount = summary?.total_payment_amount ?? 0

    const orderMoneyFormat =
        useAppSelector(
            (store) => store.returnsApi.order.response.order?.money_format
        ) ?? {}

    const currency =
        useAppSelector(
            (store) => store.returnsApi.order.response.order?.money_format
        )?.currency ?? 'unknown'
    const baseURL = window.location.origin + pages.SuccessTracking

    const stripe = useStripe()
    const elements = useElements()

    const [message, setMessage] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        setMessage(null)
        event.preventDefault()

        if (!stripe || !elements) return
        setLoading(true)

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${baseURL}?email=${encodeURIComponent(
                    orderInfo.email || ''
                )}&orderNumber=${encodeURIComponent(
                    orderInfo.orderNumber || ''
                )}&processId=${encodeURIComponent(
                    orderInfo.selectedProcessID || ''
                )}&amount=${encodeURIComponent(
                    totalPaymentAmount
                )}&currency=${encodeURIComponent(
                    currency
                )}&returnedItemsCount=${encodeURIComponent(
                    returnedItemsCount
                )}&exchangedOneOnOneItemsCount=${encodeURIComponent(
                    exchangedOneOnOneItemsCount
                )}&exchangedFullCatalogItemsCount=${encodeURIComponent(
                    exchangedFullCatalogItemsCount
                )}`
            }
        })

        if (error) {
            console.warn(error)
            toast({
                text: error.message ?? translate('stripe_checkout_wrong'),
                variant: 'error'
            })
            setMessage(error.message ?? null)

            if (
                error.type !== 'validation_error' &&
                error.type !== 'card_error' &&
                error.type !== 'invalid_request_error'
            )
                notifyError(error)
        }
        setLoading(false)
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement />
            <div className="mt-4 flex flex-col items-center gap-4">
                {message ? <p className="text-red-400">{message}</p> : null}
                {loading ? (
                    <div className="flex justify-center">
                        <Loading loading={true} size="50px" />
                    </div>
                ) : (
                    <Button
                        id="StripeComplete"
                        disabled={!stripe || !elements || loading}
                    >
                        {translate('stripe_checkout_pay_now')} (
                        {formatPrice(totalPaymentAmount, orderMoneyFormat)})
                    </Button>
                )}
            </div>
        </form>
    )
}

export default StripeCheckoutForm
