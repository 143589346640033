import { fetchProducts, loadMoreProducts } from '@/redux/api/storeApiSlice'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
    PbshoppingMenuItem,
    StoreApiFetchProductsRequest
} from '@itsrever/rever-api'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export function useStoreProducts(
    menu: PbshoppingMenuItem[],
    selectedCategoryIndex: number,
    filterText: string,
    isMenuLoaded: boolean
) {
    const dispatch = useAppDispatch()
    const { i18n } = useTranslation()
    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )
    const exchangeFlow = useAppSelector(
        (store) => store.settingsApi.settings.response.refund?.exchange_flow
    )

    const productsCall = useAppSelector((store) => store.store.products)
    const products = productsCall.products.products ?? []
    const pageInfo = productsCall.pageInfo
    const loadingProducts = productsCall.loading
    const countryCode =
        useAppSelector((store) => store.return.returnAddress.country_code) ??
        'ES'
    const orderCurrency =
        useAppSelector(
            (store) => store.returnsApi.order.response?.order?.money_format
        )?.currency ?? 'unknown'
    const language = i18n.language.toLocaleUpperCase() ?? 'EN'
    const ecommerceId = settings.ecommerce_id ?? ''

    const resourceId = filterText
        ? ''
        : menu[selectedCategoryIndex]?.resource_id ?? ''
    const resourceType = filterText
        ? ''
        : menu[selectedCategoryIndex]?.resource_type ?? ''

    const loadNextPage = () => {
        if (pageInfo.has_next_page === false) return
        let fetchProductsParams: StoreApiFetchProductsRequest = {
            ecommerceId,
            resourceId,
            resourceType,
            language,
            countryCode,
            orderCurrency,
            pageInfoPageSize: 20
        }
        if (filterText !== '') {
            fetchProductsParams = {
                ...fetchProductsParams,
                filterText
            }
        }
        dispatch(
            loadMoreProducts({
                ...fetchProductsParams,
                pageInfoStartCursor: pageInfo.end_cursor ?? ''
            })
        )
    }

    useEffect(() => {
        if (!isMenuLoaded) return
        if (exchangeFlow !== 'FULL') return
        const fetchStoreProducts = async () => {
            let fetchProductsParams: StoreApiFetchProductsRequest = {
                ecommerceId,
                resourceId,
                resourceType,
                language,
                countryCode,
                orderCurrency,
                pageInfoPageSize: 20
            }
            if (filterText !== '') {
                fetchProductsParams = {
                    ...fetchProductsParams,
                    filterText
                }
            }
            dispatch(fetchProducts(fetchProductsParams))
        }

        const timeoutId = setTimeout(fetchStoreProducts, 300)
        return () => clearTimeout(timeoutId)
    }, [
        selectedCategoryIndex,
        filterText,
        isMenuLoaded,
        ecommerceId,
        resourceId,
        resourceType,
        language,
        countryCode,
        orderCurrency,
        exchangeFlow,
        dispatch
    ])

    return {
        products,
        pageInfo,
        loadNextPage,
        isLoadingProducts: loadingProducts === 'pending'
    }
}
