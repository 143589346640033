import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AppLayout, PortalLayout, TrackingLayout } from '@/layout'
import {
    Landing,
    ItemSelection,
    RefundMethod,
    ReturnMethod,
    Confirmation,
    SuccessTracking,
    NotFound,
    Error,
    Redirect,
    ReturnAddress,
    ExchangeAddress,
    Tracking,
    Label,
    PickUp,
    ReturnDetails,
    OrderTracking,
    ProcessSelectionPage,
    TrackingUploadDocuments,
    ChangeCompensationMethod
} from '@/pages'
import domains from './domains'

export default function App() {
    const isCustomDomain = domains[window.location.hostname] !== undefined

    const basePath = isCustomDomain ? '/' : '/:slug/'
    const landingPath = isCustomDomain ? undefined : ''
    const landingIndex = isCustomDomain ? true : undefined

    return (
        <BrowserRouter>
            <Routes>
                <Route path={basePath} element={<AppLayout />}>
                    <Route
                        path={landingPath}
                        index={landingIndex}
                        element={<Landing />}
                    />

                    <Route path="tracking" element={<TrackingLayout />}>
                        <Route index element={<Tracking />} />
                        <Route path="return-label" element={<Label />} />
                        <Route path="schedule-pickup" element={<PickUp />} />
                        <Route path="drop-off" element={<Label />} />
                        <Route
                            path="return-details"
                            element={<ReturnDetails />}
                        />
                        <Route
                            path="documents"
                            element={<TrackingUploadDocuments />}
                        />
                    </Route>

                    <Route path="order-tracking" element={<OrderTracking />} />
                    <Route path="select" element={<ProcessSelectionPage />} />
                    <Route
                        path="change-of-compensation-opm-bt"
                        element={<ChangeCompensationMethod />}
                    />
                    <Route element={<PortalLayout />}>
                        <Route path="order" element={<ItemSelection />}>
                            <Route path="item-options" element={null} />
                        </Route>
                        <Route
                            path="return-address"
                            element={<ReturnAddress />}
                        />
                        <Route
                            path="refund-method"
                            element={<RefundMethod />}
                        />
                        <Route
                            path="return-method"
                            element={<ReturnMethod />}
                        />
                        <Route
                            path="exchange-address"
                            element={<ExchangeAddress />}
                        />
                        <Route path="confirm" element={<Confirmation />} />
                        <Route
                            path="success-tracking"
                            element={<SuccessTracking />}
                        />
                    </Route>
                    <Route path="error" element={<Error />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="" element={<Redirect />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}
