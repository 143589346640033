import { useRef, useState } from 'react'
import ReverTextField from '@/components/ReverTextField'
import { useOrder, useTranslate } from '@/hooks'
import { Button } from '@itsrever/design-system'
import { useAppSelector } from '@/redux/hooks'
import { useSearchParams } from 'react-router-dom'
import { LoadingModal } from '@/components/Loading'
import { PbECommerceSettingsUseCaseEnum } from '@itsrever/rever-api'
import { useOrderLite } from '@/hooks/useOrderLite'
import { validateOrderParams } from '../validateForm'
export const LandingForm: React.FC = () => {
    const { translate } = useTranslate()

    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )
    const isReverLite =
        settings.use_case === PbECommerceSettingsUseCaseEnum.Lite

    const [searchParams] = useSearchParams()
    const preSelectedOrderNumber = searchParams.get('orderNumber')
    const preSelectedEmail = searchParams.get('email')

    const email = useRef<HTMLInputElement>(null)
    const orderNumber = useRef<HTMLInputElement>(null)
    const [errorOrder, setErrorOrder] = useState(false)
    const [errorEmail, setErrorEmail] = useState(false)
    const { callOrder, loadingOrder } = useOrder(undefined, setErrorOrder)
    const { handleStartLiteOrder } = useOrderLite()

    const handleStartReturn = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const customerPrintedOrderId = orderNumber.current?.value?.trim() ?? ''
        const orderEmail = email.current?.value?.trim() ?? ''
        const error = validateOrderParams(customerPrintedOrderId, orderEmail)
        if (error === 'emailError') {
            setErrorEmail(true)
            return
        }
        if (error === 'orderNumberError') {
            setErrorOrder(true)
            return
        }

        if (isReverLite) {
            handleStartLiteOrder(customerPrintedOrderId, orderEmail)
        } else {
            callOrder(customerPrintedOrderId, orderEmail)
        }
    }

    const exampleEmail = `${translate('landing_email_example')}@${
        settings.slug
    }.com`

    return (
        <>
            {loadingOrder ? <LoadingModal loading /> : null}
            <form onSubmit={handleStartReturn} className="flex flex-col gap-4">
                <ReverTextField
                    data-testid="orderNumber"
                    ref={orderNumber}
                    label={translate('landing_numorder')}
                    placeholder="X01234"
                    defaultValue={preSelectedOrderNumber}
                    fullWidth
                    error={errorOrder}
                    helperText={
                        errorOrder
                            ? translate('landing_order_number_error')
                            : ''
                    }
                    autoFocus
                />
                <ReverTextField
                    data-testid="email"
                    ref={email}
                    label={translate('landing_email')}
                    placeholder={exampleEmail}
                    defaultValue={preSelectedEmail}
                    fullWidth
                    error={errorEmail}
                    helperText={
                        errorEmail ? translate('landing_email_error') : ''
                    }
                />
                <Button type="submit" fullWidth>
                    {translate('landing_button_continue')}
                </Button>
            </form>
        </>
    )
}
