import { usePages } from '@/hooks'
import { ChevronLeftIcon } from '@itsrever/design-system'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

interface TrackingHeaderProps {
    children?: React.ReactNode
    showBackButton?: boolean
    backButtonRedirectPage?: string
}

export const TrackingHeader: React.FC<TrackingHeaderProps> = ({
    children,
    showBackButton = true,
    backButtonRedirectPage
}) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { pages } = usePages()
    const onClickBackButton = () => {
        if (backButtonRedirectPage) {
            navigate(backButtonRedirectPage)
        } else {
            navigate(pages.Tracking)
        }
    }

    return (
        <div className="w-full overflow-x-hidden">
            <div className="ml-[-50%] flex w-[200%] content-center overflow-hidden rounded-b-[100%] bg-gradient-to-t from-brand-50 via-brand-100 to-brand-200 p-4">
                {showBackButton ? (
                    <Back
                        id="back-button"
                        className="z-50 transition-all duration-100 ease-in-out hover:bg-brand-200"
                        onClick={() => onClickBackButton()}
                    >
                        <ChevronLeftIcon
                            color={'var(--brand-900)'}
                            className="my-auto"
                        />
                        <BackText className="ml-2 text-start text-xl text-brand-900">
                            <b>{t('tracking_layout.back')}</b>
                        </BackText>
                    </Back>
                ) : null}
                {children}
            </div>
        </div>
    )
}

const BackText = styled.p`
    @media (max-width: 599px) {
        display: none;
    }
`
const Back = styled.div`
    display: flex;
    height: fit-content;
    width: fit-content;
    cursor: pointer;
    align-items: center;
    align-content: center;
    border-radius: 5px;
    padding: 0.5rem 0.75rem;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 1rem;
    margin-left: 0.5rem;
`
