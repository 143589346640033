import {
    GithubComItsreverReturnsApiPkgDomainSummaryCost as SummaryCost,
    GithubComItsreverReturnsApiPkgDomainSummaryRetItem as SummaryRetItem
} from '@itsrever/returns-api-types'
import {
    ReturnsCalculatedSummaryCost,
    ReturnsCalculatedSummaryReturnedItem
} from '@itsrever/rever-api'
import { formatPrice } from '@/utils'
import { useTranslate } from '@/hooks'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useAppSelector } from '@/redux/hooks'
import { createAvatar } from '@dicebear/core'
import { initials } from '@dicebear/collection'

interface SummaryLiteProps {
    returnedItems: SummaryRetItem[] | ReturnsCalculatedSummaryReturnedItem[]
    costs: SummaryCost[] | ReturnsCalculatedSummaryCost[]
}

export const SummaryLite: React.FC<SummaryLiteProps> = ({
    returnedItems,
    costs
}) => {
    const { translate } = useTranslate()
    const { t } = useTranslation()

    const orderMoneyFormat =
        useAppSelector(
            (store) => store.returnsApi.order.response?.order?.money_format
        ) ?? {}

    return (
        <div>
            <div className="border-b border-solid border-grey-3">
                <p
                    className="mb-4"
                    dangerouslySetInnerHTML={{
                        __html: translate('summary_returned_products')
                    }}
                />
                {returnedItems.length > 0
                    ? returnedItems.map((item, i) => {
                          const avatar = createAvatar(initials, {
                              seed: item.name ?? '',
                              chars: 1
                          })
                          const dataUri = avatar.toDataUri()
                          return (
                              <div
                                  key={i}
                                  className="mb-4 border-t border-solid border-grey-3 pt-4"
                              >
                                  <div className="flex items-center gap-3">
                                      <img
                                          className="size-12 rounded-lg"
                                          src={dataUri}
                                      />
                                      <div className="flex w-full flex-col">
                                          <p className="text-base">
                                              {item.name ?? ''}
                                          </p>
                                          {item.quantity && (
                                              <div>
                                                  <span
                                                      className="text-xs text-grey-2"
                                                      dangerouslySetInnerHTML={{
                                                          __html: translate(
                                                              'product_preview_quantity'
                                                          )
                                                      }}
                                                  />
                                                  <span className="text-xs">
                                                      {item.quantity}
                                                  </span>
                                              </div>
                                          )}
                                      </div>
                                  </div>
                                  <span className="text-xs text-grey-2">
                                      {translate('return_summary_reason') +
                                          ' ' +
                                          translate('return_summary_refund') +
                                          ' '}
                                  </span>
                                  {item.return_reason && (
                                      <span
                                          className="text-xs text-common-black"
                                          dangerouslySetInnerHTML={{
                                              __html: translate(
                                                  `select_reason_${item.return_reason}`
                                              )
                                          }}
                                      />
                                  )}
                              </div>
                          )
                      })
                    : null}
            </div>
            {costs.length > 0 && (
                <>
                    <SummaryLine className="mb-4 mt-6">
                        <p>
                            <b>
                                {costs[0].name
                                    ? costs[0].name
                                    : t(`cost_type.${costs[0].subtype}`) ?? ''}
                            </b>
                        </p>
                        <PriceText>
                            <b>
                                {' '}
                                {formatPrice(
                                    Number(costs[0].total ?? 0),
                                    orderMoneyFormat
                                )}
                            </b>
                        </PriceText>
                    </SummaryLine>
                    <div className="mb-3 text-grey-1">
                        <p>{t('rever_lite.summary_description1')}</p>
                    </div>
                </>
            )}
        </div>
    )
}

const SummaryLine = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
`

const PriceText = styled.p`
    white-space: nowrap;
`
