const DownloadLabelIcon = () => (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            d="M5.5 5.75c0-.69.56-1.25 1.25-1.25h2.75v3.25c0 .966.784 1.75 1.75 1.75h3.25v.75a.75.75 0 0 0 1.5 0v-1.5a.75.75 0 0 0-.22-.53l-5-5a.75.75 0 0 0-.53-.22h-3.5a2.75 2.75 0 0 0-2.75 2.75v8.5a2.75 2.75 0 0 0 2.75 2.75h3.25a.75.75 0 0 0 0-1.5h-3.25c-.69 0-1.25-.56-1.25-1.25v-8.5Zm7.94 2.25-2.44-2.44v2.19c0 .138.112.25.25.25h2.19Z"
            fill="currentColor"
        />
        <path
            d="M15 12.5a.75.75 0 0 0-1.5 0v2.94l-.72-.72a.75.75 0 1 0-1.06 1.06l2 2a.75.75 0 0 0 1.06 0l2-2a.75.75 0 1 0-1.06-1.06l-.72.72v-2.94Z"
            fill="currentColor"
        />
    </svg>
)
export default DownloadLabelIcon
