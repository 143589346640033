export const ExchangeFlows = {
    None: 'EXCHANGE_FLOW_NONE',
    Open: 'EXCHANGE_FLOW_OPEN',
    OpenWithGiftCard: 'EXCHANGE_FLOW_OPEN_WITH_GIFT_CARD',
    OneOnOne: 'EXCHANGE_FLOW_ONE_ON_ONE',
    Full: 'EXCHANGE_FLOW_FULL'
} as const

export interface ProductLite {
    name: string
    reason: string
    quantity: number
}
