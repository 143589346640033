import { isValidEmail } from '@/utils'

type orderError = 'emailError' | 'orderNumberError'

export const validateOrderParams = (
    orderNumber: string,
    email: string
): orderError | undefined => {
    if (!orderNumber) {
        return 'orderNumberError'
    }
    if (!email || !isValidEmail(email)) {
        return 'emailError'
    }
}
