const DropOffIcon = () => (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            d="M10 10.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm0-1.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            d="M4.25 8.227c0-3.166 2.578-5.727 5.75-5.727s5.75 2.56 5.75 5.727c0 2.699-1.128 4.985-2.34 6.581-.341.449-.692.848-1.033 1.192h1.873a.75.75 0 0 1 0 1.5h-8.5a.75.75 0 0 1 0-1.5h1.873a11.997 11.997 0 0 1-1.033-1.192c-1.212-1.596-2.34-3.882-2.34-6.581Zm5.75-4.227c-2.35 0-4.25 1.896-4.25 4.227 0 2.277.955 4.253 2.035 5.674a9.605 9.605 0 0 0 1.554 1.634c.23.186.424.316.569.396.038.021.068.036.092.047.024-.01.054-.026.092-.047.145-.08.34-.21.57-.396a9.606 9.606 0 0 0 1.553-1.634c1.08-1.421 2.035-3.397 2.035-5.674 0-2.331-1.9-4.227-4.25-4.227Z"
            fill="currentColor"
        />
    </svg>
)
export default DropOffIcon
