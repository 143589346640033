import styled from 'styled-components'
import { device } from '@/utils'

const ProgressBarReturn = () => {
    return (
        <SVGDiv>
            <svg
                width="100%"
                height="100%"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 280 16"
            >
                <path stroke="#E5E5E5" d="M104 7.5h70M178 7.5h68" />
                <circle cx={176} cy={8} r={8} fill="#000" />
                <circle cx={176} cy={8} r={3} fill="#fff" />
                <circle cx={248} cy={8} r={3} fill="#B3B3B3" />
                <path stroke="#E5E5E5" d="M31 7.5h73" />
                <circle cx={104} cy={8} r={8} fill="#000" />
                <path d="m100 8 3 3 5-6" stroke="#fff" strokeWidth={2} />
                <circle cx={32} cy={8} r={8} fill="#000" />
                <path d="m28 8 3 3 5-6" stroke="#fff" strokeWidth={2} />
            </svg>
        </SVGDiv>
    )
}

const SVGDiv = styled.svg`
    // Over lg
    width: 280px;
    height: 24px;
    @media ${device.lg} {
        width: 400px;
        height: 36px;
    }
`

export default ProgressBarReturn
