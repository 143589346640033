import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from '@itsrever/design-system'
import { useSearchParams } from 'react-router-dom'
import { setEmail, setSelectedProcessID } from '@/redux/order/orderSlice'
import { useNavigate } from 'react-router-dom'
import { usePages, useOrder } from '@/hooks'
import { ReturnsTrackingInfoResponse } from '@itsrever/rever-api'
import { getTrackingInfo } from '@/api/trackingApi'

export function useTrackingInfo() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { pages } = usePages()

    const [searchParams] = useSearchParams()
    const paramProcessId = searchParams.get('processId')
    const paramEmail = searchParams.get('email')

    const [trackingInfo, setTrackingInfo] =
        useState<ReturnsTrackingInfoResponse>()
    const [loadingTrackingInfo, setLoading] = useState(false)

    const order = useAppSelector((store) => store.order)
    const inputEmail = order.email
    const inputProcessId = order.selectedProcessID

    const email = paramEmail ?? inputEmail
    const process_id = paramProcessId ?? inputProcessId

    const returnsOrder = useAppSelector(
        (store) => store.returnsApi.order.response
    )

    const { callOrder } = useOrder(true)

    useEffect(() => {
        if (!paramProcessId || !paramEmail) return
        if (!inputEmail) dispatch(setEmail(paramEmail))
        if (!inputProcessId) dispatch(setSelectedProcessID(paramProcessId))
    }, [paramProcessId, paramEmail])

    useEffect(() => {
        async function callGetTrackingInfo() {
            if (!email || !process_id) return
            try {
                setLoading(true)
                const return_process_identification = { email, process_id }
                const response = await getTrackingInfo({
                    return_process_identification
                })
                setTrackingInfo(response)
                if (!returnsOrder.order)
                    callOrder(response.customer_printed_order_id ?? '', email)
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response?.status === 404) {
                        toast({
                            text: 'Return not found!',
                            variant: 'error'
                        })
                        navigate(
                            `${pages.Landing}?email=${encodeURIComponent(
                                email || ''
                            )}`
                        )
                        return
                    }
                    navigate(pages.Error)
                    return
                }
            } finally {
                setLoading(false)
            }
        }

        if (!email || !process_id) {
            toast({
                text: 'Return not found',
                variant: 'error'
            })
            navigate(pages.Landing)
            return
        } else callGetTrackingInfo()
    }, [email, process_id])

    return {
        trackingInfo,
        loadingTrackingInfo
    }
}
