const domains: Record<string, string> = {
    'returns.misako.com': 'misako-shopify',
    'returns.ballester.me': 'partner-with-payments',
    'returns.thehoffbrand.com': 'thehoffbrand',
    'returns.burritoblanco.com': 'burritoblanco',
    'returns.sansarushop.com': 'sansaru',
    'returns.alvaromoreno.com': 'alvaromoreno'
}

export default domains
