import { axiosInstance } from '@/redux/api/apiConfiguration'
import {
    PbUploadReturnDocumentsRequestDocument,
    ProcessesApi
} from '@itsrever/rever-api'

const processesApi = new ProcessesApi(undefined, undefined, axiosInstance)

export async function uploadDocuments(
    processId: string,
    documents: PbUploadReturnDocumentsRequestDocument[]
) {
    const response = await processesApi.uploadReturnDocuments({
        processId,
        pbUploadReturnDocumentsRequest: {
            process_id: processId,
            documents
        }
    })
    return response.data
}

export async function changeToBankTransfer(
    processId: string,
    email: string,
    iban?: string,
    swift?: string,
    bban?: string,
    bankAccountCountry?: string
) {
    const response = await processesApi.changeToBankTransfer({
        processId,
        email,
        iban,
        swift,
        bban,
        bankAccountCountry
    })
    return response.data
}
