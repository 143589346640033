import { getDeliveryNoteURL } from '@/api/trackingApi'
import { useEffect, useState } from 'react'
import { usePostHog } from '@/application/use-posthog'

export function useDownloadDeliveryNote(
    processId: string | null,
    email?: string
) {
    const { captureEventPosthog } = usePostHog()
    const [deliveryNoteURL, setDeliveryNoteURL] = useState<string | null>(null)

    useEffect(() => {
        async function fetchDeliveryNoteURL() {
            if (!processId || !email) return
            try {
                const return_process_identification = {
                    process_id: processId,
                    email
                }
                const response = await getDeliveryNoteURL({
                    return_process_identification
                })
                setDeliveryNoteURL(response.url ?? null)
            } catch (e) {
                console.warn(e)
            }
        }
        fetchDeliveryNoteURL()
    }, [email, processId])

    async function downloadDeliveryNote() {
        if (!deliveryNoteURL) return
        captureEventPosthog('Download Delivery Note', {
            processId
        })

        if (
            /iphone/i.test(navigator.userAgent) ||
            /ipad/i.test(navigator.userAgent)
        ) {
            window.location.href = deliveryNoteURL ?? ''
        } else {
            window.open(deliveryNoteURL, '_blank')
        }
    }

    return { downloadDeliveryNote, deliveryNoteURL }
}
