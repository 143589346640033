import { GithubComItsreverReturnsApiPkgDomainSummaryRetItem as SummaryRetItem } from '@itsrever/returns-api-types'
import styled from 'styled-components'
import { useAppSelector } from '@/redux/hooks'
import { formatPrice } from '@/utils'
import { ReturnedItem } from '@/components/ReturnedItem'
import { ReturnsCalculatedSummaryReturnedItem } from '@itsrever/rever-api'
import { useTranslate } from '@/hooks'
import { RefundActions } from '@/redux/return/returnSlice'

export interface ReturnProductSummaryProps {
    lineItem: SummaryRetItem | ReturnsCalculatedSummaryReturnedItem
}

export const ReturnProductSummary: React.FC<ReturnProductSummaryProps> = ({
    lineItem
}) => {
    const { translate } = useTranslate()

    const exchangedItems = useAppSelector(
        (store) => store.return.returnedItems
    ).filter((item) => item.item.action === RefundActions.ToExchange)

    const itemToExchange = exchangedItems.find(
        (exItem) => exItem.lineItemId === lineItem.id
    )?.item

    const orderMoneyFormat =
        useAppSelector(
            (store) => store.returnsApi.order.response?.order?.money_format
        ) ?? {}

    return (
        <MainDiv>
            <ItemDiv>
                <ReturnedItem lineItem={lineItem} />
                <p className="whitespace-nowrap">
                    {formatPrice(
                        Number(lineItem.total ?? 0) ?? 0,
                        orderMoneyFormat
                    )}
                </p>
            </ItemDiv>
            <div className="mb-4">
                <span className="text-xs text-grey-2">
                    {translate('return_summary_reason') +
                        ' ' +
                        (itemToExchange
                            ? translate('return_summary_exchange')
                            : translate('return_summary_refund')) +
                        ' '}
                </span>
                {lineItem.return_reason && (
                    <span
                        className="text-xs text-common-black"
                        dangerouslySetInnerHTML={{
                            __html: translate(
                                `select_reason_${lineItem.return_reason}`
                            )
                        }}
                    />
                )}
            </div>
        </MainDiv>
    )
}

export default ReturnProductSummary

const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgb(179 179 179 / 1);
    padding-top: 1rem;
`

const ItemDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.3rem;
`
