import ProductCard from '@/components/FullCatalog/Components/ProductCard'
import { useTranslation } from 'react-i18next'
import {
    formatProductPrice,
    variantsHaveSamePrices
} from '@/components/FullCatalog/utils'
import { Loading } from '@/components/Loading'
import { useAppSelector } from '@/redux/hooks'
import { usePostHog } from '@/application/use-posthog'

interface ProductSuggestionsProps {
    handleContinue: () => void
    handleClickFullCatalog: () => void
}

export const ProductSuggestions: React.FC<ProductSuggestionsProps> = ({
    handleContinue,
    handleClickFullCatalog
}) => {
    const { t, i18n } = useTranslation()
    const lang = i18n.language.toLocaleUpperCase() ?? 'EN'
    const { captureEventPosthog } = usePostHog()

    const productsCall = useAppSelector((store) => store.store.products)
    const products = productsCall.products.products ?? []
    const isLoadingProducts = productsCall.loading === 'pending'

    const getRandomProducts = (defaultAmount?: number) => {
        const amount = defaultAmount ?? 3
        if (products?.length === 0) return []
        const totalProducts = products?.length ?? 0
        if (totalProducts > amount) {
            const prods = [...products]
            for (let i = prods.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1))
                ;[prods[i], prods[j]] = [prods[j], prods[i]]
            }
            return prods.slice(0, amount)
        } else {
            return products
        }
    }
    const getBlurryProducts = (defaultAmount?: number) => {
        const amount = defaultAmount ?? 4
        const totalProducts = products?.length ?? 0
        if (totalProducts > amount) {
            return (products ?? []).slice(0, amount)
        } else {
            return products
        }
    }
    const onClickFullCatalog = () => {
        captureEventPosthog('Click FC from product suggestions')
        handleClickFullCatalog()
    }

    return (
        <div className="h-fit w-full min-w-[250px] overflow-x-hidden px-2 sm:min-w-[280px]">
            <div className="h-fit min-h-[500px] w-full overflow-x-hidden px-2">
                <div className="mx-auto max-w-[400px]">
                    <p className="mt-6 text-base text-slate-500">
                        {t('product_suggestions.title')}
                    </p>
                    <p className="text-lg font-bold text-slate-700">
                        {t('product_suggestions.subtitle')}
                    </p>
                </div>
                {isLoadingProducts ? (
                    <Loading loading />
                ) : (
                    <div className="mx-auto my-5 grid max-w-[400px] grid-cols-2 gap-10">
                        {getRandomProducts().map((product, index) => {
                            return (
                                <ProductCard
                                    id={product.id ?? ''}
                                    key={index}
                                    onProductClick={onClickFullCatalog}
                                    name={product.title ?? ''}
                                    imageUrl={product.featured_image_url ?? ''}
                                    description={product.description ?? ''}
                                    price={formatProductPrice(
                                        product.price_range?.min?.amount,
                                        product.price_range?.min?.currency,
                                        lang
                                    )}
                                    isFrom={
                                        !variantsHaveSamePrices(
                                            product?.price_range
                                        )
                                    }
                                />
                            )
                        })}

                        <div
                            className="mb-20 flex cursor-pointer flex-col items-center"
                            onClick={onClickFullCatalog}
                        >
                            <div className="group relative my-auto flex items-center justify-center rounded-lg font-bold text-slate-700">
                                <p className="absolute z-10 rounded-lg bg-white p-2 shadow-lg transition-all group-hover:shadow-xl">
                                    {t('product_suggestions.explore_more')}
                                </p>
                                <div className="grid grid-cols-2 gap-4">
                                    {getBlurryProducts().map(
                                        (product, index) => (
                                            <img
                                                key={index}
                                                className="size-16 rounded-lg object-cover blur-sm transition-all duration-300 group-hover:blur-none"
                                                src={product.featured_image_url}
                                            />
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="mb-6 flex justify-center">
                    <span
                        className="cursor-pointer text-sm text-grey-3"
                        onClick={handleContinue}
                    >
                        {t('suggestions_by_reason.proceed')}
                    </span>
                </div>
            </div>
        </div>
    )
}
