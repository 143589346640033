import styled from 'styled-components'
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import { getDate } from '@/utils'
import { Button } from '@itsrever/design-system'
import { setSelectedProcessID } from '@/redux/order/orderSlice'
import { useNavigate } from 'react-router-dom'
import { usePages } from '@/hooks'
import { useTranslation } from 'react-i18next'

export function ProcessSelectionPage() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation()
    const { pages } = usePages()

    const orderNumber = useAppSelector((store) => store.order.orderNumber)

    const handleClickProcess = (processID: string | undefined) => {
        dispatch(setSelectedProcessID(processID))
        navigate(pages.Tracking)
    }

    const order = useAppSelector((store) => store.returnsApi.order.response)
    const processes = order.previous_return_processes ?? []

    const lineItems = order?.order?.line_items ?? []

    const noReturnableItems = lineItems.every((item) => {
        const nonRet = item.non_returnable_items?.reduce((acc, curr) => {
            return acc + (curr.quantity ?? 1)
        }, 0)
        return item.quantity === nonRet
    })

    const handleClickNewReturn = () => {
        navigate(pages.ItemSelection)
    }

    return (
        <Main>
            <h4 className="mb-8">
                {t('tracking_layout.your_return_from')} {orderNumber}
            </h4>

            <>
                <div>
                    <h6 className="mb-3 text-gray-500">
                        {noReturnableItems
                            ? t('tracking_layout.see_order')
                            : t('tracking_layout.start_new_return')}
                    </h6>
                    <Button onClick={handleClickNewReturn}>
                        {noReturnableItems
                            ? t('tracking_layout.see_order')
                            : t(`tracking_layout.start_new_return`)}
                    </Button>
                </div>
                <hr className="my-4 w-1/2" />
            </>

            <h6 className="mb-3 text-gray-500">
                {t('tracking_layout.status_previous')}
            </h6>
            <div className="flex flex-col items-center gap-4">
                {processes.map((process, i) => {
                    return (
                        <Button
                            key={i}
                            onClick={() =>
                                handleClickProcess(process.process_id)
                            }
                        >
                            <p>
                                {t(`tracking_layout.return_process`)}
                                {getDate(
                                    process.started_at ?? '',
                                    i18n.language
                                )}
                            </p>
                        </Button>
                    )
                })}
            </div>
        </Main>
    )
}

const Main = styled.div`
    position: absolute;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: #fff;
`
