import ProgressBarSelection from '@/assets/images/progress-bar-selection'
import ProgressBarRefund from '@/assets/images/progress-bar-refund'
import ProgressBarReturn from '@/assets/images/progress-bar-return'
import ProgressBarConfirm from '@/assets/images/progress-bar-confirm'
import { useTheme } from '@itsrever/design-system'
import styled from 'styled-components'
import { device } from '@/utils'
import { useTranslate } from '@/hooks'

export interface ProgressBarProps {
    page:
        | 'order'
        | 'refund-method'
        | 'return-method'
        | 'confirm'
        | 'return-address'
        | 'exchange-address'
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ page }) => {
    const { translate } = useTranslate()
    const theme = useTheme()

    return (
        <MainDiv>
            <div className="mt-2 grid min-w-[280px] grid-cols-4 grid-rows-2">
                <div data-testid="SVG" className="col-span-4 mb-1">
                    {page === 'order' && <ProgressBarSelection />}
                    {page === 'refund-method' && <ProgressBarRefund />}
                    {page === 'return-address' && <ProgressBarSelection />}
                    {page === 'exchange-address' && <ProgressBarReturn />}
                    {page === 'return-method' && <ProgressBarReturn />}
                    {page === 'confirm' && <ProgressBarConfirm />}
                </div>
                <CustomP
                    dangerouslySetInnerHTML={{
                        __html: translate('progress_bar_selection')
                    }}
                />
                <CustomP
                    style={{
                        color:
                            page === 'order'
                                ? theme.colors.grey[2]
                                : theme.colors.common.black
                    }}
                    dangerouslySetInnerHTML={{
                        __html: translate('progress_bar_refund')
                    }}
                />
                <CustomP
                    style={{
                        color:
                            page === 'order' || page === 'refund-method'
                                ? theme.colors.grey[2]
                                : theme.colors.common.black
                    }}
                    dangerouslySetInnerHTML={{
                        __html: translate('progress_bar_return')
                    }}
                />
                <CustomP
                    style={{
                        color:
                            page === 'order' ||
                            page === 'refund-method' ||
                            page === 'return-method'
                                ? theme.colors.grey[2]
                                : theme.colors.common.black
                    }}
                    dangerouslySetInnerHTML={{
                        __html: translate('progress_bar_overview')
                    }}
                />
            </div>
        </MainDiv>
    )
}

const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    z-index: 10;
`

const CustomP = styled.p`
    text-align: center;
    font-size: 11px;
    line-height: 16px;
    // Over lg
    @media ${device.lg} {
        font-size: 14px;
        line-height: 20px;
    }
`

export default ProgressBar
