import { useTranslate } from '@/hooks'
import { useAppSelector } from '@/redux/hooks'
import { RefundActions } from '@/redux/return/returnSlice'
import { InfoIcon } from '@itsrever/design-system'

export const KYIInfoBanner = () => {
    const { translate } = useTranslate()
    const returnedItems = useAppSelector((store) => store.return.returnedItems)
    const hasExchanges = returnedItems.some(
        (item) => item.item.action === RefundActions.ToExchange
    )
    const onlyExchanges = returnedItems.every(
        (item) => item.item.action === RefundActions.ToExchange
    )
    return (
        <div className="flex gap-2 rounded-lg bg-yellow-50 px-3 py-2">
            <InfoIcon className="opacity-75"></InfoIcon>
            <div className="flex flex-col gap-1">
                <p
                    dangerouslySetInnerHTML={{
                        __html: translate(`kyi_summary_description`)
                    }}
                />
                {onlyExchanges ? (
                    <p
                        dangerouslySetInnerHTML={{
                            __html: translate(`kyi_summary_exchanges`)
                        }}
                    />
                ) : hasExchanges ? (
                    <p
                        dangerouslySetInnerHTML={{
                            __html: translate(
                                `kyi_summary_exchanges_and_refund`
                            )
                        }}
                    />
                ) : (
                    <p
                        dangerouslySetInnerHTML={{
                            __html: translate(`kyi_summary_refund`)
                        }}
                    />
                )}
            </div>
        </div>
    )
}
export default KYIInfoBanner
