import { useAppSelector } from '@/redux/hooks'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { usePages } from '@/hooks'
import { Order } from '@/components/ItemSelectionComponents'
import { toast } from '@itsrever/design-system'
import { useEffect } from 'react'
import { OrderLite } from '@/components/ReverLite'
import { PbECommerceSettingsUseCaseEnum } from '@itsrever/rever-api'

function ItemSelectionPage() {
    const { t } = useTranslation()
    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )

    const navigate = useNavigate()
    const { pages } = usePages()
    const isReverLite =
        settings.use_case === PbECommerceSettingsUseCaseEnum.Lite

    const order = useAppSelector(
        (store) => store.returnsApi.order.response.order
    )

    useEffect(() => {
        if ((!order || !Object.keys(order)) && !isReverLite) {
            toast({
                text: t('toast_errors.lost_order'),
                variant: 'error'
            })
            navigate(pages.Landing)
        }
    }, [])

    return isReverLite ? <OrderLite /> : <Order />
}

export default ItemSelectionPage
