import { ReturnsCalculatedSummaryExchangeItem } from '@itsrever/rever-api'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ExchangeIcon from '@/assets/images/icons/ExchangeIcon'

type Props = {
    exchangedItems: Array<ReturnsCalculatedSummaryExchangeItem>
    newExchangeOrderNumber: string
    showItems?: boolean
    showOrderNumber?: boolean
}

const ExchangeInformation: React.FC<Props> = ({
    exchangedItems,
    newExchangeOrderNumber,
    showItems = true,
    showOrderNumber = true
}) => {
    const { t } = useTranslation()

    return (
        <ExchangeInfo className="flex items-center justify-center rounded-2xl border border-solid border-blue-200 bg-gradient-to-t from-blue-100 to-blue-50 shadow-md shadow-slate-400">
            <Label className="bg-blue-900 px-3 py-1">
                <p className="text-xs uppercase">
                    <b>{t(`tracking_exchange.exchange_order`)}</b>
                </p>
            </Label>
            <div
                className={
                    'absolute -left-5 top-[50%] h-12 w-12 translate-y-[-50%] overflow-hidden rounded-full border border-solid  border-blue-200 bg-blue-50 p-3 text-blue-700 shadow-md'
                }
            >
                <div className="scale-150 opacity-75">
                    <ExchangeIcon />
                </div>
            </div>
            <p className="flex grow flex-col items-center text-slate-600">
                {showOrderNumber ? (
                    <span className="mb-1 flex flex-col rounded-xl border border-solid border-blue-200 px-5 py-1">
                        <span className="font-bold">
                            {t(`tracking_exchange.new_order_id`)}
                        </span>
                        <span>{newExchangeOrderNumber}</span>
                    </span>
                ) : null}
                {showItems &&
                    exchangedItems.map((item, i) => {
                        return (
                            <span key={i}>
                                <span className="flex flex-col items-center  text-blue-900">
                                    <span className="font-bold">
                                        {item.name}{' '}
                                    </span>
                                    <span className="ml-1 mt-2 flex w-fit shrink-0 rounded border border-solid border-blue-300 px-1">
                                        {item.variant_name}
                                    </span>
                                </span>
                            </span>
                        )
                    })}
            </p>
        </ExchangeInfo>
    )
}

export default ExchangeInformation

const ExchangeInfo = styled.div`
    max-width: 300px;
    width: 250px;
    position: relative;
    align-items: center;
    padding: 1.1rem 1rem 0.75rem 1rem;
    text-align: center;
`

const Label = styled.div`
    position: absolute;
    top: -13px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 20px;
    color: white;
    white-space: nowrap;
`
