import { useAppSelector } from '@/redux/hooks'
import { ItemActions } from '@/redux/return/returnSlice'

export function useCredit() {
    const orderProducts = useAppSelector((store) => store.order.orderProducts)
    const newItems = useAppSelector((store) => store.return.newItems)

    const returnedItemsFullCatalog = useAppSelector(
        (store) => store.return.returnedItems
    ).filter((item) => item.action === ItemActions.ToExchangeFullCatalog)

    const totalAvailableCredit = returnedItemsFullCatalog
        .map((item) => {
            const product = orderProducts.find(
                (product) => product.lineItemId === item.lineItemId
            )
            if (product) {
                return (
                    ((product.product.total ?? 0) /
                        (product.product.quantity ?? 1)) *
                    item.item.quantity
                )
            }
            return 0
        })
        .reduce((acc, price) => acc + price / 100, 0)
    const newItemsTotalPrice = newItems.reduce((acc, item) => {
        return acc + item.quantity * item.price
    }, 0)

    const computeAmountToPay = () => {
        if (newItemsTotalPrice > 0) {
            const payAmount = newItemsTotalPrice - totalAvailableCredit
            if (payAmount > 0) {
                return payAmount
            } else {
                return 0
            }
        } else {
            return 0
        }
    }

    const amountToPay = computeAmountToPay()
    const spentCredit =
        newItemsTotalPrice >= totalAvailableCredit
            ? totalAvailableCredit
            : newItemsTotalPrice

    return { totalAvailableCredit, spentCredit, amountToPay }
}
