import styled from 'styled-components'
import { useTheme } from '@itsrever/design-system'
import { useTranslation } from 'react-i18next'

interface ShowMoreButtonProps {
    onClick?: () => void
}

export const ShowMoreButton: React.FC<ShowMoreButtonProps> = ({ onClick }) => {
    const theme = useTheme()
    const { t } = useTranslation()
    return (
        <Button
            className="shadow-sm"
            $color={theme.colors.grey[0]}
            $hoverBgColor={theme.colors.grey[4]}
            onClick={onClick}
        >
            <p>{t('full_catalog.show_more')}</p>
        </Button>
    )
}

export default ShowMoreButton

interface ButtonProps {
    $color: string
    $hoverBgColor: string
}

const Button = styled.button<ButtonProps>`
    margin: 0 auto;
    border-radius: 0.375rem;
    border: 1px solid;
    border-color: ${(p) => p.$color};
    padding: 0.5rem 1.25rem;
    color: ${(p) => p.$color};
    &:hover {
        background-color: ${(p) => p.$hoverBgColor};
    }
`
