import styled from 'styled-components'
import { useAppSelector } from '@/redux/hooks'
import LanguageSwitcher from '@/components/LanguageSwitcher/LanguageSwitcher'
import { LandingForm, TermsAndConditions } from '@/components/LandingComponents'
import { useTranslate } from '@/hooks'
import { moreThan } from '@/utils'
import { PoweredByRever } from '@itsrever/design-system'

export const SimplifiedLanding: React.FC = () => {
    const { translate } = useTranslate()

    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )

    const logoUrl = settings.theme?.logo_url
    const logoHeight = settings.theme?.logo_height

    return (
        <MainDiv>
            <LanguageSwitcher />
            <div className="mb-auto" />
            <ContentDiv>
                <div className="flex w-full flex-col items-center text-center">
                    <b>
                        <span
                            className="mt-4 px-4 md:text-lg"
                            dangerouslySetInnerHTML={{
                                __html: translate(
                                    'landing_title_layout_simplified'
                                )
                            }}
                        />
                    </b>
                    <span
                        className="mt-2 p-4 md:text-lg"
                        dangerouslySetInnerHTML={{
                            __html: translate('landing_subtitle')
                        }}
                    />
                    <div className="max-w-[280px] md:max-w-[335px]">
                        <LandingForm />
                        <TermsAndConditions />
                    </div>
                </div>
                <LogoContainer
                    className="mt-8"
                    mobileHeight={logoHeight?.mobile ?? ''}
                    desktopHeight={logoHeight?.desktop ?? ''}
                >
                    <img className="max-h-full max-w-full" src={logoUrl} />
                </LogoContainer>
            </ContentDiv>
            <div className="mt-auto">
                <PoweredByRever />
            </div>
        </MainDiv>
    )
}

export default SimplifiedLanding

const ContentDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`

const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 1rem;
`

interface LogoContainerProps {
    mobileHeight: string
    desktopHeight: string
}

const LogoContainer = styled.div<LogoContainerProps>`
    height: ${(p) => (p.mobileHeight ? p.mobileHeight : '2rem')};
    @media ${moreThan.md} {
        height: ${(p) => (p.desktopHeight ? p.desktopHeight : '4rem')};
    }
`
