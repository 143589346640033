import styled from 'styled-components'
import { ChevronLeftIcon } from '@itsrever/design-system'
import ProgressBar from '@/layout/LayoutComponents/ProgressBar'
import { useNavigate } from 'react-router-dom'
import { useTranslate } from '@/hooks'

interface Props {
    page:
        | 'order'
        | 'refund-method'
        | 'return-method'
        | 'confirm'
        | 'return-address'
        | 'exchange-address'
    title: string
}

const TopBar: React.FC<Props> = ({ page, title }) => {
    const { translate } = useTranslate()
    const navigate = useNavigate()

    return (
        <MainDiv id="TopBar">
            <LeftDiv className="lg:w-[245px] xl:w-[400px]">
                <ContentDiv
                    id="back-button"
                    className="hover:bg-grey-5"
                    onClick={() => navigate(-1)}
                >
                    <ChevronLeftIcon />
                    <CustomP className="ml-2 text-start text-lg">
                        <b
                            dangerouslySetInnerHTML={{
                                __html: translate('top_bar_back')
                            }}
                        />
                    </CustomP>
                </ContentDiv>
            </LeftDiv>
            <div className="grow">
                <h5 className="mb-2 text-center">{title}</h5>
                <ProgressBar page={page} />
            </div>
            <div className="lg:w-[245px] xl:w-[400px]" />
        </MainDiv>
    )
}

export default TopBar

const ContentDiv = styled.div`
    display: flex;
    height: fit-content;
    width: fit-content;
    cursor: pointer;
    align-items: center;
    border-radius: 0.5rem;
    padding: 0.5rem;
    @media (max-width: 899px) {
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 1rem;
        margin-left: 0.5rem;
    }
`

const MainDiv = styled.div`
    position: sticky;
    top: 0;
    background-color: #fff;
    width: 100%;
    z-index: 10;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    padding-top: 0.5rem;
    display: flex;
`

const CustomP = styled.p`
    @media (max-width: 599px) {
        display: none;
    }
`

const LeftDiv = styled.div`
    padding: 0.125rem;
    padding-left: 1rem;
    height: 100%;
`
