interface EmptyCartProps {
    lightColor?: string
    darkColor?: string
}
const EmptyCart: React.FC<EmptyCartProps> = ({ lightColor, darkColor }) => {
    lightColor = lightColor || '#475569'
    darkColor = darkColor || '#1e293b'
    return (
        <svg
            width="190"
            height="189"
            viewBox="0 0 190 189"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Group 6">
                <svg
                    width="190"
                    height="189"
                    viewBox="0 0 190 189"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g id="Group 6">
                        <g id="Rectangle 9" filter="url(#filter0_dd_820_453)">
                            <path
                                d="M3.39648 27.6673C3.39648 13.7112 14.7102 2.39746 28.6663 2.39746H161.333C175.289 2.39746 186.603 13.7112 186.603 27.6673V158.755C186.603 172.711 175.289 184.024 161.333 184.024H28.6663C14.7101 184.024 3.39648 172.711 3.39648 158.755V27.6673Z"
                                fill="url(#paint0_linear_820_453)"
                            />
                            <path
                                d="M4.18617 27.6673C4.18617 14.1473 15.1463 3.18714 28.6663 3.18714H161.333C174.853 3.18714 185.813 14.1473 185.813 27.6673V158.755C185.813 172.275 174.853 183.235 161.333 183.235H28.6663C15.1463 183.235 4.18617 172.275 4.18617 158.755V27.6673Z"
                                stroke="white"
                                strokeWidth="1.57937"
                            />
                        </g>
                        <path
                            id="Rectangle 12"
                            d="M18.4005 130.326C18.4005 123.784 23.7038 118.481 30.2458 118.481H159.754C166.296 118.481 171.599 123.784 171.599 130.326V158.755C171.599 165.297 166.296 170.6 159.754 170.6H30.2458C23.7038 170.6 18.4005 165.297 18.4005 158.755V130.326Z"
                            fill="url(#paint1_radial_820_453)"
                            stroke={darkColor}
                            strokeWidth="1.57937"
                        />
                        <g id="shopping-cart-2-fill">
                            <g id="Vector" filter="url(#filter1_d_820_453)">
                                <path
                                    d="M83.628 136.6L79.0183 131.992L81.0296 129.98L85.6379 134.59H107.303C107.525 134.59 107.743 134.642 107.941 134.741C108.139 134.841 108.311 134.985 108.444 135.163C108.576 135.341 108.665 135.547 108.703 135.765C108.742 135.983 108.729 136.207 108.665 136.42L105.254 147.791C105.166 148.084 104.986 148.341 104.741 148.523C104.495 148.706 104.198 148.804 103.892 148.804H86.4709V151.647H102.107V154.49H85.0494C84.6724 154.49 84.3109 154.34 84.0443 154.074C83.7778 153.807 83.628 153.446 83.628 153.069V136.6ZM85.7601 160.176C85.1947 160.176 84.6523 159.951 84.2525 159.551C83.8526 159.152 83.628 158.609 83.628 158.044C83.628 157.478 83.8526 156.936 84.2525 156.536C84.6523 156.136 85.1947 155.912 85.7601 155.912C86.3256 155.912 86.8679 156.136 87.2678 156.536C87.6677 156.936 87.8923 157.478 87.8923 158.044C87.8923 158.609 87.6677 159.152 87.2678 159.551C86.8679 159.951 86.3256 160.176 85.7601 160.176ZM102.817 160.176C102.252 160.176 101.709 159.951 101.31 159.551C100.91 159.152 100.685 158.609 100.685 158.044C100.685 157.478 100.91 156.936 101.31 156.536C101.709 156.136 102.252 155.912 102.817 155.912C103.383 155.912 103.925 156.136 104.325 156.536C104.725 156.936 104.949 157.478 104.949 158.044C104.949 158.609 104.725 159.152 104.325 159.551C103.925 159.951 103.383 160.176 102.817 160.176Z"
                                    fill="white"
                                />
                            </g>
                        </g>
                        <rect
                            id="Rectangle 8"
                            x="17.6108"
                            y="16.6113"
                            width="63.1746"
                            height="63.1746"
                            rx="11.0556"
                            fill="url(#paint2_linear_820_453)"
                        />
                        <rect
                            id="Rectangle 10"
                            x="94.9995"
                            y="29.2471"
                            width="63.1746"
                            height="14.2143"
                            rx="7.10714"
                            fill="url(#paint3_linear_820_453)"
                        />
                        <g id="Frame 64">
                            <rect
                                id="Rectangle 13"
                                x="17.6108"
                                y="94.001"
                                width="28.4286"
                                height="14.2143"
                                rx="7.10714"
                                fill="#EBF0F5"
                            />
                            <rect
                                id="Rectangle 14"
                                x="50.7773"
                                y="94.001"
                                width="28.4286"
                                height="14.2143"
                                rx="7.10714"
                                fill="#EBF0F5"
                            />
                            <rect
                                id="Rectangle 15"
                                x="83.9438"
                                y="94.001"
                                width="28.4286"
                                height="14.2143"
                                rx="7.10714"
                                fill="#EBF0F5"
                            />
                        </g>
                        <rect
                            id="Rectangle 11"
                            x="94.9995"
                            y="52.9375"
                            width="72.6508"
                            height="14.2143"
                            rx="7.10714"
                            fill="url(#paint4_linear_820_453)"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_dd_820_453"
                            x="0.237754"
                            y="0.818096"
                            width="189.524"
                            height="187.944"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood
                                floodOpacity="0"
                                result="BackgroundImageFix"
                            />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feMorphology
                                radius="1.57937"
                                operator="dilate"
                                in="SourceAlpha"
                                result="effect1_dropShadow_820_453"
                            />
                            <feOffset />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0.882353 0 0 0 0 0.894118 0 0 0 0 0.917647 0 0 0 1 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_820_453"
                            />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="1.57937" />
                            <feGaussianBlur stdDeviation="1.57937" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0.0392157 0 0 0 0 0.0509804 0 0 0 0 0.0784314 0 0 0 0.03 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="effect1_dropShadow_820_453"
                                result="effect2_dropShadow_820_453"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect2_dropShadow_820_453"
                                result="shape"
                            />
                        </filter>
                        <filter
                            id="filter1_d_820_453"
                            x="72.7009"
                            y="129.98"
                            width="42.3415"
                            height="42.8302"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood
                                floodOpacity="0"
                                result="BackgroundImageFix"
                            />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="6.31746" />
                            <feGaussianBlur stdDeviation="3.15873" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0.284017 0 0 0 0 0.284017 0 0 0 0 0.284017 0 0 0 0.4 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_820_453"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_820_453"
                                result="shape"
                            />
                        </filter>
                        <linearGradient
                            id="paint0_linear_820_453"
                            x1="94.9997"
                            y1="2.39746"
                            x2="94.9997"
                            y2="184.024"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="#F1F3F8" />
                        </linearGradient>
                        <radialGradient
                            id="paint1_radial_820_453"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(94.9997 171.39) scale(151.475 86.0295)"
                        >
                            <stop stopColor={lightColor} />
                            <stop offset="1" stopColor={darkColor} />
                        </radialGradient>
                        <linearGradient
                            id="paint2_linear_820_453"
                            x1="49.1981"
                            y1="16.6113"
                            x2="49.1981"
                            y2="79.7859"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F7F9FB" />
                            <stop offset="1" stopColor="#E9EFF4" />
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear_820_453"
                            x1="126.587"
                            y1="29.2471"
                            x2="126.587"
                            y2="43.4614"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F7F9FB" />
                            <stop offset="1" stopColor="#E9EFF4" />
                        </linearGradient>
                        <linearGradient
                            id="paint4_linear_820_453"
                            x1="131.325"
                            y1="52.9375"
                            x2="131.325"
                            y2="67.1518"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F7F9FB" />
                            <stop offset="1" stopColor="#E9EFF4" />
                        </linearGradient>
                    </defs>
                </svg>
            </g>
        </svg>
    )
}

export default EmptyCart
