import { TextBox } from '@itsrever/design-system'
import { ChangeEvent, useEffect } from 'react'
import { useMediaQuery } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@/assets/images/icons/DeleteIcon'
import CheckIcon from '@/assets/images/icons/CheckIcon'

interface UploadedImagePreviewProps {
    src: string
    index: number
    isEditing: boolean
    setEditingImage: (index: number | undefined) => void
    onClear: (index: number) => void
    value: string
    onChangeText: (index: number, value: string) => void
}

export const UploadedImagePreview: React.FC<UploadedImagePreviewProps> = ({
    src,
    index,
    onClear,
    value,
    isEditing,
    setEditingImage,
    onChangeText
}) => {
    const { t } = useTranslation()
    const isMobile = useMediaQuery('(max-width:599px)')

    const [editingValue, setEditingValue] = useState(value)

    const handleClear = () => {
        onClear(index)
    }

    const handleChangeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
        onChangeText(index, e.currentTarget.value)
    }
    const [showValidationError, setShowValidationError] = useState(false)
    const [dirty, setDirty] = useState(false)

    const onImageClick = () => {
        if (isEditing) {
            setEditingImage(undefined)
        }
        setEditingImage(index)
    }
    const onEditingReason = (reason: string) => {
        setEditingValue(reason)
        if (reason.length < 5 && dirty) {
            setShowValidationError(true)
        } else {
            setShowValidationError(false)
        }
    }

    const onSaveEdit = () => {
        if (editingValue.length < 5) {
            setDirty(true)
            setShowValidationError(true)
            return
        }

        onChangeText(index, editingValue)
        setEditingImage(undefined)
        setShowValidationError(false)
    }
    const onCancelEdition = () => {
        setEditingValue(value)
        setEditingImage(undefined)
        setShowValidationError(false)
        setDirty(false)
    }

    useEffect(() => {
        const textInput = document.getElementById(
            'textInputMobile'
        ) as HTMLInputElement
        if (textInput) {
            const length = textInput.value.length
            textInput.focus()
            textInput.setSelectionRange(length, length)
        }
    }, [isEditing])

    return (
        <>
            {isMobile ? (
                <div
                    className={
                        'flex flex-col w-full cursor-pointer overflow-hidden gap-3 rounded border border-solid border-slate-300 transition-all ' +
                        (isEditing ? ' h-64' : 'h-20')
                    }
                >
                    <div className="relative flex w-full items-center gap-3">
                        <div className="self-stretch">
                            <div
                                className={
                                    'mb-auto h-20 w-20 shrink-0 border-solid border-slate-300 bg-cover bg-center bg-no-repeat ' +
                                    (isEditing ? 'rounded-tl' : 'rounded-l')
                                }
                                style={{ backgroundImage: `url(${src})` }}
                            />
                        </div>
                        <div onClick={onImageClick} className="grow">
                            {value && !isEditing ? (
                                <p className="flex shrink-0 gap-2">
                                    <span className="mb-2 line-clamp-2 flex-1 text-sm text-slate-600">
                                        {value}
                                    </span>
                                </p>
                            ) : (
                                <p className="mb-2 flex text-sm italic text-yellow-500">
                                    {t('upload_images.what_is_wrong')}
                                </p>
                            )}
                        </div>
                        <div className="shrink-0 self-start">
                            <button
                                onClick={handleClear}
                                className="mb-auto h-8 w-8 rounded-lg border-solid border-slate-300 bg-white p-1 text-center text-gray-500"
                            >
                                <DeleteIcon />
                            </button>
                        </div>
                    </div>
                    {isEditing ? (
                        <div className="flex h-full w-full flex-col px-3 pb-1">
                            <TextBox
                                id="textInputMobile"
                                fullWidth
                                placeholder={t(
                                    'upload_images.reason_placeholder'
                                )}
                                onChange={(e) => {
                                    onEditingReason(e.target.value)
                                }}
                                value={editingValue}
                                sx={{
                                    fontFamily: 'Roobert',
                                    height: '100px'
                                }}
                            />
                            <div className="mt-2 flex h-full items-center justify-between gap-5">
                                <div>
                                    {showValidationError && (
                                        <p className="text-xs text-red-600">
                                            {t('upload_images.minimum_length')}
                                        </p>
                                    )}
                                </div>
                                <div className="flex items-center gap-5">
                                    <button
                                        onClick={onCancelEdition}
                                        className="text-gray-300"
                                    >
                                        {t('upload_images.cancel')}
                                    </button>
                                    <button
                                        className="flex items-center rounded bg-blue-200 text-blue-700"
                                        onClick={onSaveEdit}
                                    >
                                        <span className="h-9 w-9 p-1">
                                            <CheckIcon />
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : (
                <div className="relative mx-auto flex w-full max-w-[750px] flex-col items-center gap-2 overflow-hidden rounded-md border border-solid border-grey-4 md:flex-row">
                    <button
                        onClick={handleClear}
                        className="absolute right-1 top-1 inline-block h-8 w-8 cursor-pointer rounded p-1 text-gray-400 hover:bg-gray-200"
                    >
                        <DeleteIcon />
                    </button>
                    <div
                        className="h-44 w-64 rounded-l-md bg-cover bg-center bg-no-repeat"
                        style={{ backgroundImage: `url(${src})` }}
                    />
                    <div className="px-3 py-1">
                        <p className="mb-4 text-sm text-gray-400">
                            {t('upload_images.what_is_wrong')}
                        </p>
                        <TextBox
                            value={value}
                            onChange={handleChangeText}
                            sx={{ fontFamily: 'Roobert', height: '100px' }}
                        />
                    </div>
                </div>
            )}
        </>
    )
}
