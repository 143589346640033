import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import EmptyCart from '@/assets/images/EmptyCart'
interface CartEmptyStateProps {
    onClose?: () => void
}
export const CartEmptyState: React.FC<CartEmptyStateProps> = ({ onClose }) => {
    const { t } = useTranslation()
    return (
        <div className="flex flex-col items-center">
            <div className="relative flex h-[190px] w-[400px] items-center justify-center gap-4 overflow-hidden">
                <div className="absolute inset-y-0 right-0 w-12 bg-gradient-to-r from-transparent to-white md:to-[#F2F2F2]" />
                <div className="absolute inset-y-0 left-0 w-12 bg-gradient-to-l from-transparent to-white md:to-[#F2F2F2]" />
                {[...Array(3)].map((_, index) => (
                    <div key={index}>
                        <EmptyCart
                            lightColor="var(--brand-600)"
                            darkColor="var(--brand-800)"
                        />
                    </div>
                ))}
            </div>
            <h3 className="mt-6 text-2xl font-semibold">
                {t('full_catalog.no_items_added')}
            </h3>
            <p className="mt-1 max-w-80 text-center text-base font-light text-slate-500">
                {t('full_catalog.empty_cart_description')}
            </p>
            <Button
                onClick={() => {
                    if (onClose) onClose()
                }}
                className="mt-4 cursor-pointer"
            >
                <span className="font-semibold drop-shadow-sm">
                    {t('full_catalog.shop_now')}
                </span>
            </Button>
        </div>
    )
}
const Button = styled.button`
    display: flex;
    padding: 9px 20px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    color: white;
    border-radius: 100px;
    border: 2px solid var(--brand-800);
    background: radial-gradient(
        160.21% 97.87% at 50% 100%,
        var(--brand-600) 0%,
        var(--brand-800) 100%
    );
    box-shadow: 0px 0px 16px 0px rgba(255, 255, 255, 0.5) inset;
    transition: box-shadow 0.3s ease-in-out;
    &:hover {
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2),
            0px 0px 16px 0px rgba(255, 255, 255, 0.7) inset;
    }
`
