const NoLabelImage = () => {
    return (
        <svg
            width="300"
            height="200"
            viewBox="0 0 900 600"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_11_6)">
                <path
                    d="M741.941 251.957C725.56 260.082 703.819 249.634 703.819 249.634C703.819 249.634 708.651 226.016 725.04 217.902C741.421 209.776 763.153 220.213 763.153 220.213C763.153 220.213 758.321 243.831 741.941 251.957Z"
                    fill="url(#paint0_linear_11_6)"
                />
                <path
                    d="M183.711 391.22C205.37 404.201 236.444 392.201 236.444 392.201C236.444 392.201 232.381 359.159 210.709 346.192C189.05 333.211 157.989 345.197 157.989 345.197C157.989 345.197 162.052 378.239 183.711 391.22Z"
                    fill="url(#paint1_linear_11_6)"
                />
                <path
                    d="M742.859 301.115C733.058 301.115 725.112 309.061 725.112 318.862C725.112 328.663 733.058 336.609 742.859 336.609C752.66 336.609 760.606 328.663 760.606 318.862C760.606 309.061 752.66 301.115 742.859 301.115Z"
                    fill="currentColor"
                />
                <path
                    d="M216.713 253.502C208.644 253.502 202.103 260.043 202.103 268.112C202.103 276.181 208.644 282.722 216.713 282.722C224.782 282.722 231.323 276.181 231.323 268.112C231.323 260.043 224.782 253.502 216.713 253.502Z"
                    fill="currentColor"
                />
                <path
                    d="M243.77 471.513C234.581 471.513 227.132 464.064 227.132 454.875C227.132 445.686 234.581 438.237 243.77 438.237C252.959 438.237 260.408 445.686 260.408 454.875C260.408 464.064 252.959 471.513 243.77 471.513Z"
                    fill="currentColor"
                />
                <path
                    d="M796.955 347.128C792.667 347.128 789.191 343.652 789.191 339.364C789.191 335.076 792.667 331.6 796.955 331.6C801.243 331.6 804.719 335.076 804.719 339.364C804.719 343.652 801.243 347.128 796.955 347.128Z"
                    fill="currentColor"
                />
                <path
                    d="M686.438 428.407C680.925 428.407 676.455 423.937 676.455 418.424C676.455 412.911 680.925 408.441 686.438 408.441C691.951 408.441 696.421 412.911 696.421 418.424C696.421 423.937 691.951 428.407 686.438 428.407Z"
                    fill="#E1E4E5"
                />
                <path
                    d="M216.879 536.17C208.916 536.17 202.46 529.714 202.46 521.751C202.46 513.788 208.916 507.332 216.879 507.332C224.842 507.332 231.298 513.788 231.298 521.751C231.298 529.714 224.842 536.17 216.879 536.17Z"
                    fill="#E1E4E5"
                />
                <path
                    d="M676.703 532.889C670.642 532.889 665.728 527.975 665.728 521.914C665.728 515.853 670.642 510.939 676.703 510.939C682.764 510.939 687.678 515.853 687.678 521.914C687.678 527.975 682.764 532.889 676.703 532.889Z"
                    fill="#E1E4E5"
                />
                <path
                    d="M767.703 168.141C760.965 168.141 755.502 162.678 755.502 155.94C755.502 149.202 760.965 143.739 767.703 143.739C774.441 143.739 779.904 149.202 779.904 155.94C779.904 162.678 774.441 168.141 767.703 168.141Z"
                    fill="#E1E4E5"
                />
                <path
                    d="M120.003 295.71C121.569 301.556 118.1 307.564 112.255 309.13C106.41 310.696 100.402 307.227 98.8354 301.382C97.2692 295.537 100.738 289.529 106.583 287.963C112.428 286.396 118.437 289.865 120.003 295.71Z"
                    fill="#E1E4E5"
                />
                <path
                    d="M280.337 151.773C277.819 151.773 275.777 149.731 275.777 147.213C275.777 144.695 277.819 142.653 280.337 142.653C282.855 142.653 284.897 144.695 284.897 147.213C284.897 149.731 282.855 151.773 280.337 151.773Z"
                    fill="#E1E4E5"
                />
                <path
                    d="M755.602 435.916C749.41 435.916 744.39 431.9 744.39 426.946C744.39 421.992 749.41 417.976 755.602 417.976C761.794 417.976 766.814 421.992 766.814 426.946C766.814 431.9 761.794 435.916 755.602 435.916Z"
                    fill="#E1E4E5"
                />
                <path
                    d="M559.153 71.6609C560.287 75.8924 557.776 80.2418 553.545 81.3756C549.313 82.5094 544.964 79.9983 543.83 75.7668C542.696 71.5354 545.207 67.186 549.439 66.0521C553.67 64.9183 558.02 67.4295 559.153 71.6609Z"
                    fill="#E1E4E5"
                />
                <path
                    d="M202.019 156.14H202.178C203.124 169.541 213.09 169.748 213.09 169.748C213.09 169.748 202.101 169.962 202.101 185.446C202.101 169.962 191.111 169.748 191.111 169.748C191.111 169.748 201.073 169.541 202.019 156.14Z"
                    fill="#E1E4E5"
                />
                <path
                    d="M541.683 116.529L609.56 184.466C617.533 192.447 622.013 203.266 622.016 214.547V482.932C622.016 506.444 602.992 525.478 579.513 525.478H339.495C316.015 525.478 296.992 506.444 296.992 482.932V146.61C296.992 123.097 316.015 104.064 339.495 104.064H511.636C517.218 104.067 522.744 105.169 527.899 107.307C533.055 109.446 537.739 112.58 541.683 116.529Z"
                    fill="white"
                    stroke="#E1E4E5"
                    strokeWidth="11"
                />
                <path
                    d="M369.205 274.474H452.39M369.205 228.474H452.39M369.205 331.87H517.348M369 391.312H535.697M369 450.312H535.697"
                    stroke="#E1E4E5"
                    strokeWidth="24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <circle cx="582.5" cy="185.5" r="122.5" fill="currentColor" />
                <path
                    d="M522.55 178.025C522.55 179.875 523.285 181.649 524.593 182.957C525.901 184.265 527.675 185 529.525 185C531.375 185 533.149 184.265 534.457 182.957C535.765 181.649 536.5 179.875 536.5 178.025C536.5 170.625 539.439 163.529 544.672 158.297C549.904 153.064 557 150.125 564.4 150.125H625.464L615.02 161.731C614.384 162.408 613.89 163.204 613.566 164.074C613.242 164.944 613.095 165.87 613.133 166.797C613.172 167.725 613.395 168.635 613.79 169.475C614.184 170.316 614.743 171.068 615.433 171.69C616.122 172.311 616.929 172.789 617.805 173.095C618.682 173.4 619.61 173.528 620.537 173.47C621.463 173.412 622.369 173.17 623.201 172.758C624.032 172.345 624.773 171.771 625.38 171.069L646.305 147.819C647.5 146.491 648.14 144.755 648.094 142.97C648.047 141.184 647.317 139.484 646.054 138.221L625.129 117.296C624.49 116.611 623.72 116.061 622.865 115.68C622.009 115.299 621.086 115.094 620.149 115.077C619.213 115.061 618.282 115.233 617.414 115.584C616.545 115.935 615.756 116.457 615.094 117.119C614.432 117.781 613.909 118.57 613.559 119.439C613.208 120.307 613.036 121.238 613.052 122.174C613.069 123.111 613.274 124.034 613.655 124.89C614.036 125.745 614.586 126.515 615.271 127.154L624.292 136.175H564.4C553.301 136.175 542.656 140.584 534.808 148.433C526.959 156.281 522.55 166.926 522.55 178.025Z"
                    fill="white"
                />
                <path
                    d="M643.45 191.975C643.45 190.125 642.715 188.351 641.407 187.043C640.099 185.735 638.325 185 636.475 185C634.625 185 632.851 185.735 631.543 187.043C630.235 188.351 629.5 190.125 629.5 191.975C629.5 199.375 626.561 206.471 621.328 211.703C616.096 216.936 609 219.875 601.6 219.875H540.536L550.98 208.269C551.616 207.592 552.11 206.796 552.434 205.926C552.758 205.056 552.905 204.13 552.867 203.203C552.828 202.275 552.605 201.365 552.21 200.525C551.816 199.684 551.257 198.932 550.567 198.31C549.878 197.689 549.071 197.211 548.195 196.905C547.318 196.6 546.39 196.472 545.463 196.53C544.537 196.588 543.631 196.83 542.799 197.242C541.968 197.655 541.227 198.229 540.62 198.931L519.695 222.181C518.5 223.509 517.86 225.245 517.906 227.03C517.953 228.816 518.683 230.516 519.946 231.779L540.871 252.704C542.193 253.936 543.942 254.607 545.749 254.575C547.556 254.543 549.28 253.811 550.558 252.533C551.836 251.255 552.568 249.531 552.6 247.724C552.632 245.917 551.961 244.168 550.729 242.846L541.708 233.825H601.6C612.699 233.825 623.344 229.416 631.192 221.567C639.041 213.719 643.45 203.074 643.45 191.975Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_11_6"
                    x1="679.037"
                    y1="277.303"
                    x2="814.146"
                    y2="170.432"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#EEEEEE" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_11_6"
                    x1="267.526"
                    y1="433.005"
                    x2="93.249"
                    y2="271.079"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#EEEEEE" />
                </linearGradient>
                <clipPath id="clip0_11_6">
                    <rect width="900" height="600" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default NoLabelImage
