import { GithubComItsreverReturnsApiPkgDomainSummaryRetItem as SummaryRetItem } from '@itsrever/returns-api-types'
import NoAvailable from '@/assets/images/noAvailable.png'
import styled from 'styled-components'
import { ReturnsCalculatedSummaryReturnedItem } from '@itsrever/rever-api'
import { useTranslate } from '@/hooks'

export interface ReturnedItemProps {
    lineItem: SummaryRetItem | ReturnsCalculatedSummaryReturnedItem
}

export const ReturnedItem: React.FC<ReturnedItemProps> = ({ lineItem }) => {
    const { translate } = useTranslate()

    const imgSrc = lineItem.image_url ? lineItem.image_url : NoAvailable

    return (
        <MainDiv>
            <div className="h-fit w-16">
                <img src={imgSrc} alt="ProductImage" />
            </div>
            <div className="ml-4 mr-6">
                <p className="text-xs">{lineItem.name}</p>
                {lineItem.quantity && (
                    <>
                        <span
                            className="text-xs text-grey-2"
                            dangerouslySetInnerHTML={{
                                __html: translate('product_preview_quantity')
                            }}
                        />
                        <span className="text-xs">{lineItem.quantity}</span>
                    </>
                )}
                <p className="mt-0.5">{lineItem.variant_name}</p>
            </div>
        </MainDiv>
    )
}

export default ReturnedItem

const MainDiv = styled.div`
    display: flex;
    width: fit-content;
    flex-direction: row;
`
