import { Button, useTheme, toast } from '@itsrever/design-system'
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import { GithubComItsreverDomainModelsPkgModelsAddress as ModelsAddress } from '@itsrever/returns-api-types'
import styled from 'styled-components'
import { device, disabledForm } from '@/utils'
import AddressForm from '@/components/AddressForm'
import TopBar from '@/components/TopBarComponent/TopBar'
import { setReturnAddress, RefundActions } from '@/redux/return/returnSlice'
import { ReverCard } from '@/components/ReverCard'
import {
    useReturnMethods,
    useTranslate,
    usePages,
    useProvinces,
    useRefundMethods
} from '@/hooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LoadingModal } from '@/components/Loading'
import { PbECommerceSettingsUseCaseEnum } from '@itsrever/rever-api'

function ReturnAddress() {
    const { pages } = usePages()
    const { t } = useTranslation()
    const { translate } = useTranslate()
    const navigate = useNavigate()
    const theme = useTheme()
    const dispatch = useAppDispatch()

    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )
    const isReverLite =
        settings.use_case === PbECommerceSettingsUseCaseEnum.Lite

    const address = useAppSelector((store) => store.return.returnAddress)
    const setAddress = (address: ModelsAddress) => {
        dispatch(setReturnAddress(address))
    }

    const refundMethods = useAppSelector(
        (store) =>
            store.refundMethodsApi.refundMethods.response.result?.refund_methods
    )
    const provisionalRefundAmount = useAppSelector(
        (store) => store.refundMethodsApi.provisionalRefundAmount
    )
    const isKeepYourItemOffered = refundMethods?.some(
        (method) => method.keep_your_item?.enabled === true
    )

    const { provinces } = useProvinces(
        address.country_code,
        address.state_province_code
    )
    const { callReturnMethods, loadingReturnMethods } = useReturnMethods(
        onSuccessReturnMethods
    )
    const { callRefundMethods, loadingRefundMethods } = useRefundMethods(
        onSuccessRefundMethods
    )

    const order = useAppSelector(
        (store) => store.returnsApi.order?.response?.order
    )
    const returnedItems = useAppSelector((store) => store.return.returnedItems)
    const onlyExchanges = returnedItems.every(
        (item) => item.item.action === RefundActions.ToExchange
    )

    useEffect(() => {
        if ((!order || !Object.keys(order)) && !isReverLite) {
            toast({
                text: t('toast_errors.lost_order'),
                variant: 'error'
            })
            navigate(pages.Landing)
        }
    }, [])

    function onSuccessRefundMethods() {
        if (
            (onlyExchanges && !isKeepYourItemOffered) ||
            (provisionalRefundAmount &&
                provisionalRefundAmount <= 0 &&
                !isKeepYourItemOffered)
        ) {
            callReturnMethods(address)
        } else {
            navigate(pages.RefundMethod)
        }
    }
    function onSuccessReturnMethods() {
        navigate(pages.ReturnMethod)
    }

    const onClickContinue = () => {
        callRefundMethods(address)
    }

    return (
        <>
            {loadingRefundMethods || loadingReturnMethods ? (
                <LoadingModal loading />
            ) : null}
            <TopBar
                page="return-address"
                title={translate('return_address_page_title')}
            />
            <MainDiv color={theme.colors.grey[5]}>
                <ReverCard>
                    <Title
                        dangerouslySetInnerHTML={{
                            __html: translate('return_address_return_info')
                        }}
                    />
                    <AddressForm
                        addressObject={address}
                        setAddressObject={setAddress}
                    />
                    <div className="mt-6 flex w-full justify-center">
                        <div className="w-full md:max-w-[280px]">
                            <Button
                                onClick={onClickContinue}
                                id="returnAddressContinue"
                                fullWidth
                                disabled={disabledForm(
                                    address,
                                    provinces ? provinces.length : 0,
                                    undefined
                                )}
                            >
                                {translate('return_page_button_continue')}
                            </Button>
                        </div>
                    </div>
                </ReverCard>
            </MainDiv>
        </>
    )
}
export default ReturnAddress
interface MainProps {
    color?: string
}

const Title = styled.div`
    font-family: 'Roobert';
    margin-bottom: 2rem;
    text-align: center;
`

const MainDiv = styled.div<MainProps>`
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    flex-grow: 1;
    @media ${device.lg} {
        background-color: ${(p) => p.color};
    }
`
