import React from 'react'
import ReactDOM from 'react-dom/client'
import 'tailwindcss/tailwind.css'
import './assets/main.css'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import './i18next'
import App from './App'
import { initializeSentry, initializePosthog } from '@/services'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'

const development = import.meta.env.VITE_DEVELOPMENT

if (!development) {
    initializeSentry()
    initializePosthog()
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <PostHogProvider client={posthog}>
        <Provider store={store}>
            <App />
        </Provider>
    </PostHogProvider>
)
