import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Loading, axiosInstance } from './apiConfiguration'
import {
    SettingsApi,
    PbECommerceSettings,
    PbCopyTextDetail,
    PbAvailableReturnReasons
} from '@itsrever/rever-api'
import { AxiosError } from 'axios'

const settingsApi = new SettingsApi(undefined, undefined, axiosInstance)

interface SettingsCall {
    response: PbECommerceSettings
    loading: Loading
    error?: AxiosError
}

interface CopiesCall {
    response: Record<string, PbCopyTextDetail>
    loading: Loading
    error?: AxiosError
}

interface SettingsApiState {
    settings: SettingsCall
    copies: CopiesCall
    defaultCopies: CopiesCall
}

const initialState: SettingsApiState = {
    settings: {
        response: {},
        loading: 'pending'
    },
    copies: {
        response: {},
        loading: 'pending'
    },
    defaultCopies: {
        response: {},
        loading: 'pending'
    }
}

export const getSettings = createAsyncThunk(
    'settingsApi/getSettings',
    async (slug: string, { rejectWithValue }) => {
        try {
            const settings = await settingsApi.getECommerceSettings({
                slug
            })
            return settings.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const getCopies = createAsyncThunk(
    'settingsApi/getCopies',
    async (slug: string, { rejectWithValue }) => {
        try {
            const copies = await settingsApi.getECommerceTextCopies({
                slug
            })
            return copies.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const getDefaultCopies = createAsyncThunk(
    'settingsApi/getDefaultCopies',
    async () => {
        const copies = await settingsApi.getECommerceTextCopies()
        return copies.data
    }
)

export const findItemReturnReasons = async (
    ecommerceId: string,
    categoryIds: Array<string>
) => {
    try {
        const response = await settingsApi.findItemReturnReasons({
            ecommerceId,
            categoryIds
        })
        return response.data.return_reasons as Array<PbAvailableReturnReasons>
    } catch (error) {
        console.warn(error)
    }
}

const settingsApiSlice = createSlice({
    name: 'settingsApi',
    initialState,
    reducers: {
        setTheming: (state, action) => {
            if (action.payload && state.settings.response) {
                const newTheme = {
                    ...state.settings.response.theme,
                    ...action.payload
                }
                const newSettings = {
                    ...state.settings,
                    response: {
                        ...state.settings.response,
                        theme: newTheme
                    }
                }
                state.settings = newSettings
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSettings.pending, (state) => {
            state.settings.loading = 'pending'
            state.settings.response = {}
        })
        builder.addCase(getSettings.fulfilled, (state, action) => {
            state.settings.loading = 'succeeded'
            state.settings.response = action.payload
        })
        builder.addCase(getSettings.rejected, (state, action) => {
            state.settings.loading = 'failed'
            state.settings.error = action.payload as AxiosError
        })

        builder.addCase(getCopies.pending, (state) => {
            state.copies.loading = 'pending'
            state.copies.response = {}
        })
        builder.addCase(getCopies.fulfilled, (state, action) => {
            state.copies.loading = 'succeeded'
            if (!action.payload.copies) return
            state.copies.response = action.payload.copies.reduce<
                Record<string, PbCopyTextDetail>
            >((acc, copy) => {
                return {
                    ...acc,
                    [(copy.lang_tag ?? '') + '-' + (copy.field_tag ?? '')]: copy
                }
            }, {})
        })
        builder.addCase(getCopies.rejected, (state, action) => {
            state.copies.loading = 'failed'
            state.copies.error = action.payload as AxiosError
        })

        builder.addCase(getDefaultCopies.pending, (state) => {
            state.defaultCopies.loading = 'pending'
            state.defaultCopies.response = {}
        })
        builder.addCase(getDefaultCopies.fulfilled, (state, action) => {
            state.defaultCopies.loading = 'succeeded'
            if (!action.payload.copies) return
            state.defaultCopies.response = action.payload.copies.reduce<
                Record<string, PbCopyTextDetail>
            >((acc, copy) => {
                return {
                    ...acc,
                    [(copy.lang_tag ?? '') + '-' + (copy.field_tag ?? '')]: copy
                }
            }, {})
        })
        builder.addCase(getDefaultCopies.rejected, (state, action) => {
            state.defaultCopies.loading = 'failed'
            state.defaultCopies.error = action.payload as AxiosError
        })
    }
})

export const { setTheming } = settingsApiSlice.actions
export default settingsApiSlice.reducer
